import {
  CALCULATION_SIMULATION_FAILED,
  CALCULATION_SIMULATION_SUCCESS,
  FETCH_START,
  FETCH_END,
  FINANCIAL_POSTINGS_SIMULATION_FAILED,
  FINANCIAL_POSTINGS_SIMULATION_SUCCESS,
  PAYMENT_POSTINGS_SIMULATION_SUCCESS,
  NEXT_TIER,
  CALC_SIMULATION_SUCCESS,
  REBATE_ESTIMATE,
} from '../../../actions/Simulations/CalculationSimulation/ActionTypes';

const initialState = {
  calculationSimulationData: [],
  financialPostingsSimulationData: [],
  financialPostingsErrorList: [],
  calculationSimErrorList: [],
  paymentPostingsSimulationData: [],
  paymentPostingsErrorList: [],
  error: false,
  loading: false,
  nextTierData: {},
  nexTierError: [],
  calcSimulationData: [],
  calcSimErrorList: [],
  rebateEstimateData: {},
  rebateEstimateError: [],
};

const CalculationSimulationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CALCULATION_SIMULATION_SUCCESS:
      return {
        ...state,
        calculationSimulationData: action.data,
        calculationSimErrorList: action.errorList,
      };
    case CALCULATION_SIMULATION_FAILED:
      return {
        ...state,
        error: true,
      };
    case FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_END:
      return {
        ...state,
        loading: false,
      };
    case FINANCIAL_POSTINGS_SIMULATION_SUCCESS:
      return {
        ...state,
        financialPostingsSimulationData: action.data,
        financialPostingsErrorList: action.errorList,
      };
    case FINANCIAL_POSTINGS_SIMULATION_FAILED:
      return {
        ...state,
        error: true,
      };
    case PAYMENT_POSTINGS_SIMULATION_SUCCESS:
      return {
        ...state,
        paymentPostingsSimulationData: action.data,
        paymentPostingsErrorList: action.errorList,
      };
    case NEXT_TIER:
      return {
        ...state,
        nextTierData: action.data,
        nexTierError: action.errorList,
      };
    case CALC_SIMULATION_SUCCESS:
      return {
        ...state,
        calcSimulationData: action.data,
        calcSimErrorList: action.errorList,
      };
    case REBATE_ESTIMATE:
      return {
        ...state,
        rebateEstimateData: action.data,
        rebateEstimateError: action.errorList,
      };
    default:
      return state;
  }
};

export default CalculationSimulationReducer;
