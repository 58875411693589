import {
  CHAT_GPT_NORMAL,
  // CHAT_GPT_INSIGHT,
  // FETCH_START,
  // FETCH_END,
  COMMUNITY_CHAT_MAIN,
  GET_ALL_COMMUNITY_CHAT,
} from './ActionTypes';
import axios from '../../../axios.conf';
import { toast } from 'react-toastify';
import { history } from '../../../components/Helpers';
import secureLocalStorage from 'react-secure-storage';

export const getchatGptData = (data, type) => {
  return (dispatch) => {
    //   dispatch(fetchStart());
    getchatGptAPI(data, type)
      .then((response) => {
        //   dispatch(fetchEnd());
        dispatch(getchatGptSuccess(response.data.response));
      })
      .catch((error) => {
        //   dispatch(fetchEnd());
      });
  };
};
const getchatGptAPI = (data, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'Normal') result = axios.post('chat', data, { headers: headers });
  else if (type == 'Insight')
    result = axios.post('chat?useDatabase=true', data, { headers: headers });
  return result;
};
export const getchatGptSuccess = (data) => {
  return {
    type: CHAT_GPT_NORMAL,
    data,
  };
};
export const getAllCommunityCollabChannelsData = () => {
  return (dispatch) => {
    getAllCommunityCollabChannelsAPI()
      .then((response) => {
        dispatch(getAllCommunityCollabChannelsSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};
const getAllCommunityCollabChannelsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('collaboration-channels', { headers: headers });
  return result;
};
export const getAllCommunityCollabChannelsSuccess = (data) => {
  return {
    type: COMMUNITY_CHAT_MAIN,
    data,
  };
};
export const getAllCommunityChatData = (id, type) => {
  return (dispatch) => {
    getAllCommunityChatAPI(id, type)
      .then((response) => {
        dispatch(getAllCommunityChatSuccess(response.data.response));
        history.push('/community-chat');
      })
      .catch((error) => {});
  };
};
const getAllCommunityChatAPI = (id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('collaboration-chats?collaboration_id=' + id, {
    headers: headers,
  });
  return result;
};
export const getAllCommunityChatSuccess = (data) => {
  return {
    type: GET_ALL_COMMUNITY_CHAT,
    data,
  };
};
export const createQuestionChannel = (id, data, type) => {
  return (dispatch) => {
    createQuestionChannelAPI(id, data, type)
      .then((response) => {
        if (type === 'question') {
          toast.success('Question asked successfully');
          history.push('/community-chat');
        } else if (type === 'answer') {
          toast.success('Answerd successfully');
          history.push('/community-chat');
        }
      })
      .catch((error) => {});
  };
};
const createQuestionChannelAPI = (id, data, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'question') {
    result = axios.post(
      'collaboration-chat-question?collaboration_id=' + id,
      data,
      { headers: headers },
    );
  } else if (type === 'answer') {
    result = axios.post(
      'collaboration-chat-answer?collaboration_id=' + id,
      data,
      { headers: headers },
    );
  }
  return result;
};
