import {
  FETCH_START,
  FETCH_END,
  ADD_ELIGIBILITY_RULE_SUCCESS,
  ADD_ELIGIBILITY_RULE,
  ADD_ELIGIBILITY_RULE_FAILED,
  ADD_CALCULATION_RULE,
  CONTRACT_DATA,
  CONTRACT_DETAIL_DATA,
  ADD_CONTRACT_GENERAL_DATA,
  ADD_CALCULATION_RULE_FAILED,
  CALCULATION_RULE_SUCCESS,
  CONTRACT_NUMBER,
  CONTRACT_DATA_COMPLETE,
  UPLOAD_ATTACHMENT_SUCCESS,
  FETCH_START_UPLOAD,
  FETCH_END_UPLOAD,
  SHOW_CONTRACT_POPUP,
  CHANGED_CONTRACT_DETAIL_SUCCESS,
  SECOND_CHANGED_CONTRACT_DETAIL_SUCCESS,
  CONTRACT_DATA_COMPARE,
  UPDATED_CONTRACT_LIST,
  CONTRACT_CHANGE_LOG,
  CONTRACT_ALL_APPTYPE,
  CONTRACT_DATA_LIST,
  CONTRACT_DATA_LIST_DASHBOARD,
  UPDATED_CONTRACT_ALL_LIST,
  FP_HISTORY_SUCCESS,
  MASS_CONTRACT_UPDATE,
  MASS_CONTRACT_UPDATE_ACTION,
  UPLOAD_CALCULATION_RULES_SUCCESS,
  APPROVAL_LEVEL_DATA,
  EXPIRY_DATA,
  CALCU_RULE_VALID_MSG,
  CONTRACT_RULES_DATA_API_SUCCESS,
} from '../../../actions/Contracts/AddContract/ActionTypes';

const initialState = {
  eligibilityRulesData: [],
  calculationRulesData: [],
  contractListData: [],
  contractDetailData: [],
  contractGeneralData: [],
  changedContractData: [],
  changedContractOldData: [],
  contractCompareData: [],
  contractChangeLog: {},
  contractNumber: 0,
  eligibilityAdded: false,
  loading: false,
  uploadUrl: '',
  loadingFile: false,
  showContractPopup: false,
  contractAllAppType: [],
  contractAllListData: [],
  contractAllListDataDashBoard: [],
  contractRulesDataList: [],
  fpHistoryData: {},
  massUpdate: [],
  massUpdateAction: [],
  calculationRulesUploadData: [],
  approvalLevelData: [],
  expiredData: [],
  calculationMessage: [],
};

const AddContractReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ELIGIBILITY_RULE:
      return {
        ...state,
        eligibilityRulesData: action.data,
      };
    case ADD_ELIGIBILITY_RULE_SUCCESS:
      return {
        eligibilityAdded: true,
      };
    case ADD_ELIGIBILITY_RULE_FAILED:
      return {
        ...state,
        errorEligibilityAdd: true,
      };
    case ADD_CALCULATION_RULE:
      return {
        ...state,
        calculationRulesData: action.data,
      };
    case CONTRACT_DATA:
      return {
        ...state,
        contractListData: action.data,
      };
    case CONTRACT_DATA_COMPLETE:
      return {
        ...state,
        contractListDataComplete: action.data,
      };
    case CONTRACT_DETAIL_DATA:
      return {
        ...state,
        contractDetailData: action.data,
      };
    case ADD_CONTRACT_GENERAL_DATA:
      return {
        ...state,
        contractGeneralData: action.data,
      };
    case CONTRACT_DATA_COMPARE:
      return {
        ...state,
        contractCompareData: action.data,
      };
    case ADD_CALCULATION_RULE_FAILED:
      return {
        ...state,
        errorCalculationAdd: true,
      };
    case CALCULATION_RULE_SUCCESS:
      return {
        ...state,
        calculationSuccess: true,
      };
    case CONTRACT_NUMBER:
      return {
        ...state,
        contractNumber: action.data,
      };
    case FETCH_START:
      return {
        loading: true,
      };
    case FETCH_END:
      return {
        loading: false,
      };
    case UPLOAD_ATTACHMENT_SUCCESS:
      return {
        ...state,
        uploadUrl: action.data,
      };
    case FETCH_START_UPLOAD:
      return {
        loadingFile: true,
      };
    case FETCH_END_UPLOAD:
      return {
        loadingFile: false,
      };
    case SHOW_CONTRACT_POPUP:
      return {
        showContractPopup: action.data,
      };
    case CHANGED_CONTRACT_DETAIL_SUCCESS:
      return {
        changedContractData: action.data,
      };
    case SECOND_CHANGED_CONTRACT_DETAIL_SUCCESS:
      return {
        changedContractOldData: action.data,
      };
    case UPDATED_CONTRACT_LIST:
      return {
        ...state,
        updatedContractList: action.data,
      };
    case CONTRACT_CHANGE_LOG:
      return {
        ...state,
        contractChangeLog: action.data,
      };
    case CONTRACT_ALL_APPTYPE:
      return {
        ...state,
        contractAllAppType: action.data,
      };
    case CONTRACT_DATA_LIST:
      return {
        ...state,
        contractAllListData: action.data,
      };
    case CONTRACT_DATA_LIST_DASHBOARD:
      return {
        ...state,
        contractAllListDataDashBoard: action.data,
      };
    case CONTRACT_RULES_DATA_API_SUCCESS:
      return {
        ...state,
        contractRulesDataList: action.data,
      };
    case UPDATED_CONTRACT_ALL_LIST:
      return {
        ...state,
        updatedContractAllList: action.data,
      };
    case FP_HISTORY_SUCCESS:
      return {
        ...state,
        fpHistoryData: action.data,
      };
    case MASS_CONTRACT_UPDATE:
      return {
        ...state,
        massUpdate: action.data,
      };
    case MASS_CONTRACT_UPDATE_ACTION:
      return {
        ...state,
        massUpdateAction: action.data,
      };
    case UPLOAD_CALCULATION_RULES_SUCCESS:
      return {
        ...state,
        calculationRulesUploadData: action.data,
      };
    case APPROVAL_LEVEL_DATA:
      return {
        ...state,
        approvalLevelData: action.data,
      };
    case EXPIRY_DATA:
      return {
        ...state,
        expiredData: action.data,
      };
    case CALCU_RULE_VALID_MSG:
      return {
        ...state,
        calculationMessage: action.data,
      };
    default:
      return state;
  }
};

export default AddContractReducer;
