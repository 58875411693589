import {
  FINANCIAL_REPORTS_SUCCESS,
  FINANCIAL_REPORTS_COMPLETE_SUCCESS,
  FINANCIAL_REPORTS_FAILED,
  CALCULATION_REPORTS_SUCCESS,
  TARGET_REPORTS_SUCCESS,
  RATIO_KEY_FIGURES_REPORTS_SUCCESS,
  TOP_N_ATTRIBUTES_REPORTS_SUCCESS,
  DUPLICATE_PAYMENT_REPORTS_SUCCESS,
  ANAMOLY_REPORTS_SUCCESS,
  APPROVAL_POSTING_HISTORY_REPORTS_SUCCESS,
  CONTRACT_SEARCH_BY_CUSTOMER,
  CALCULATION_FORMULA_VALUES_REPORTS_SUCCESS,
  CONTRACT_CHANGE_REPORTS_SUCCESS,
  CONFIG_CHANGE_HISTORY_SUCCESS,
  CALCULATION_REPORTS_COMPLETE_SUCCESS,
  CALCULATION_REPORTS_FAILED,
  INVOICE_REPORTS_SUCCESS,
  INVOICE_REPORTS_COMPLETE_SUCCESS,
  INVOICE_REPORTS_FAILED,
  CONTRACT_REPORTS_SUCCESS,
  CONTRACT_REPORTS_COMPLETE_SUCCESS,
  CONTRACT_REPORTS_FAILED,
  FETCH_START,
  FETCH_END,
  CONTRACT_REPORTS_OVERLAP_SUCCESS,
  DYNAMIC_QUERY_FIELDS_SUCCESS,
  DYNAMIC_QUERY_FIELDS_SUCCESS_NEW,
  QUERY_LIST_SUCCESS,
  QUERY_LIST_ADMIN_SUCCESS,
  QUERY_RESULTS_FINAL_SUCCESS,
  DYNAMIC_QUERY_DETAILS_SUCCESS,
  CLAIMS_FORMAT_FIELDS_SUCCESS,
  INCOMING_CLAIM_DETAILS_SUCCESS,
  CALCULATION_SIMULATION_FIELDS_SUCCESS,
  QUERY_TYPES_SUCCESS,
  LOAD_TARGET_DATA,
  COLL_PORTAL_CUST_SUPP,
  OUTGOING_CLAIMS_FORMAT_FIELDS_SUCCESS,
  OUTGOING_CLAIM_DETAILS_SUCCESS,
  GET_ALL_ORG_USERS,
  TABLE_DETERMINATION_SUCCESS,
  PREDEFINED_QUERY_RESULTS_FINAL_SUCCESS,
  PREDEFINED_QUERY_DETAILS_SUCCESS,
  DYNAMIC_QUERY_FILTER,
  DYNAMIC_QUERY_FILTER_BY_ID,
} from '../../../actions/Simulations/OperationalReports/ActionTypes';

const initialState = {
  financialReportsData: [],
  calculationReportsData: [],
  invoiceReportsData: [],
  contractReportsData: [],
  contractReportsOverlapData: [],
  queryListSuccessData: [],
  queryListAdminSuccessData: [],
  queryResultsFinalData: [],
  dynamicQueryDetailsData: [],
  claimsFormatFieldsData: [],
  calculationSimulationData: [],
  targetData: [],
  contractChangeReportsData: [],
  configChangeHistory: [],
  error: false,
  loading: false,
  collaborationCustSuppData: {},
  orgUsers: [],
  tableDetermination: [],
  prefinedQueryDetailsData: [],
  predefinedResultsFinalData: [],
  predefinedListSuccessData: [],
  ratioKeyFiguresRepotsData: [],
  calculationFormulaValues: [],
  topNattributesReportsData: [],
  duplicatePaymentReportsData: [],
  approvalPostingHistoryReportsData: [],
  contractSearchByCustomer: [],
  anamolyReportsData: [],
  dynamicQueryFilters: [],
  dynamicQueryFilterData: {},
};

const OperationalReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Financial Reports
    case FINANCIAL_REPORTS_SUCCESS:
      return {
        ...state,
        financialReportsData: action.data,
      };
    case FINANCIAL_REPORTS_COMPLETE_SUCCESS:
      return {
        ...state,
        financialReportsCompleteData: action.data,
      };
    case FINANCIAL_REPORTS_FAILED:
      return {
        ...state,
        error: true,
      };
    // Calculation Reports
    case CALCULATION_REPORTS_SUCCESS:
      return {
        ...state,
        calculationReportsData: action.data,
      };
    case CALCULATION_REPORTS_FAILED:
      return {
        ...state,
        error: true,
      };
    case CALCULATION_REPORTS_COMPLETE_SUCCESS:
      return {
        ...state,
        calculationReportsCompleteData: action.data,
      };

    // Target reports
    case TARGET_REPORTS_SUCCESS:
      return {
        ...state,
        targetReportsData: action.data,
      };
    case RATIO_KEY_FIGURES_REPORTS_SUCCESS:
      return {
        ...state,
        ratioKeyFiguresRepotsData: action.data,
      };
    case TOP_N_ATTRIBUTES_REPORTS_SUCCESS:
      return {
        ...state,
        topNattributesReportsData: action.data,
      };
    case DUPLICATE_PAYMENT_REPORTS_SUCCESS:
      return {
        ...state,
        duplicatePaymentReportsData: action.data,
      };
    case ANAMOLY_REPORTS_SUCCESS:
      return {
        ...state,
        anamolyReportsData: action.data,
      };
    case APPROVAL_POSTING_HISTORY_REPORTS_SUCCESS:
      return {
        ...state,
        approvalPostingHistoryReportsData: action.data,
      };
    case CONTRACT_SEARCH_BY_CUSTOMER:
      return {
        ...state,
        contractSearchByCustomer: action.data,
      };
    case CALCULATION_FORMULA_VALUES_REPORTS_SUCCESS:
      return {
        ...state,
        calculationFormulaValues: action.data,
      };
    // Contract Change
    case CONTRACT_CHANGE_REPORTS_SUCCESS:
      return {
        ...state,
        contractChangeReportsData: action.data,
      };
    case CONFIG_CHANGE_HISTORY_SUCCESS:
      return {
        ...state,
        configChangeHistory: action.data,
      };
    // Invoice reports
    case INVOICE_REPORTS_SUCCESS:
      return {
        ...state,
        invoiceReportsData: action.data,
      };
    case INVOICE_REPORTS_COMPLETE_SUCCESS:
      return {
        ...state,
        invoiceReportsCompleteData: action.data,
      };
    case INVOICE_REPORTS_FAILED:
      return {
        ...state,
        error: true,
      };
    //contract reports
    case CONTRACT_REPORTS_SUCCESS:
      return {
        ...state,
        contractReportsData: action.data,
      };
    case CONTRACT_REPORTS_COMPLETE_SUCCESS:
      return {
        ...state,
        contractReportsCompleteData: action.data,
      };
    case CONTRACT_REPORTS_FAILED:
      return {
        ...state,
        error: true,
      };
    case FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_END:
      return {
        ...state,
        loading: false,
      };
    case CONTRACT_REPORTS_OVERLAP_SUCCESS:
      return {
        ...state,
        contractReportsOverlapData: action.data,
      };
    case DYNAMIC_QUERY_FIELDS_SUCCESS:
      return {
        ...state,
        dynamicQueryFieldsData: action.data,
      };
    case DYNAMIC_QUERY_FIELDS_SUCCESS_NEW:
      return {
        ...state,
        dynamicQueryFieldsDataNew: action.data,
      };
    case QUERY_LIST_SUCCESS:
      return {
        ...state,
        queryListSuccessData: action.data,
      };
    case QUERY_LIST_ADMIN_SUCCESS:
      return {
        ...state,
        queryListAdminSuccessData: action.data,
      };
    case QUERY_RESULTS_FINAL_SUCCESS:
      return {
        ...state,
        queryResultsFinalData: action.data,
      };
    case DYNAMIC_QUERY_DETAILS_SUCCESS:
      return {
        ...state,
        dynamicQueryDetailsData: action.data,
      };
    case CLAIMS_FORMAT_FIELDS_SUCCESS:
      return {
        ...state,
        claimsFormatFieldsData: action.data,
      };
    case INCOMING_CLAIM_DETAILS_SUCCESS:
      return {
        ...state,
        incomingClaimDetailsData: action.data,
      };
    case CALCULATION_SIMULATION_FIELDS_SUCCESS:
      return {
        ...state,
        calculationSimulationData: action.data,
      };
    case QUERY_TYPES_SUCCESS:
      return {
        ...state,
        queryTypesSuccess: action.data,
      };
    case LOAD_TARGET_DATA:
      return {
        ...state,
        targetData: action.data,
      };
    case COLL_PORTAL_CUST_SUPP:
      return {
        ...state,
        collaborationCustSuppData: action.data,
      };
    case OUTGOING_CLAIMS_FORMAT_FIELDS_SUCCESS:
      return {
        ...state,
        outgoingClaimsFormatFieldsData: action.data,
      };
    case OUTGOING_CLAIM_DETAILS_SUCCESS:
      return {
        ...state,
        outgoingClaimDetailsData: action.data,
      };
    case GET_ALL_ORG_USERS:
      return {
        ...state,
        orgUsers: action.data,
      };
    case TABLE_DETERMINATION_SUCCESS:
      return {
        ...state,
        tableDetermination: action.data,
      };
    case PREDEFINED_QUERY_RESULTS_FINAL_SUCCESS:
      return {
        ...state,
        predefinedResultsFinalData: action.data,
      };
    case PREDEFINED_QUERY_DETAILS_SUCCESS:
      return {
        ...state,
        predefinedListSuccessData: action.data,
      };
    case DYNAMIC_QUERY_FILTER:
      return {
        ...state,
        dynamicQueryFilters: action.data,
      };
    case DYNAMIC_QUERY_FILTER_BY_ID:
      return {
        ...state,
        dynamicQueryFilterData: action.data,
      };
    default:
      return state;
  }
};

export default OperationalReportsReducer;
