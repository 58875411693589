import {
  QUALIFIER_CONFIG_SUCCESS,
  PRICE_LIST_FIELDS,
  FETCH_START,
  FETCH_END,
  PRICE_TYPE_CONFIG_SUCCESS,
  PRICE_TYPE_CONFIG_BY_ID,
  EXTERNAl_CONFIG_SUCCESS,
  EXTERNAl_CONFIG_BY_ID,
  PRICE_LIST_MASTER_SUCCESS,
  PRICE_LIST_MASTER_BY_ID,
  PRICE_QUALIFIER_NAME_SUCCESS,
  PRICE_TYPE_NAME_SUCCESS,
  PRICE_CAL_FOR_LIST_SUCCESS,
  PRICE_CAL_FOR_LIST_BY_NAME,
  DRIVER_FORMAT_FIELDS,
  DRIVER_FORMAT_UPLOAD,
  PROFIT_FORMAT_BY_ID,
  DRIVER_DATA_UPLOAD_FAILURE,
  EXTERNAL_DATA_FORMAT_FIELDS_SUCCESS,
  ALL_EXTERNAL_DATA,
  EXTERNAL_DATA_UPLOAD_SUCCESS,
  FLEXIBLE_ANALYSIS_SIMULATION_SUCCESS,
  MARGIN_POL_EXC_SUCCESS,
  GROSS_TO_NET_SUCCESS,
  MARGIN_TREN_SUCCESS,
  MEDIAN_MARGIN_SUCCESS,
} from '../../actions/ProfitOptimizationConfig/ActionTypes';
const initialState = {
  loading: false,
  qualifierConfigData: [],
  ListFields: [],
  priceTypeData: [],
  priceTypeSingleData: [],
  priceQualifierNameList: [],
  priceTypeNameList: [],
  priceListMasterData: [],
  priceListMasterSingleData: [],
  priceCalForListData: [],
  priceListCalForDataByName: [],
  driverFormatFields: [],
  driverFormatUpload: [],
  driverFormatData: [],
  driverDataUploadFailure: [],
  externalDataFormatFields: [],
  allExternalData: [],
  allExternalDataErrorList: [],
  flexibleAnalysisSimulationData: [],
  marginPolicyExpectionData: [],
  grossNetData: [],
  marginTrendingData: [],
  medianMargin: [],
};
const ProfitOptimizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUALIFIER_CONFIG_SUCCESS:
      return {
        ...state,
        qualifierConfigData: action.data,
        loading: false,
      };
    case FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_END:
      return {
        ...state,
        loading: false,
      };
    case PRICE_LIST_FIELDS:
      return {
        ...state,
        ListFields: action.data,
        loading: false,
      };
    case PRICE_TYPE_CONFIG_SUCCESS:
      return {
        ...state,
        priceTypeData: action.data,
        loading: false,
      };
    case PRICE_TYPE_CONFIG_BY_ID:
      return {
        ...state,
        priceTypeSingleData: action.data,
        loading: false,
      };
    case EXTERNAl_CONFIG_SUCCESS:
      return {
        ...state,
        externalConfigData: action.data,
        loading: false,
      };
    case EXTERNAl_CONFIG_BY_ID:
      return {
        ...state,
        externalConfigSingleData: action.data,
        loading: false,
      };
    case PRICE_QUALIFIER_NAME_SUCCESS:
      return {
        ...state,
        priceQualifierNameList: action.data,
        loading: false,
      };
    case PRICE_TYPE_NAME_SUCCESS:
      return {
        ...state,
        priceTypeNameList: action.data,
        loading: false,
      };
    case PRICE_LIST_MASTER_SUCCESS:
      return {
        ...state,
        priceListMasterData: action.data,
        loading: false,
      };
    case PRICE_LIST_MASTER_BY_ID:
      return {
        ...state,
        priceListMasterSingleData: action.data,
        loading: false,
      };
    case PRICE_CAL_FOR_LIST_SUCCESS:
      return {
        ...state,
        priceCalForListData: action.data,
        loading: false,
      };
    case PRICE_CAL_FOR_LIST_BY_NAME:
      return {
        ...state,
        priceListCalForDataByName: action.data,
        loading: false,
      };
    case DRIVER_FORMAT_FIELDS:
      return {
        ...state,
        driverFormatFields: action.data,
        loading: false,
      };
    case DRIVER_FORMAT_UPLOAD:
      return {
        ...state,
        driverFormatUpload: action.data,
        loading: false,
      };

    case PROFIT_FORMAT_BY_ID:
      return {
        ...state,
        driverFormatData: action.data,
        loading: false,
      };
    case DRIVER_DATA_UPLOAD_FAILURE:
      return {
        ...state,
        driverDataUploadFailure: action.data,
        loading: false,
      };
    case EXTERNAL_DATA_FORMAT_FIELDS_SUCCESS:
      return {
        ...state,
        externalDataFormatFields: action.data,
        loading: false,
      };
    case ALL_EXTERNAL_DATA:
      return {
        ...state,
        allExternalData: action.data,
        loading: false,
      };
    case EXTERNAL_DATA_UPLOAD_SUCCESS:
      return {
        ...state,
        allExternalDataErrorList: action.data,
        loading: false,
      };
    case FLEXIBLE_ANALYSIS_SIMULATION_SUCCESS:
      return {
        ...state,
        flexibleAnalysisSimulationData: action.data,
      };
    case MARGIN_POL_EXC_SUCCESS:
      return {
        ...state,
        marginPolicyExpectionData: action.data,
        loading: false,
      };
    case GROSS_TO_NET_SUCCESS:
      return {
        ...state,
        grossNetData: action.data,
      };
    case MARGIN_TREN_SUCCESS:
      return {
        ...state,
        marginTrendingData: action.data,
        loading: false,
      };
    case MEDIAN_MARGIN_SUCCESS:
      return {
        ...state,
        medianMargin: action.data,
      };
    default:
      return state;
  }
};
export default ProfitOptimizationReducer;
