import {
  FETCH_START,
  FETCH_END,
  PROMOTION_DATA_SUCCESS,
  PROMO_FINANCE_COST_DATA_SUCCESS,
  PROMO_TERRITORY_DATA_SUCCESS,
  PROMO_TERRITORY_FORMAT_SUCCESS,
  PROMO_TERRITORY_FORMAT_BY_ID_SUCCESS,
  PROMO_TERRITORY_FORMAT_FIELDS_SUCCESS,
  PROMO_TERRITORY_UPLOAD_SUCCESS,
  PROMOTION_SETUP_BY_ID,
  PROMOTION_SETUP_SUCCESS,
  PROMOTION_MATERIAL_SUCCESS,
  PROMO_FINANCE_COST_DETAIL_SUCCESS,
  FINANCE_COST_FORMAT_SUCCESS,
  FINANCE_COST_FORMAT_BY_ID_SUCCESS,
  FINANCE_COST_FORMAT_FIELDS_SUCCESS,
  PROMO_FINANCE_COST_UPLOAD_SUCCESS,
  COUNTRY_SUCCESS,
  TERRITORY_ID_SUCCESS,
  POSTING_FAILED,
  GLOBAL_SETUP_BY_ID,
  GLOBAL_SETUP_SUCCESS,
  PROMO_MAP_SUCCESS,
  PROMO_MAP_SINGLE_SUCCESS,
  PROMO_MAPPING_FORMAT_SUCCESS,
  PROMO_MAP_FORMAT_BY_ID,
  PROMO_MAP_FORMAT_FIELDS,
  PROMO_SIM_FAILURE,
  PROMO_TYPES,
  PROMO_STICKERS,
  PROMO_STICKER_BY_ID,
  PROMO_FILTERS,
  MARKET_FILTERS,
  MODEL_GROUP,
  LOCATION_GROUP,
  MODEL_LIST_GROUP,
  PROMO_CONFIG_UPLOAD_SUCCESS,
  PROMO_CONFIG_FORMAT_FIELDS_SUCCESS,
  PROMO_CONFIG_FORMAT_SUCCESS,
  PROMO_CONFIG_DATA_SUCCESS,
  MATERIAL_PRICING_UPLOAD_SUCCESS,
  MATERIAL_PRICING_FORMAT_FIELDS_SUCCESS,
  MATERIAL_PRICING_FORMAT_SUCCESS,
  MATERIAL_PRICING_DATA_SUCCESS,
  MATERIAL_PRICING_SINGLE_DATA_SUCCESS,
  PROMO_CONFIG_SINGLE_DATA_SUCCESS,
  SCENARIO_LIST_DATA_SUCCESS,
  SAND_BOX_DATA_SUCCESS,
  HISTORY_DATA_SUCCESS,
  PROMOTIONS_SEARCH_SUCCESS,
  COSTING_SIMULATION_SUCCESS,
  COSTING_START,
  COSTING_END,
  PREFILL_SCENARIO_SUCCESS,
  SCENARIO_BY_ID,
  COVERAGE_MAPPING_UPLOAD_SUCCESS,
  COVERAGE_MAPPING_FORMAT_FIELDS_SUCCESS,
  COVERAGE_MAPPING_FORMAT_SUCCESS,
  COVERAGE_MAPPING_DATA_SUCCESS,
  COVERAGE_MAPPING_SINGLE_SUCCESS,
  STANDARD_FINANCE_UPLOAD_SUCCESS,
  STANDARD_FINANCE_FORMAT_FIELDS_SUCCESS,
  STANDARD_FINANCE_FORMAT_SUCCESS,
  STANDARD_FINANCE_DATA_SUCCESS,
  STANDARD_FINANCE_SINGLE_DATA_SUCCESS,
  PROMOTION_CURRENCY_UPLOAD_SUCCESS,
  PROMOTION_CURRENCY_FORMAT_FIELDS_SUCCESS,
  PROMOTION_CURRENCY_FORMAT_SUCCESS,
  PROMOTION_CURRENCY_DATA_SUCCESS,
  PROMOTION_CURRENCY_SINGLE_SUCCESS,
  SAVED_SCENARIO,
  SALES_FORECAST_SINGLE_DATA_SUCCESS,
  SCENARIO_MARKETS,
  PREVIOUS_SCENARIO,
  REVIEW_SCENARIO,
  CURRENT_SCENARIO,
  MARKET_END,
  MARKET_START,
  CUSTOM_GROUP_ID,
  CUSTOM_GROUPS,
  CUSTOM_GROUP_LIST,
  TR_APPROVAL_REVIEW_DATA_SUCCESS,
  TR_APPROVAL_DATA_SUCCESS,
  TR_APPROVAL_REVIEW_COMPLETED_DATA_SUCCESS,
  GROUPED_CUSTOM_GROUP,
  PROMOTION_COSTING_COMPARISON_UPLOAD_SUCCESS,
  PROMO_COSTING_DROPDOWN_DATA_SUCCESS,
  ARCHIVE,
  STICKER_ARCHIVE,
  PROMO_COSTING_TABLE_DATA_SUCCESS,
  LOCATION_FILTER,
  SCENARIO_DATA,
  GRID_DATA,
  USER_ACCESS_DATA,
  CURRENT_MODEL_YEAR,
  PROMO_FINANCE_COSTING_DIALOG_DATA_SUCCESS,
  PROMO_PROMOTIONAL_OFFERS_DATA_SUCCESS,
  PROMO_REGULAR_OFFERS_DATA_SUCCESS,
  PROMO_REGULAR_OFFERS_FORMAT_SUCCESS,
  PROMO_REGULAR_OFFERS_UPLOAD_SUCCESS,
  PROMO_REGULAR_OFFERS_FIELDS_SUCCESS,
  PROMO_REGULAR_FINANCING_DATA_SUCCESS,
  DOWNLOADING,
  VALID_MARKETS,
} from './ActionTypes';
import secureLocalStorage from 'react-secure-storage';
import axios from '../../../axios.conf';
import { toast } from 'react-toastify';
import { history } from '../../../components/Helpers';
import { isArray } from 'validate.js';

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const getCreatePromotionPlanSuccess = (data) => {
  return {
    type: PROMOTION_DATA_SUCCESS,
    data,
  };
};

export const createPromotionPlan = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCreatePromotionPlanAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getCreatePromotionPlanSuccess(response.data.response));
        history.push('/promotion-trends-results');
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getCreatePromotionPlanAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('plan-promotions', data, { headers: headers });
  return result;
};

//save
export const savePromotionPlan = (data) => {
  return (dispatch) => {
    getSavePromotionPlanAPI(data)
      .then((response) => {
        toast.success(response.data.response);
        history.push('/promotion-trends');
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message)
      });
  };
};

const getSavePromotionPlanAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('/plan-promotions-save', data, { headers: headers });
  return result;
};

// //   Promo Finance Cost
export const getPromoFinanceCostData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPromoFinanceCostDataApi()
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPromoFinanceCostDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPromoFinanceCostDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('promo-finance-cost?page=1&&limit=0', {
    headers: headers,
  });
  return result;
};

export const getPromoFinanceCostDataSuccess = (data) => {
  return {
    type: PROMO_FINANCE_COST_DATA_SUCCESS,
    data,
  };
};
export const deleteFinanceCostData = (id) => {
  return (dispatch) => {
    deleteFinanceCostDataApi(id).then((response) => {
      toast.success(response.data.response);
      dispatch(getPromoFinanceCostData());
    });
  };
};
const deleteFinanceCostDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('promo-finance-cost/' + id, { headers: headers });
  return result;
};
// Finance Cost format
export const getFinanceCostFormatData = () => {
  return (dispatch) => {
    getFinanceCostFormatDataApi()
      .then((response) => {
        dispatch(getFinanceCostFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getFinanceCostFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=PromotionPromoFinanceCostsFormat',
    { headers: headers },
  );
  return result;
};

export const getFinanceCostFormatDataSuccess = (data) => {
  return {
    type: FINANCE_COST_FORMAT_SUCCESS,
    data,
  };
};

export const getFinanceCostFormatDataById = (id) => {
  return (dispatch) => {
    getFinanceCostFormatDataByIdApi(id)
      .then((response) => {
        dispatch(getFinanceCostFormatDataByIdSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getFinanceCostFormatDataByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format/' +
    id +
    '?app-functionality=PromotionPromoFinanceCostsFormat',
    { headers: headers },
  );
  return result;
};

export const getFinanceCostFormatDataByIdSuccess = (data) => {
  return {
    type: FINANCE_COST_FORMAT_BY_ID_SUCCESS,
    data,
  };
};

//get Finance Cost format fields
export const getFinanceCostFormatFields = () => {
  return (dispatch) => {
    getFinanceCostFormatFieldsApi()
      .then((response) => {
        dispatch(getFinanceCostFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getFinanceCostFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=PromotionPromoFinanceCostsFormat',
    { headers: headers },
  );
  return result;
};

export const getFinanceCostFormatFieldsSuccess = (data) => {
  return {
    type: FINANCE_COST_FORMAT_FIELDS_SUCCESS,
    data,
  };
};

// update Finance Cost format
export const updateFinanceCostFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateFinanceCostFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push('/finance-cost/finance-cost-excel-upload');
        } else {
          //toast.error(response.data.error.message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const updateFinanceCostFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'upload-format/' +
    id +
    '?app-functionality=PromotionPromoFinanceCostsFormat',
    data,
    { headers: headers },
  );
  return result;
};

//add Finance Cost format
export const addFinanceCostFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addFinanceCostFormatDataApi(data)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push('/finance-cost/finance-cost-excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addFinanceCostFormatDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'upload-format?app-functionality=PromotionPromoFinanceCostsFormat',
    data,
    { headers: headers },
  );
  return result;
};

// delete Coverage Cost format
export const deleteFinanceCostFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteFinanceCostFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          dispatch(getFinanceCostFormatData());
        } else {
          //toast.error(response.data.error.message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const deleteFinanceCostFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};
export const uploadPromoFinanceCostDataFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadPromoFinanceCostDataFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadPromoFinanceCostSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadPromoFinanceCostDataFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('upload-file?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};

export const uploadPromoFinanceCostSuccess = (data) => {
  return {
    type: PROMO_FINANCE_COST_UPLOAD_SUCCESS,
    data,
  };
};

//get Promo Finance Cost details
export const getPromoFinanceCostDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPromoFinanceCostDetailsApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPromoFinanceCostDetailsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
        dispatch(fetchEnd());
      });
  };
};

const getPromoFinanceCostDetailsApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('promo-finance-cost/' + id, { headers: headers });
  return result;
};

export const getPromoFinanceCostDetailsSuccess = (data) => {
  return {
    type: PROMO_FINANCE_COST_DETAIL_SUCCESS,
    data,
  };
};

// Promo Territory

export const getPromoTerritoryData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPromoTerritoryDataApi()
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPromoTerritoryDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getPromoTerritoryDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('promo-territory?page=1&&limit=0', { headers: headers });
  return result;
};

export const getPromoTerritoryDataSuccess = (data) => {
  return {
    type: PROMO_TERRITORY_DATA_SUCCESS,
    data,
  };
};
export const deletePromoTerritoryData = (id) => {
  return (dispatch) => {
    deletePromoTerritoryDataApi(id).then((response) => {
      toast.success(response.data.response);
      dispatch(getPromoTerritoryData());
    });
  };
};
const deletePromoTerritoryDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('promo-territory/' + id, { headers: headers });
  return result;
};
// Promo Territory format
export const getPromoTerritoryFormatData = () => {
  return (dispatch) => {
    getPromoTerritoryFormatDataApi()
      .then((response) => {
        dispatch(getPromoTerritoryFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getPromoTerritoryFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=PromotionTerritoriesFormat',
    { headers: headers },
  );
  return result;
};

export const getPromoTerritoryFormatDataSuccess = (data) => {
  return {
    type: PROMO_TERRITORY_FORMAT_SUCCESS,
    data,
  };
};

export const getPromoTerritoryFormatDataById = (id) => {
  return (dispatch) => {
    getPromoTerritoryFormatDataByIdApi(id)
      .then((response) => {
        dispatch(
          getPromoTerritoryFormatDataByIdSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getPromoTerritoryFormatDataByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format/' + id + '?app-functionality=PromotionTerritoriesFormat',
    { headers: headers },
  );
  return result;
};

export const getPromoTerritoryFormatDataByIdSuccess = (data) => {
  return {
    type: PROMO_TERRITORY_FORMAT_BY_ID_SUCCESS,
    data,
  };
};

//get Finance Cost format fields
export const getPromoTerritoryFormatFields = () => {
  return (dispatch) => {
    getPromoTerritoryFormatFieldsApi()
      .then((response) => {
        dispatch(getPromoTerritoryFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getPromoTerritoryFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=PromotionTerritoriesFormat',
    { headers: headers },
  );
  return result;
};

export const getPromoTerritoryFormatFieldsSuccess = (data) => {
  return {
    type: PROMO_TERRITORY_FORMAT_FIELDS_SUCCESS,
    data,
  };
};

// update Promo Territory format
export const updatePromoTerritoryFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updatePromoTerritoryFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push('/promotion-territory/promotion-territory-excel-upload');
        } else {
          //toast.error(response.data.error.message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const updatePromoTerritoryFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'upload-format/' + id + '?app-functionality=PromotionTerritoriesFormat',
    data,
    { headers: headers },
  );
  return result;
};

//add Finance Cost format
export const addPromoTerritoryFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addPromoTerritoryFormatDataApi(data)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push('/promotion-territory/promotion-territory-excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addPromoTerritoryFormatDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'upload-format?app-functionality=PromotionTerritoriesFormat',
    data,
    { headers: headers },
  );
  return result;
};

// delete Promo Territory format
export const deletePromoTerritoryFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deletePromoTerritoryFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          dispatch(getPromoTerritoryFormatData());
        } else {
          //toast.error(response.data.error.message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const deletePromoTerritoryFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};
export const uploadPromoTerritoryDataFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadPromoTerritoryDataFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadPromoTerritorySuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadPromoTerritoryDataFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('upload-file?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
export const uploadPromoTerritorySuccess = (data) => {
  return {
    type: PROMO_TERRITORY_UPLOAD_SUCCESS,
    data,
  };
};

//promotion setup

export const createPromotionSetup = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createPromotionSetupAPI(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/promotion-setup');
        dispatch(getPromotionSetup());
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createPromotionSetupAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('promotion-cost-header/' + id, data, {
      headers: headers,
    });
  } else if (type === 'delete') {
    result = axios.delete('promotion-cost-header/' + id, { headers: headers });
  } else {
    result = axios.post('promotion-cost-header', data, { headers: headers });
  }
  return result;
};

export const getPromotionSetupById = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPromotionSetupByIdAPI(id)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPromotionSetupByIdSuccess(response.data.response));
        //history.push('/pricing-type-config/edit-type-config/' + id);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPromotionSetupByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('promotion-cost-header/' + id, { headers: headers });
  return result;
};

export const getPromotionSetupByIdSuccess = (data) => {
  return {
    type: PROMOTION_SETUP_BY_ID,
    data,
  };
};

export const getPromotionSetup = () => {
  return (dispatch) => {
    getPromotionSetupAPI()
      .then((response) => {
        dispatch(getPromotionSetupSuccess(response.data.response));
        //history.push('/pricing-type-config/edit-type-config/' + id);
      })
      .catch((error) => { });
  };
};

const getPromotionSetupAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('promotion-cost-header?page=1&&limit=0', {
    headers: headers,
  });
  return result;
};

export const getPromotionSetupSuccess = (data) => {
  return {
    type: PROMOTION_SETUP_SUCCESS,
    data,
  };
};
export const getPromotionMaterialData = () => {
  return (dispatch) => {
    getPromotionMaterialDataAPI()
      .then((response) => {
        dispatch(getPromotionMaterialDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getPromotionMaterialDataAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('promotion-cost-material', { headers: headers });
  return result;
};

export const getPromotionMaterialDataSuccess = (data) => {
  return {
    type: PROMOTION_MATERIAL_SUCCESS,
    data,
  };
};
//get country
export const getCountryData = (country, id) => {
  return (dispatch) => {
    getCountryDataAPI(country, id)
      .then((response) => {
        if (id) dispatch(getIdDataSuccess(response.data.response));
        else dispatch(getCountryDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getCountryDataAPI = (country, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id)
    result = axios.get('promo-territory-by-value?id=' + id, {
      headers: headers,
    });
  else
    result = axios.get('promo-territory-by-value?country=' + country, {
      headers: headers,
    });
  return result;
};

export const getCountryDataSuccess = (data) => {
  return {
    type: COUNTRY_SUCCESS,
    data,
  };
};

export const getIdDataSuccess = (data) => {
  return {
    type: TERRITORY_ID_SUCCESS,
    data,
  };
};

//promo-simulation
export const runPromotionSimulation = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    runPromotionSimulationAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.messageLog) {
          dispatch(promotionSimulationFailure(response.data));
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        if (error.response.status == '422')
          dispatch(promotionSimulationFailure(error.response.data));
      });
  };
};

const runPromotionSimulationAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('promotion-simulation', data, { headers: headers });
  return result;
};
export const promotionSimulationFailure = (data) => {
  return {
    type: PROMO_SIM_FAILURE,
    data,
  };
};
//promo-posting

export const runPromotionPosting = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    runPromotionPostingAPI(data)
      .then((response) => {
        dispatch(fetchEnd());

        if (
          response.data.response.error_list &&
          response.data.response.error_list.length > 0
        ) {
          dispatch(promotionPostingFailed(response.data.response));
        } else toast.success('Success');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const runPromotionPostingAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('promotion-posting', data, { headers: headers });
  return result;
};
export const promotionPostingFailed = (data) => {
  return {
    type: POSTING_FAILED,
    data,
  };
};

// global setup
export const createGlobalSetup = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createGlobalSetupAPI(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/promotion-global-setup');
        dispatch(getGlobalSetupData());
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createGlobalSetupAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('global-promotion', data, { headers: headers });
  } else if (type === 'delete') {
    result = axios.delete('global-promotion/' + id, { headers: headers });
  } else {
    result = axios.post('global-promotion', data, { headers: headers });
  }
  return result;
};

export const getGlobalSetupData = (country) => {
  return (dispatch) => {
    getGlobalSetupDataAPI(country)
      .then((response) => {
        dispatch(getGlobalSetupSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getGlobalSetupDataAPI = (country) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (country)
    result = axios.get('global-promotion?country=' + country, {
      headers: headers,
    });
  else result = axios.get('global-promotion', { headers: headers });
  return result;
};

export const getGlobalSetupByIdSuccess = (data) => {
  return {
    type: GLOBAL_SETUP_BY_ID,
    data,
  };
};
export const getGlobalSetupSuccess = (data) => {
  return {
    type: GLOBAL_SETUP_SUCCESS,
    data,
  };
};

//clone promo setup
export const clonePromotionSetup = (id) => {
  const pagination = 1;
  const limit = 10;
  return (dispatch) => {
    getClonePromotionSetupApi(id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getPromotionSetup());
      })
      .catch((error) => {
        //toast.error('Something went wrong.');;
      });
  };
};

const getClonePromotionSetupApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('promotion-cost-header/' + id + '/clone', {
    headers: headers,
  });
  return result;
};
//promotion mapping

export const createPromotionMapping = (data, type, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createPromotionMappingAPI(data, type, id)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
        dispatch(getPromotionMappingData(1, 0));

        history.push('/promo-mapping');
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createPromotionMappingAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'delete') {
    result = axios.delete('promotion-mapping/' + id, { headers: headers });
  } else if (type === 'edit') {
    result = axios.put('promotion-mapping/' + id, data, { headers: headers });
  } else {
    result = axios.post('promotion-mapping', data, { headers: headers });
  }
  return result;
};
export const getPromotionMappingData = (pagination, limit) => {
  return (dispatch) => {
    getPromotionMappingDataApi(pagination, limit).then((response) => {
      dispatch(getPromotionMappingDataSuccess(response.data.response));
    });
  };
};
const getPromotionMappingDataApi = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'promotion-mapping?page=' + pagination + '&&limit=' + limit,
    { headers: headers },
  );
  return result;
};
export const getPromotionMappingDataSuccess = (data) => {
  return {
    type: PROMO_MAP_SUCCESS,
    data,
  };
};
export const getPromotionMappingSingleItem = (id) => {
  return (dispatch) => {
    getPromotionMappingSingleItemAPI(id).then((response) => {
      dispatch(getPromotionMappingSingleItemSuccess(response.data.response));
    });
  };
};
const getPromotionMappingSingleItemAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('promotion-mapping/' + id, { headers: headers });
  return result;
};

export const getPromotionMappingSingleItemSuccess = (data) => {
  return {
    type: PROMO_MAP_SINGLE_SUCCESS,
    data,
  };
};

//promo-mapping-format
export const getPromotionMappingFormatData = () => {
  return (dispatch) => {
    getPromotionMappingFormatDataApi()
      .then((response) => {
        dispatch(getPromotionMappingFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getPromotionMappingFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=PromotionBudgetIntegrationMappingFormat ',
    { headers: headers },
  );
  return result;
};

export const getPromotionMappingFormatDataSuccess = (data) => {
  return {
    type: PROMO_MAPPING_FORMAT_SUCCESS,
    data,
  };
};

export const getPromotionMappingFormatDataById = (id) => {
  return (dispatch) => {
    getPromotionMappingFormatDataByIdApi(id)
      .then((response) => {
        dispatch(
          getPromotionMappingFormatDataByIdSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getPromotionMappingFormatDataByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format/' + id, { headers: headers });
  return result;
};

export const getPromotionMappingFormatDataByIdSuccess = (data) => {
  return {
    type: PROMO_MAP_FORMAT_BY_ID,
    data,
  };
};

export const getPromotionMappingFormatFields = () => {
  return (dispatch) => {
    getPromotionMappingFormatFieldsApi()
      .then((response) => {
        dispatch(
          getPromotionMappingFormatFieldsSuccess(response.data.response),
        );
      })
      .catch((error) => {
        ////toast.error(error.response.data.error.message);
      });
  };
};

const getPromotionMappingFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=PromotionBudgetIntegrationMappingFormat ',
    { headers: headers },
  );
  return result;
};

export const getPromotionMappingFormatFieldsSuccess = (data) => {
  return {
    type: PROMO_MAP_FORMAT_FIELDS,
    data,
  };
};

export const updatePromotionMappingFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updatePromotionMappingFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push('/promo-mapping/promo-mapping-excel-upload');
        } else {
          ////toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const updatePromotionMappingFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'upload-format/' +
    id +
    '?app-functionality=PromotionBudgetIntegrationMappingFormat',
    data,
    { headers: headers },
  );
  return result;
};
export const addPromotionMappingFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addPromotionMappingFormatDataApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        //dispatch(getPromotionMappingFormatData())
        toast.success(response.data.response);
        history.push('/promo-mapping/promo-mapping-excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addPromotionMappingFormatDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'upload-format?app-functionality=PromotionBudgetIntegrationMappingFormat',
    data,
    { headers: headers },
  );
  return result;
};

export const deletePromotionMappingFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deletePromotionMappingFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success('Successfully deleted');
          dispatch(getPromotionMappingFormatData());
        } else {
          ////toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        ////toast.error(ServerError);
      });
  };
};

const deletePromotionMappingFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};

export const uploadPromotionMappingFile = (id, file) => {
  return (dispatch) => {
    uploadPromotionMappingFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          //window.location.reload();
          toast.success('File has been successfully uploaded.');
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const uploadPromotionMappingFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('upload-file?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
export const getPromoTypes = (country, productLine) => {
  return (dispatch) => {
    getPromoTypesApi(country, productLine)
      .then((response) => {
        dispatch(getPromoTypesSuccess(response.data.response));
      })
      .catch((error) => {
        ////toast.error(error.response.data.error.message);
      });
  };
};

const getPromoTypesApi = (country, productLine) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (country && productLine) {
    result = axios.get(
      '/promotion-sticker-types?country=' +
      country +
      '&product_lines=' +
      productLine,
      { headers: headers },
    );
  } else {
    result = axios.get('/promotion-sticker-types', { headers: headers });
  }
  return result;
};

export const getPromoTypesSuccess = (data) => {
  return {
    type: PROMO_TYPES,
    data,
  };
};

export const getPromoStickers = (
  productLines,
  country,
  id,
  pagination,
  limit,
  page,
  data,
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPromoStickersApi(
      productLines,
      country,
      id,
      pagination,
      limit,
      page,
      data,
    )
      .then((response) => {
        dispatch(fetchEnd());
        if (
          id &&
          page &&
          (page === 'TR' ||
            page === 'filtered_list' ||
            page === 'sticker_bank_view')
        ) {
          dispatch(getPromoStickerByIdSuccess(response.data.response));
        } else if (id && page && page != 'list') {
          dispatch(getPromoStickerByIdSuccess(response.data.response));
          //history.push('/promotion-sticker-setup/edit/' + id)
          history.push({
            pathname: '/promotion-sticker-setup/edit/' + id,
            data: { page: page },
          });
        } else dispatch(getPromoStickersSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPromoStickersApi = (
  productLines,
  country,
  id,
  pagination,
  limit,
  page,
  data,
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id) {
    if (page == 'list' || page === 'filtered_list')
      result = axios.get('/promotion-stickers?id.in=' + id, {
        headers: headers,
      });
    else if (page == 'TR')
      result = axios.get(
        '/promotion-sticker/' +
        id +
        '?country=' +
        country +
        '&product_lines=' +
        productLines,
        { headers: headers },
      );
    else result = axios.get('/promotion-sticker/' + id, { headers: headers });
  } else if (pagination)
    result = axios.get(
      '/promotion-stickers?page=' +
      pagination +
      '&&limit=' +
      limit +
      '&product_line.in=' +
      productLines +
      '&country=' +
      country,
      { headers: headers },
    );
  else if (page === 'filter') {
    result = axios.get(
      '/promotion-stickers',
      { params: data },
      { headers: headers },
    );
  } else
    result = axios.get(
      '/promotion-stickers?product_line.in=' +
      productLines +
      '&country=' +
      country,
      { headers: headers },
    );
  return result;
};

export const getPromoStickersSuccess = (data) => {
  return {
    type: PROMO_STICKERS,
    data,
  };
};
export const getPromoStickerByIdSuccess = (data) => {
  return {
    type: PROMO_STICKER_BY_ID,
    data,
  };
};
export const createStickers = (data, type, value, page, isOvrtUpdated) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createStickersAPI(data, type, value, isOvrtUpdated)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response) {
          toast.success(
            <div style={{ whiteSpace: 'pre-line' }}>
              {response.data.response}
            </div>,
          );
        }
        if (response.data.warning_message) {
          toast.warn(
            <div style={{ whiteSpace: 'pre-line' }}>
              {response.data.warning_message}
            </div>,
          );
        }
        console.log(page);
        console.log(type);
        console.log(response.data.response);
        if (type == 'create' || type == 'edit') {
          if (page == 'Scenario')
            history.push({
              pathname: '/promotion-scenario-setup',
              data: {
                country: data.countries[0],
                productLine: data.product_lines,
                isVisited: true,
                page: page,
              },
            });
          else if (type === 'create')
            history.push({
              pathname: '/promotion-sticker-setup',
              data: {
                country: data.countries[0],
                productLine: data.product_lines,
                isVisited: true,
              },
            });
          else if (type === 'edit')
            history.push({
              pathname: '/promotion-sticker-setup',
              data: {
                country: data.countries[0],
                productLine: data.product_lines,
                isVisited: true,
              },
            });
        }
        if (type == 'delete' || type == 'clone')
          dispatch(getPromoStickers(value.product_line, value.country));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createStickersAPI = (data, type, value, isOvrtUpdated) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'create')
    result = axios.post('/promotion-sticker', data, {
      headers: headers,
    });
  else if (type === 'delete')
    result = axios.delete(
      '/promotion-sticker/' + value.ID,
      { params: data },
      {
        headers: headers,
      },
    );
  else if (type == 'clone')
    result = axios.post('/promotion-sticker/' + value.ID + '/clone', {
      headers: headers,
    });
  else
    result = axios.put(
      '/promotion-sticker/' + value + '?update_overwrite=' + isOvrtUpdated,
      data,
      {
        headers: headers,
      },
    );
  return result;
};

export const getMaterialFilters = (table) => {
  return (dispatch) => {
    getMaterialFiltersApi(table)
      .then((response) => {
        dispatch(getMaterialFiltersSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

//create stickers archive
export const createStickerArchive = (id, productline, country) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCreateStickerArchiveApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
        dispatch(getPromoStickers(productline, country));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        // toast.error('Something went wrong.');
      });
  };
};

const getCreateStickerArchiveApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'promotion/' + id + '/archive?table_name=promotion_stickers',
    {
      headers: headers,
    },
  );
  return result;
};

// stickers archive list
export const getStickerArchiveList = (productLine, country, type, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getStickerArchiveListApi(productLine, country, type, data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getStickerArchiveListSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const getStickerArchiveListApi = (productLine, country, type, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;

  if (type === 'filter') {
    result = axios.get(
      '/promotion-stickers',
      { params: data },
      { headers: headers },
    );
  } else
    result = axios.get(
      'promotion-stickers?product_line.in=' +
      productLine +
      '&country=' +
      country +
      '&archive=true',
      { headers: headers },
    );

  return result;
};

export const getStickerArchiveListSuccess = (data) => {
  return {
    type: STICKER_ARCHIVE,
    data,
  };
};

const getMaterialFiltersApi = (table) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;

  result = axios.get('promotion-filter-group', { headers: headers });
  return result;
};

export const getMaterialFiltersSuccess = (data) => {
  return {
    type: PROMO_FILTERS,
    data,
  };
};

export const getMarketFilters = (table, data) => {
  return (dispatch) => {
    getMarketFiltersApi(table, data)
      .then((response) => {
        dispatch(getMarketFiltersSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getMarketFiltersApi = (table, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;

  result = axios.post('promotion-market-filters?table_name=' + table, data, {
    headers: headers,
  });
  return result;
};

export const getMarketFiltersSuccess = (data) => {
  return {
    type: MARKET_FILTERS,
    data,
  };
};

export const getModels = (table, data, type) => {
  return (dispatch) => {
    getModelsApi(table, data)
      .then((response) => {
        if (table == 'material_master') {
          if (type == 'filter')
            dispatch(getModelsSuccess(response.data.response));
          else dispatch(getModelsListSuccess(response.data.response));
        } else {
          if (type == 'search&filter')
            dispatch(getFilteredLocationSuccess(response.data.response));
          else dispatch(getLocationSuccess(response.data.response));
        }
      })
      .catch((error) => { });
  };
};

const getModelsApi = (table, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;

  result = axios.post('promotion-define-market', data, { headers: headers });
  return result;
};

export const getModelsSuccess = (data) => {
  return {
    type: MODEL_GROUP,
    data,
  };
};
export const getModelsListSuccess = (data) => {
  return {
    type: MODEL_LIST_GROUP,
    data,
  };
};
export const getLocationSuccess = (data) => {
  return {
    type: LOCATION_GROUP,
    data,
  };
};
export const getFilteredLocationSuccess = (data) => {
  return {
    type: LOCATION_FILTER,
    data,
  };
};
// Promotion Config

// ADD edit Promotion Config
export const addPromoConfigFormatData = (data, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addPromoConfigFormatDataApi(data, id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push('/promo-config/excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addPromoConfigFormatDataApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id) {
    result = axios.put(
      'upload-format/' +
      id +
      '?app-functionality=PromotionConfigurationsFormat',
      data,
      { headers: headers },
    );
  } else {
    result = axios.post(
      'upload-format?app-functionality=PromotionConfigurationsFormat',
      data,
      { headers: headers },
    );
  }
  return result;
};

// delete promo config

export const deletePromoConfigFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deletePromoConfigFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          dispatch(getPromoConfigFormatData());
        } else {
          //toast.error(response.data.error.message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const deletePromoConfigFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};

// upload promo config file

export const uploadPromoConfigDataFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadPromoConfigDataFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadPromoConfigSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadPromoConfigDataFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('upload-file?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};

export const uploadPromoConfigSuccess = (data) => {
  return {
    type: PROMO_CONFIG_UPLOAD_SUCCESS,
    data,
  };
};

//get Promotion Config format fields

export const getPromoConfigFormatFields = () => {
  return (dispatch) => {
    getPromoConfigFormatFieldsApi()
      .then((response) => {
        dispatch(getPromoConfigFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getPromoConfigFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=PromotionConfigurationsFormat',
    { headers: headers },
  );
  return result;
};

export const getPromoConfigFormatFieldsSuccess = (data) => {
  return {
    type: PROMO_CONFIG_FORMAT_FIELDS_SUCCESS,
    data,
  };
};

//get promo config format data

export const getPromoConfigFormatData = () => {
  return (dispatch) => {
    getPromoConfigFormatDataApi()
      .then((response) => {
        dispatch(getPromoConfigFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getPromoConfigFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=PromotionConfigurationsFormat',
    { headers: headers },
  );
  return result;
};

export const getPromoConfigFormatDataSuccess = (data) => {
  return {
    type: PROMO_CONFIG_FORMAT_SUCCESS,
    data,
  };
};

//get promotion config table data

export const getPromoConfigData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPromoConfigDataApi()
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPromoConfigDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getPromoConfigDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('promotion-config?page=1&&limit=0', { headers: headers });
  return result;
};

export const getPromoConfigDataSuccess = (data) => {
  return {
    type: PROMO_CONFIG_DATA_SUCCESS,
    data,
  };
};

//delete promotion config table data

export const deletePromoConfigData = (id) => {
  return (dispatch) => {
    deletePromoConfigDataApi(id).then((response) => {
      toast.success(response.data.response);
      dispatch(getPromoConfigData());
    });
  };
};
const deletePromoConfigDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('promotion-config/' + id, { headers: headers });
  return result;
};

//material pricing

// add edit material pricing
export const addMaterialPricingFormatData = (data, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addMaterialPricingFormatDataApi(data, id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push('/material-pricing/excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addMaterialPricingFormatDataApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id) {
    result = axios.put(
      'upload-format/' + id + '?app-functionality=MaterialPricingsFormat',
      data,
      { headers: headers },
    );
  } else {
    result = axios.post(
      'upload-format?app-functionality=MaterialPricingsFormat',
      data,
      { headers: headers },
    );
  }
  return result;
};

// delete material pricing

export const deleteMaterialPricingFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteMaterialPricingFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          dispatch(getMaterialPricingFormatData());
        } else {
          //toast.error(response.data.error.message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const deleteMaterialPricingFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};

//upload material pricing data file

export const uploadMaterialPricingDataFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadMaterialPricingDataFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadMaterialPricingSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadMaterialPricingDataFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('upload-file?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};

export const uploadMaterialPricingSuccess = (data) => {
  return {
    type: MATERIAL_PRICING_UPLOAD_SUCCESS,
    data,
  };
};

//get Material Pricing format fields

export const getMaterialPricingFormatFields = () => {
  return (dispatch) => {
    getMaterialPricingFormatFieldsApi()
      .then((response) => {
        dispatch(getMaterialPricingFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getMaterialPricingFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=MaterialPricingsFormat',
    { headers: headers },
  );
  return result;
};

export const getMaterialPricingFormatFieldsSuccess = (data) => {
  return {
    type: MATERIAL_PRICING_FORMAT_FIELDS_SUCCESS,
    data,
  };
};

//get Material Pricing format data

export const getMaterialPricingFormatData = () => {
  return (dispatch) => {
    getMaterialPricingFormatDataApi()
      .then((response) => {
        dispatch(getMaterialPricingFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getMaterialPricingFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format?app-functionality=MaterialPricingsFormat', {
    headers: headers,
  });
  return result;
};

export const getMaterialPricingFormatDataSuccess = (data) => {
  return {
    type: MATERIAL_PRICING_FORMAT_SUCCESS,
    data,
  };
};

// for calculating costing simulation
export const runCostingSimulation = (data) => {
  return (dispatch) => {
    dispatch(CostingSimulationStart());
    runCostingSimulationApi(data)
      .then((response) => {
        dispatch(CostingSimulationEnd());
        dispatch(runCostingSimulationSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(CostingSimulationEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const runCostingSimulationApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('costing-simulation', data, { headers: headers });
  return result;
};

export const runCostingSimulationSuccess = (data) => {
  return {
    type: COSTING_SIMULATION_SUCCESS,
    data,
  };
};
export const CostingSimulationStart = (data) => {
  if (data == 'market') {
    return {
      type: MARKET_START,
      data,
    };
  } else {
    return {
      type: COSTING_START,
      data,
    };
  }
};
export const CostingSimulationEnd = (data) => {
  if (data == 'market')
    return {
      type: MARKET_END,
      data,
    };
  else
    return {
      type: COSTING_END,
      data,
    };
};

//create scenario
export const PromotionScenario = (data, id, type, saveType) => {
  return (dispatch) => {
    dispatch(getPromoScenarioData(null, true));
    PromotionScenarioAPI(data, id, type)
      .then((response) => {
        if (saveType !== 'auto_save') toast.success(response.data.response);
        console.log(response.data);
        //dispatch(getPromoScenarioData(response.data.data))
        if (type == 'edit' || type == 'prefill' || type == 'last_activity') {
          dispatch(getPromoScenarioData(response.data.response, false));
          if (type == 'edit' || type == 'last_activity')
            history.push({
              pathname: '/promotion-scenario-setup/edit/' + id,
              data: { page: type },
            });
        } else dispatch(getPromoScenarioData(response.data.data, false));
      })
      .catch((error) => {
        dispatch(getPromoScenarioData(null, false));
      });
  };
};

const PromotionScenarioAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'delete')
    result = axios.delete('promotion/scenario/' + id, { headers: headers });
  if (type == 'edit' || type == 'prefill' || type == 'last_activity')
    result = axios.post('promotion/scenario', data, { headers: headers });
  else result = axios.post('promotion/scenarios', data, { headers: headers });
  //}
  return result;
};

export const getPromoScenarioData = (data, loader) => {
  return {
    type: SCENARIO_DATA,
    data,
    loader,
  };
};
// export const getClearData = () => {
//   return {
//     type: CLEAR_DATA
//   };
// };
//old-ne
export const createPromotionScenario = (data, id, type, page) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createPromotionScenarioAPI(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (type == 'fetch_id') {
          dispatch(getPromoScenarioById(response.data.response));
          if (page == 'edit')
            history.push({
              pathname: '/promotion-scenario-setup/edit/' + id,
              data: { page: page },
            });
          else {
            if (page !== 'prefillGrid')
              history.push({
                pathname: '/promotion-scenario-setup',
                data: { page: page },
              });
          }
        } else {
          if (
            type == 'partial_save' ||
            page == 'partial_save' ||
            page == 'review'
          ) {
            dispatch(getSavedScenario(response.data.data));
          } else
            history.push({
              pathname: '/promotion-dashboard',
              data: { productLine: data.product_lines, page: type },
            });
          toast.success(response.data.response);
          if (response.data.warning_message)
            toast.warn(response.data.warning_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createPromotionScenarioAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'fetch_id') {
    result = axios.get('promotion-scenario/' + id, { headers: headers });
  } else if (type == 'edit') {
    result = axios.put('promotion-scenario/' + data.ID, data, {
      headers: headers,
    });
  } else result = axios.post('promotion-scenario', data, { headers: headers });
  //}
  return result;
};
export const getPromoScenarioById = (data) => {
  return {
    type: SCENARIO_BY_ID,
    data,
  };
};
export const getSavedScenario = (data) => {
  return {
    type: SAVED_SCENARIO,
    data,
  };
};
// get api for scenario dropdown
export const getPrefillScenarioList = (data) => {
  return (dispatch) => {
    dispatch(CostingSimulationStart());
    getPrefillScenarioListApi(data)
      .then((response) => {
        dispatch(CostingSimulationEnd());
        dispatch(getPrefillScenarioListSuccess(response.data.response));
        // history.push({ pathname: "/promotion-scenario-setup" });
      })
      .catch((error) => {
        dispatch(CostingSimulationEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const getPrefillScenarioListApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('promotion-define-market', data, { headers: headers });
  return result;
};

export const getPrefillScenarioListSuccess = (data) => {
  return {
    type: PREFILL_SCENARIO_SUCCESS,
    data,
  };
};

//get material pricing table data

export const getMaterialPricingData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getMaterialPricingDataApi()
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getMaterialPricingDataSuccess(response.data.response));
        console.log(response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getMaterialPricingDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('material-pricing?page=1&&limit=0', { headers: headers });
  return result;
};

export const getMaterialPricingDataSuccess = (data) => {
  return {
    type: MATERIAL_PRICING_DATA_SUCCESS,
    data,
  };
};

//get material pricing table data

export const getSingleMaterialPricingData = (id) => {
  return (dispatch) => {
    getSingleMaterialPricingDataApi(id)
      .then((response) => {
        dispatch(getSingleMaterialPricingDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};
const getSingleMaterialPricingDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('material-pricing/' + id, { headers: headers });
  return result;
};

export const getSingleMaterialPricingDataSuccess = (data) => {
  return {
    type: MATERIAL_PRICING_SINGLE_DATA_SUCCESS,
    data,
  };
};

//edit page material pricing table data

export const putMaterialPricingData = (data, id) => {
  return (dispatch) => {
    putMaterialPricingDataApi(data, id).then((response) => {
      toast.success('Successfully updated.');
      history.push('/material-pricing');
    });
  };
};

const putMaterialPricingDataApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('update-material-pricing/' + id, data, {
    headers: headers,
  });
  return result;
};

//delete material pricing table data

export const deleteMaterialPricingData = (id) => {
  return (dispatch) => {
    deleteMaterialPricingDataApi(id).then((response) => {
      toast.success(response.data.response);
      dispatch(getMaterialPricingData());
    });
  };
};
const deleteMaterialPricingDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('material-pricing/' + id, { headers: headers });
  return result;
};

//

export const getPromotionsSearch = (
  pagination,
  limit,
  keyword,
  data,
  table,
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPromotionsSearchApi(pagination, limit, keyword, data, table)
      .then((response) => {
        if (keyword === 'search') {
          dispatch(fetchEnd());
          dispatch(getPromotionsSearchSuccess(response.data.response));
          if (table === 'sales_forecast_data')
            history.push({
              pathname: '/sales-forecast-main',
              state: 'SalesForecast',
            });
          else if (table === 'material_pricings')
            history.push({
              pathname: '/material-pricing',
              state: 'MaterialPricing',
            });
          else if (table === 'coverage_cost_mappings')
            history.push({
              pathname: '/coverage-cost-mapping',
              state: 'CoverageCostMapping',
            });
          else
            history.push({ pathname: '/coverage-cost', state: 'CoverageCost' });
        } else {
          dispatch(fetchEnd());
          dispatch(getPromotionsSearchSuccess(response.data.response));
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPromotionsSearchApi = (pagination, limit, keyword, data, table) => {
  if (keyword === 'search') {
    for (var propName in data) {
      if (
        data[propName] === '' ||
        data[propName] === null ||
        data[propName] === undefined ||
        data[propName].length === 0 ||
        data[propName] ===
        '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
        data[propName] === '1970-01-01T00:00:00.000Z' ||
        data[propName] === ','
      ) {
        delete data[propName];
      }
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (keyword === 'search') {
    result = axios.post(
      'promotions-search?page=' +
      pagination +
      '&limit=' +
      0 +
      '&table_name=' +
      table,
      data,
      { headers: headers },
    );
  } else {
    if (pagination === 'NoLimit') {
      result = axios.get('promotions-search?limit=0', { headers: headers });
    } else {
      result = axios.get(
        'promotions-search?page=' + pagination + '&limit=' + limit,
        { headers: headers },
      );
    }
  }
  return result;
};

export const getPromotionsSearchSuccess = (data) => {
  return {
    type: PROMOTIONS_SEARCH_SUCCESS,
    data,
  };
};

//get promotion config data

export const getSinglePromotionConfigData = (id) => {
  return (dispatch) => {
    getSinglePromotionConfigDataApi(id)
      .then((response) => {
        dispatch(getSinglePromotionConfigDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};
const getSinglePromotionConfigDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('promotion-config/' + id, { headers: headers });
  return result;
};

export const getSinglePromotionConfigDataSuccess = (data) => {
  return {
    type: PROMO_CONFIG_SINGLE_DATA_SUCCESS,
    data,
  };
};

//edit page promotion config table data

export const putPromotionConfigData = (data, id) => {
  return (dispatch) => {
    putPromotionConfigDataApi(data, id).then((response) => {
      toast.success('Successfully updated.');
      history.push('/promo-config');
    });
  };
};

const putPromotionConfigDataApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('promotion-config/' + id, data, { headers: headers });
  return result;
};

//get scenario list table data

export const getScenarioList = (productLine, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getScenarioListApi(productLine, data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getScenarioListSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getScenarioListApi = (productLine, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (data) {
    result = axios.get(
      'promotion/scenario-list?',
      { params: data },
      { headers: headers },
    );
  } else
    result = axios.get('promotion/scenario-list?product_lines=' + productLine, {
      headers: headers,
    });
  return result;
};

export const getScenarioListSuccess = (data) => {
  return {
    type: SCENARIO_LIST_DATA_SUCCESS,
    data,
  };
};

// scenario list actions buttons

export const createScenarioList = (data, type, value, fieldName) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createScenarioListAPI(data, type, value, fieldName)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
        // history.push("/promotion-scenario-list");
        if (type == 'delete' || type == 'clone' || type == 'extended') {
          // console.log(value.product_line[0])
          dispatch(getScenarioList(value.product_line));
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createScenarioListAPI = (data, type, value, fieldName) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'extended' || type === 'clone')
    result = axios.post('promotion/scenario/clone', data, {
      headers: headers,
    });
  else if (type === 'delete')
    result = axios.delete('promotion/scenario/' + value.ID, {
      headers: headers,
    });
  // else if (type === 'clone')
  //   result = axios.post("promotion/scenario/clone", data, {
  //     headers: headers,
  //   });
  // else
  //   result = axios.put("/promotion-scenario/" + value.ID, data, {
  //     headers: headers,
  //   });
  return result;
};

//coverage cost mapping

// add edit coverage cost mapping
export const addCoverageMappingFormatData = (data, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addCoverageMappingFormatDataApi(data, id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push('/coverage-cost-mapping/excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addCoverageMappingFormatDataApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id) {
    result = axios.put(
      'upload-format/' +
      id +
      '?app-functionality=PromotionCoverageCostMappingsFormat',
      data,
      { headers: headers },
    );
  } else {
    result = axios.post(
      'upload-format?app-functionality=PromotionCoverageCostMappingsFormat',
      data,
      { headers: headers },
    );
  }
  return result;
};

// delete coverage cost mapping

export const deleteCoverageMappingFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteCoverageMappingFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          dispatch(getCoverageMappingFormatData());
        } else {
          //toast.error(response.data.error.message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const deleteCoverageMappingFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};

//upload coverage cost mapping data file

export const uploadCoverageMappingDataFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadCoverageMappingDataFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadCoverageMappingSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadCoverageMappingDataFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('upload-file?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};

export const uploadCoverageMappingSuccess = (data) => {
  return {
    type: COVERAGE_MAPPING_UPLOAD_SUCCESS,
    data,
  };
};

//get Coverage Cost Mapping format fields

export const getCoverageMappingFormatFields = () => {
  return (dispatch) => {
    getCoverageMappingFormatFieldsApi()
      .then((response) => {
        dispatch(getCoverageMappingFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getCoverageMappingFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=PromotionCoverageCostMappingsFormat',
    { headers: headers },
  );
  return result;
};

export const getCoverageMappingFormatFieldsSuccess = (data) => {
  return {
    type: COVERAGE_MAPPING_FORMAT_FIELDS_SUCCESS,
    data,
  };
};

//get Coverage Cost Mapping format data

export const getCoverageMappingFormatData = () => {
  return (dispatch) => {
    getCoverageMappingFormatDataApi()
      .then((response) => {
        dispatch(getCoverageMappingFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getCoverageMappingFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=PromotionCoverageCostMappingsFormat',
    { headers: headers },
  );
  return result;
};

export const getCoverageMappingFormatDataSuccess = (data) => {
  return {
    type: COVERAGE_MAPPING_FORMAT_SUCCESS,
    data,
  };
};

//delete coverage mapping table data

export const deleteCoverageMappingData = (id) => {
  return (dispatch) => {
    deleteCoverageMappingDataApi(id).then((response) => {
      toast.success(response.data.response);
      dispatch(getCoverageMappingData());
    });
  };
};
const deleteCoverageMappingDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('coverage-cost-mapping/' + id, { headers: headers });
  return result;
};

//get Coverage cost mapping table data

export const getCoverageMappingData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCoverageMappingDataApi()
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getCoverageMappingDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getCoverageMappingDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('coverage-cost-mapping?page=1&&limit=0', {
    headers: headers,
  });
  return result;
};

export const getCoverageMappingDataSuccess = (data) => {
  return {
    type: COVERAGE_MAPPING_DATA_SUCCESS,
    data,
  };
};

// get single data for Coverage cost mapping

export const getCoverageMappingSingleItem = (id) => {
  return (dispatch) => {
    getCoverageMappingSingleItemAPI(id).then((response) => {
      dispatch(getCoverageMappingSingleItemSuccess(response.data.response));
    });
  };
};
const getCoverageMappingSingleItemAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('coverage-cost-mapping/' + id, { headers: headers });
  return result;
};

export const getCoverageMappingSingleItemSuccess = (data) => {
  return {
    type: COVERAGE_MAPPING_SINGLE_SUCCESS,
    data,
  };
};

//edit page  Coverage cost mapping table data

export const updateCoverageMappingData = (data, id) => {
  return (dispatch) => {
    updateCoverageMappingDataApi(data, id).then((response) => {
      toast.success('Successfully updated.');
      history.push('/coverage-cost-mapping');
    });
  };
};

const updateCoverageMappingDataApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('update-coverage-cost-mapping/' + id, data, {
    headers: headers,
  });
  return result;
};

//Standard Finance cost

// add edit Standard Finance Cost
export const addStandardFinanceFormatData = (data, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addStandardFinanceFormatDataApi(data, id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push('/standard-finance-cost/excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addStandardFinanceFormatDataApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id) {
    result = axios.put(
      'upload-format/' +
      id +
      '?app-functionality=PromotionStandardFinanceCostsFormat',
      data,
      { headers: headers },
    );
  } else {
    result = axios.post(
      'upload-format?app-functionality=PromotionStandardFinanceCostsFormat',
      data,
      { headers: headers },
    );
  }
  return result;
};

// delete Standard Finance Cost

export const deleteStandardFinanceFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteStandardFinanceFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          dispatch(getStandardFinanceFormatData());
        } else {
          //toast.error(response.data.error.message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const deleteStandardFinanceFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};

//upload Standard Finance Cost data file

export const uploadStandardFinanceDataFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadStandardFinanceDataFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadStandardFinanceSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadStandardFinanceDataFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('upload-file?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};

export const uploadStandardFinanceSuccess = (data) => {
  return {
    type: STANDARD_FINANCE_UPLOAD_SUCCESS,
    data,
  };
};

//get Standard Finance Cost format fields

export const getStandardFinanceFormatFields = () => {
  return (dispatch) => {
    getStandardFinanceFormatFieldsApi()
      .then((response) => {
        dispatch(getStandardFinanceFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getStandardFinanceFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=PromotionStandardFinanceCostsFormat',
    { headers: headers },
  );
  return result;
};

export const getStandardFinanceFormatFieldsSuccess = (data) => {
  return {
    type: STANDARD_FINANCE_FORMAT_FIELDS_SUCCESS,
    data,
  };
};

//get Standard Finance Cost format data

export const getStandardFinanceFormatData = () => {
  return (dispatch) => {
    getStandardFinanceFormatDataApi()
      .then((response) => {
        dispatch(getStandardFinanceFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getStandardFinanceFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=PromotionStandardFinanceCostsFormat',
    { headers: headers },
  );
  return result;
};

export const getStandardFinanceFormatDataSuccess = (data) => {
  return {
    type: STANDARD_FINANCE_FORMAT_SUCCESS,
    data,
  };
};

//get Standard Finance Cost table data

export const getStandardFinanceData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getStandardFinanceDataApi()
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getStandardFinanceDataSuccess(response.data.response));
        console.log(response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getStandardFinanceDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('promotion-finances?page=1&&limit=0', {
    headers: headers,
  });
  return result;
};

export const getStandardFinanceDataSuccess = (data) => {
  return {
    type: STANDARD_FINANCE_DATA_SUCCESS,
    data,
  };
};

//delete Standard Finance Cost table data

export const deleteStandardFinanceData = (id) => {
  return (dispatch) => {
    deleteStandardFinanceDataApi(id).then((response) => {
      toast.success(response.data.response);
      dispatch(getStandardFinanceData());
    });
  };
};
const deleteStandardFinanceDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('promotion-finance/' + id, { headers: headers });
  return result;
};

//get single standard finance table data

export const getSingleStandardFinanceData = (id) => {
  return (dispatch) => {
    getSingleStandardFinanceDataApi(id)
      .then((response) => {
        dispatch(getSingleStandardFinanceDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};
const getSingleStandardFinanceDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('promotion-finance/' + id, { headers: headers });
  return result;
};

export const getSingleStandardFinanceDataSuccess = (data) => {
  return {
    type: STANDARD_FINANCE_SINGLE_DATA_SUCCESS,
    data,
  };
};

//edit page standard finance table data

export const updateStandardFinanceData = (data, id) => {
  return (dispatch) => {
    updateStandardFinanceDataApi(data, id).then((response) => {
      toast.success('Successfully updated.');
      history.push('/standard-finance-cost');
    });
  };
};

const updateStandardFinanceDataApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('update-promotion-finance/' + id, data, {
    headers: headers,
  });
  return result;
};

// Promotion Currency Rates

// add edit  Promotion Currency Rates
export const addPromotionCurrencyFormatData = (data, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addPromotionCurrencyFormatDataApi(data, id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push('/promo-currency/excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addPromotionCurrencyFormatDataApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id) {
    result = axios.put(
      'upload-format/' + id + '?app-functionality=PromotionCurrencyRatesFormat',
      data,
      { headers: headers },
    );
  } else {
    result = axios.post(
      'upload-format?app-functionality=PromotionCurrencyRatesFormat',
      data,
      { headers: headers },
    );
  }
  return result;
};

// delete Promotion Currency Rates

export const deletePromotionCurrencyFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deletePromotionCurrencyFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          dispatch(getPromotionCurrencyFormatData());
        } else {
          //toast.error(response.data.error.message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const deletePromotionCurrencyFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};

//upload Promotion Currency Rates data file

export const uploadPromotionCurrencyDataFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadPromotionCurrencyDataFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadPromotionCurrencySuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadPromotionCurrencyDataFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('upload-file?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};

export const uploadPromotionCurrencySuccess = (data) => {
  return {
    type: PROMOTION_CURRENCY_UPLOAD_SUCCESS,
    data,
  };
};

//get Promotion Currency Rates format fields

export const getPromotionCurrencyFormatFields = () => {
  return (dispatch) => {
    getPromotionCurrencyFormatFieldsApi()
      .then((response) => {
        dispatch(
          getPromotionCurrencyFormatFieldsSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getPromotionCurrencyFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=PromotionCurrencyRatesFormat',
    { headers: headers },
  );
  return result;
};

export const getPromotionCurrencyFormatFieldsSuccess = (data) => {
  return {
    type: PROMOTION_CURRENCY_FORMAT_FIELDS_SUCCESS,
    data,
  };
};

//get Promotion Currency Rates format data

export const getPromotionCurrencyFormatData = () => {
  return (dispatch) => {
    getPromotionCurrencyFormatDataApi()
      .then((response) => {
        dispatch(getPromotionCurrencyFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getPromotionCurrencyFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=PromotionCurrencyRatesFormat',
    { headers: headers },
  );
  return result;
};

export const getPromotionCurrencyFormatDataSuccess = (data) => {
  return {
    type: PROMOTION_CURRENCY_FORMAT_SUCCESS,
    data,
  };
};

//get Promotion Currency Rates table data

export const getPromotionCurrencyData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPromotionCurrencyDataApi()
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPromotionCurrencyDataSuccess(response.data.response));
        console.log(response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getPromotionCurrencyDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('promo-currency-rates?page=1&&limit=0', {
    headers: headers,
  });
  return result;
};

export const getPromotionCurrencyDataSuccess = (data) => {
  return {
    type: PROMOTION_CURRENCY_DATA_SUCCESS,
    data,
  };
};

//delete Promotion Currency Rates table data

export const deletePromotionCurrencyData = (id) => {
  return (dispatch) => {
    deletePromotionCurrencyDataApi(id).then((response) => {
      toast.success(response.data.response);
      dispatch(getPromotionCurrencyData());
    });
  };
};
const deletePromotionCurrencyDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('promo-currency-rate/' + id, { headers: headers });
  return result;
};

// get single data for Promotion Currency Rates

export const getPromotionCurrencySingleItem = (id) => {
  return (dispatch) => {
    getPromotionCurrencySingleItemAPI(id).then((response) => {
      dispatch(getPromotionCurrencySingleItemSuccess(response.data.response));
    });
  };
};
const getPromotionCurrencySingleItemAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('promo-currency-rate/' + id, { headers: headers });
  return result;
};

export const getPromotionCurrencySingleItemSuccess = (data) => {
  return {
    type: PROMOTION_CURRENCY_SINGLE_SUCCESS,
    data,
  };
};

//edit page Promotion Currency table data

export const updatePromotionCurrencyData = (data, id) => {
  return (dispatch) => {
    updatePromotionCurrencyDataApi(data, id).then((response) => {
      toast.success('Successfully updated.');
      history.push('/promo-currency');
    });
  };
};

const updatePromotionCurrencyDataApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('update-promo-currency-rate/' + id, data, {
    headers: headers,
  });
  return result;
};

//edit Finance Cost table single data

export const updateFinanceCostData = (data, id) => {
  return (dispatch) => {
    updateFinanceCostDataApi(data, id).then((response) => {
      toast.success('Successfully updated.');
      history.push('/finance-cost');
    });
  };
};

const updateFinanceCostDataApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('update-finance-cost/' + id, data, { headers: headers });
  return result;
};

// edit Promotion territory single data

export const updatePromotionTerritoryData = (data, id) => {
  return (dispatch) => {
    updatePromotionTerritoryDataApi(data, id).then((response) => {
      toast.success('Successfully updated.');
      history.push('/promotion-territory');
    });
  };
};

const updatePromotionTerritoryDataApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('update-promo-territory/' + id, data, {
    headers: headers,
  });
  return result;
};

//get sales forecast single table data

export const getSingleSalesForecastData = (id) => {
  return (dispatch) => {
    getSingleSalesForecastDataApi(id)
      .then((response) => {
        dispatch(getSingleSalesForecastDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};
const getSingleSalesForecastDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('sales-forecast/' + id, { headers: headers });
  return result;
};

export const getSingleSalesForecastDataSuccess = (data) => {
  return {
    type: SALES_FORECAST_SINGLE_DATA_SUCCESS,
    data,
  };
};

//edit page sales forecast table data

export const updateSalesForecastData = (data, id) => {
  return (dispatch) => {
    updateSalesForecastDataApi(data, id).then((response) => {
      // toast.success("Successfully updated.");
      toast.success(response.data.response);
      dispatch(
        getPromotionsSearch(
          1,
          0,
          'search',
          JSON.parse(sessionStorage.getItem('search-data')),
          'sales_forecast_data',
        ),
      );
    });
  };
};

const updateSalesForecastDataApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('update-sales-forecast/' + id, data, { headers: headers });
  return result;
};

//delete sales forecast table data

export const deleteSalesForecastData = (id) => {
  return (dispatch) => {
    deleteSalesForecastDataApi(id).then((response) => {
      toast.success(response.data.response);
      dispatch(
        getPromotionsSearch(
          1,
          0,
          'search',
          JSON.parse(sessionStorage.getItem('search-data')),
          'sales_forecast_data',
        ),
      );
    });
  };
};
const deleteSalesForecastDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('delete-sales-forecast/' + id, { headers: headers });
  return result;
};

//add promotion mapping single table data

export const addPromotionMappingData = (data) => {
  return (dispatch) => {
    addPromotionMappingDataApi(data).then((response) => {
      toast.success('Successfully updated.');
      history.push('/promo-config');
    });
  };
};

const addPromotionMappingDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('promo-config', data, { headers: headers });
  return result;
};
//review scenario-market
export const getScenarioMarkets = (data) => {
  return (dispatch) => {
    dispatch(CostingSimulationStart('market'));
    getScenarioMarketsApi(data)
      .then((response) => {
        dispatch(CostingSimulationEnd('market'));
        dispatch(getScenarioMarketsSuccess(response.data.response));
        // history.push({ pathname: "/promotion-scenario-setup" });
      })
      .catch((error) => {
        dispatch(CostingSimulationEnd('market'));
        //toast.error(error.response.data.error.message);
      });
  };
};

const getScenarioMarketsApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('promotion/review/markets', data, { headers: headers });
  // result = axios.post(
  //   "promotion/markets", data,
  //   { headers: headers }
  // );
  return result;
};

export const getScenarioMarketsSuccess = (data) => {
  return {
    type: SCENARIO_MARKETS,
    data,
  };
};
//validate-market
export const validateMarkets = (data, productLine) => {
  return (dispatch) => {
    dispatch(fetchStart);
    validateMarketsApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
        var payload = {
          scenario_number: data.scenario_number,
          product_line: productLine,
        };
        dispatch(getScenarioMarkets(payload));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const validateMarketsApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('promotion/review/market-status', data, {
    headers: headers,
  });
  // result = axios.put(
  //   "promotion/market-status", data,
  //   { headers: headers }
  // );
  return result;
};
//reviewSenario-previous scenario dropdown
export const getPreviousScenarioList = (data, type) => {
  return (dispatch) => {
    dispatch(CostingSimulationStart());
    getPreviousScenarioListApi(data, type)
      .then((response) => {
        dispatch(CostingSimulationEnd());
        if (type == 'approved')
          dispatch(getPreviousScenarioListSuccess(response.data.response));
        else dispatch(getCurrentScenariosSuccess(response.data.response));
        // history.push({ pathname: "/promotion-scenario-setup" });
      })
      .catch((error) => {
        dispatch(CostingSimulationEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const getPreviousScenarioListApi = (data, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('promotion/review/scenarios', data, { headers: headers });
  // if (type == 'Finance Team')
  //   result = axios.get(
  //     "promotion/market-scenarios?product_line=" + data,
  //     { headers: headers }
  //   );
  // else
  // result = axios.post(
  //   "promotion/scenario-dropdown", data,
  //   { headers: headers }
  // );
  return result;
};

export const getPreviousScenarioListSuccess = (data) => {
  return {
    type: PREVIOUS_SCENARIO,
    data,
  };
};
export const getCurrentScenariosSuccess = (data) => {
  return {
    type: CURRENT_SCENARIO,
    data,
  };
};
//reviewScenario-comparison
export const runScenarioComparison = (data) => {
  return (dispatch) => {
    dispatch(CostingSimulationStart());
    runScenarioComparisonApi(data)
      .then((response) => {
        dispatch(CostingSimulationEnd());
        dispatch(runScenarioComparisonSuccess(response.data.response));
        // history.push({ pathname: "/promotion-scenario-setup" });
      })
      .catch((error) => {
        dispatch(CostingSimulationEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const runScenarioComparisonApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('promotion/review/market-stickers', data, {
    headers: headers,
  });
  // result = axios.post(
  //   "promotion/market-stickers", data,
  //   { headers: headers }
  // );
  return result;
};

export const runScenarioComparisonSuccess = (data) => {
  return {
    type: REVIEW_SCENARIO,
    data,
  };
};
//custom group
export const createCustomGroup = (data, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createCustomGroupApi(data, id).then((response) => {
      dispatch(fetchEnd());
      toast.success(response.data.response);
      dispatch(getCustomGroups(null, data.product_line));
      history.push({
        pathname: '/custom-group',
        productLine: data.product_line,
        page: 'Custom Group',
      });
    });
  };
};

const createCustomGroupApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id)
    result = axios.put('promotion/custom-group/' + id, data, {
      headers: headers,
    });
  else
    result = axios.post('promotion/custom-group', data, { headers: headers });

  return result;
};
export const getCustomGroups = (id, productLine) => {
  return (dispatch) => {
    dispatch(fetchStart());
    dispatch(CostingSimulationStart());
    getCustomGroupsApi(id, productLine)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(CostingSimulationEnd());
        dispatch(getCustomGroupsSuccess(response.data.response, id));
        if (id && !isArray(id))
          history.push({ pathname: '/update-custom-group/' + id });
      })
      .catch((error) => {
        dispatch(CostingSimulationEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const getCustomGroupsApi = (id, productLine) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id) {
    if (isArray(id))
      result = axios.get('promotion/custom-groups?id.in=' + id, {
        headers: headers,
      });
    else
      result = axios.get('promotion/custom-group/' + id, { headers: headers });
  } else
    result = axios.get('promotion/custom-group?product_line=' + productLine, {
      headers: headers,
    });
  return result;
};

export const getCustomGroupsSuccess = (data, id) => {
  if (id) {
    if (isArray(id))
      return {
        type: CUSTOM_GROUP_LIST,
        data,
      };
    else
      return {
        type: CUSTOM_GROUP_ID,
        data,
      };
  } else
    return {
      type: CUSTOM_GROUPS,
      data,
    };
};

//clone custom groups
export const cloneCustomGroups = (id, productLine) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCloneCustomGroupsApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
        dispatch(getCustomGroups(null, productLine));
        history.push({
          pathname: '/custom-group',
          data: { productLine: productLine, page: 'clone' },
        });
      })
      .catch((error) => {
        dispatch(fetchEnd());
        // toast.error('Something went wrong.');
      });
  };
};

const getCloneCustomGroupsApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('promotion/custom-group/' + id + '/clone', {
    headers: headers,
  });
  return result;
};
//create archive
export const createArchive = (id, productline) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCreateArchiveApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
        dispatch(getCustomGroups(null, productline));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        // toast.error('Something went wrong.');
      });
  };
};

const getCreateArchiveApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'promotion/' + id + '/archive?table_name=promotion_custom_groups',
    {
      headers: headers,
    },
  );
  return result;
};
//archive list
export const getArchiveList = (productLine) => {
  return (dispatch) => {
    dispatch(fetchStart());
    dispatch(CostingSimulationStart());
    getArchiveListApi(productLine)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(CostingSimulationEnd());
        dispatch(getArchiveListSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(CostingSimulationEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const getArchiveListApi = (productLine) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;

  result = axios.get(
    'promotion/custom-group?product_line=' + productLine + '&archive=true',
    { headers: headers },
  );

  return result;
};

export const getArchiveListSuccess = (data, id) => {
  return {
    type: ARCHIVE,
    data,
  };
};
export const getTRApprovalData = (productLine, country) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getTRApprovalDataApi(productLine, country)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getTRApprovalDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getTRApprovalDataApi = (productLine, country) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'promotion-tr-approval?product_line=' + productLine + '&country=' + country,
    {
      headers: headers,
    },
  );
  return result;
};

export const getTRApprovalDataSuccess = (data) => {
  return {
    type: TR_APPROVAL_DATA_SUCCESS,
    data,
  };
};

// //   Get TR Approval Review data
export const getTRApprovalReviewData = (data, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getTRApprovalReviewDataApi(data, type)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getTRApprovalReviewDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(getTRApprovalReviewDataSuccess({}));
      });
  };
};

const getTRApprovalReviewDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('promotion-tr-models', data, {
    headers: headers,
  });
  return result;
};

export const getTRApprovalReviewDataSuccess = (data) => {
  return {
    type: TR_APPROVAL_REVIEW_DATA_SUCCESS,
    data,
  };
};

export const getTRApprovalReviewCompletedDataSuccess = (data) => {
  return {
    type: TR_APPROVAL_REVIEW_COMPLETED_DATA_SUCCESS,
    data,
  };
};

// update TR approval stickers
export const updateTrApprovalStickerData = (scenarioId, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateTrApprovalStickerDataApi(scenarioId, data)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const updateTrApprovalStickerDataApi = (scenarioId, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('update-tr-approval?scenario_id=' + scenarioId, data, {
    headers: headers,
  });
  return result;
};
//custom groups in grid
export const getGroupedCustomGroups = (data) => {
  return (dispatch) => {
    getGroupedCustomGroupsApi(data)
      .then((response) => {
        dispatch(getGroupedCustomGroupsSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};
const getGroupedCustomGroupsApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('promotion/custom-grouped-data', data, {
    headers: headers,
  });
  return result;
};
export const getGroupedCustomGroupsSuccess = (data) => {
  return {
    type: GROUPED_CUSTOM_GROUP,
    data,
  };
};
//upload Costing file download after Comparison

export const uploadCostingAfterComparisonDataFile = (
  scenarioNumber,
  isDownload,
  file,
  name,
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadCostingAfterComparisonDataFileApi(
      scenarioNumber,
      isDownload,
      file,
      name,
    )
      .then((response) => {
        if (isDownload) {
          dispatch(fetchEnd());
          toast('Please wait... The sheet is getting downloaded.');
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data]),
          );
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.setAttribute('download', `${name}_differences.xlsx`);
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(downloadUrl);
        } else {
          dispatch(fetchEnd());
          dispatch(uploadCostingAfterComparisonSuccess(response.data.response));
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadCostingAfterComparisonDataFileApi = (
  scenarioNumber,
  isDownload,
  file,
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  if (isDownload) {
    result = axios.post(
      'promotion/download-compare-data?scenario_number=' +
      scenarioNumber +
      '&download=' +
      isDownload,
      file,
      {
        headers: headers,
        responseType: 'blob',
      },
    );
  } else {
    result = axios.post(
      'promotion/download-compare-data?scenario_number=' +
      scenarioNumber +
      '&download=' +
      isDownload,
      file,
      {
        headers: headers,
      },
    );
  }
  return result;
};

export const uploadCostingAfterComparisonSuccess = (data) => {
  return {
    type: PROMOTION_COSTING_COMPARISON_UPLOAD_SUCCESS,
    data,
  };
};

// //   get dropdown data for file download
export const getDropdownScenarioData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getDropdownScenarioDataApi()
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getDropdownScenarioDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getDropdownScenarioDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('promotion/costing-scenarios', {
    headers: headers,
  });
  return result;
};

export const getDropdownScenarioDataSuccess = (data) => {
  return {
    type: PROMO_COSTING_DROPDOWN_DATA_SUCCESS,
    data,
  };
};

//approve scenario
export const approveScenario = (id, status) => {
  return (dispatch) => {
    dispatch(fetchStart());
    approveScenarioApi(id, status)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const approveScenarioApi = (id, status) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;

  result = axios.put('promotion/' + id + '/update-status/' + status, {
    headers: headers,
  });
  return result;
};

//   get table data for costing
export const getCostingTableData = (productLine, scenarioNumber) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCostingTableDataApi(productLine, scenarioNumber)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getCostingTableDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getCostingTableDataApi = (productLine, scenarioNumber) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'promotion-costing-approval?product_line=' +
    productLine +
    '&scenario_number=' +
    scenarioNumber,
    {
      headers: headers,
    },
  );
  return result;
};

export const getCostingTableDataSuccess = (data) => {
  return {
    type: PROMO_COSTING_TABLE_DATA_SUCCESS,
    data,
  };
};
//access grid
export const getAccessGrid = (data) => {
  return (dispatch) => {
    dispatch(getAccessGridData(null, true));
    getAccessGridAPI(data)
      .then((response) => {
        dispatch(getAccessGridData(response.data.response, false));
      })
      .catch((error) => {
        dispatch(getAccessGridData(null, false));
      });
  };
};

const getAccessGridAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('promotion/access-grid', data, { headers: headers });
  //}
  return result;
};

export const getAccessGridData = (data, loader) => {
  return {
    type: GRID_DATA,
    data,
    loader,
  };
};

// create/edit user access

export const createUserAccess = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createUserAccessAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
        dispatch(getUserAccess(data.product_line));
        dispatch(getScenarioList(data.product_line));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createUserAccessAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('promotion/user-access', data, { headers: headers });
  return result;
};

//get user access data

export const getUserAccess = (productLine) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getUserAccessApi(productLine)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getUserAccessSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getUserAccessApi = (productLine) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('promotion/user-access?product_line=' + productLine, {
    headers: headers,
  });
  return result;
};

export const getUserAccessSuccess = (data) => {
  return {
    type: USER_ACCESS_DATA,
    data,
  };
};

// Create and Edit for promotion configuration model Year

export const createPromotionModelYear = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createPromotionModelYearAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(fetchPromotionModelYear());
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createPromotionModelYearAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('promotion/default', data, { headers: headers });
  return result;
};

// get promotion configuration model year

export const fetchPromotionModelYear = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    fetchPromotionModelYearAPI()
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(fetchPromotionModelYearSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const fetchPromotionModelYearAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'promotion/default-all?key_name=promotion_configurations',
    { headers: headers },
  );
  return result;
};

export const fetchPromotionModelYearSuccess = (data) => {
  return {
    type: CURRENT_MODEL_YEAR,
    data,
  };
};

//   get data for finance costing dialog box
export const getFinanceCostingDialogData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getFinanceCostingDialogDataApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getFinanceCostingDialogDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getFinanceCostingDialogDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('promotion/review/markets', data, { headers: headers });
  return result;
};

export const getFinanceCostingDialogDataSuccess = (data) => {
  return {
    type: PROMO_FINANCE_COSTING_DIALOG_DATA_SUCCESS,
    data,
  };
};

//   get data for promotional offers
export const getFinancingOffersData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getFinancingOffersDataApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPromotionalOffersDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getFinancingOffersDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'promotion/promotional-financing?product_line=' +
    data.product_line +
    '&scenario_number=' +
    data.scenario_number,
    { headers: headers },
  );
  return result;
};

export const getPromotionalOffersDataSuccess = (data) => {
  return {
    type: PROMO_PROMOTIONAL_OFFERS_DATA_SUCCESS,
    data,
  };
};

//  get data for Regular Financing Offers

export const getRegularOffersData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getRegularOffersDataApi()
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getRegularOffersDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getRegularOffersDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('promotion/regular-financing', { headers: headers });
  return result;
};

export const getRegularOffersDataSuccess = (data) => {
  return {
    type: PROMO_REGULAR_OFFERS_DATA_SUCCESS,
    data,
  };
};

//   get data for regular fianacing categorised
export const getRegularFinancingData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getRegularFinancingDataApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getRegularFinancingDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getRegularFinancingDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'promotion/format-regular-financing?product_line=' + data.product_line,
    { headers: headers },
  );
  return result;
};

export const getRegularFinancingDataSuccess = (data) => {
  return {
    type: PROMO_REGULAR_FINANCING_DATA_SUCCESS,
    data,
  };
};

// delete

export const deleteRegularOffersData = (id) => {
  return (dispatch) => {
    deleteRegularOffersDataApi(id).then((response) => {
      toast.success(response.data.response);
      dispatch(getRegularOffersData());
    });
  };
};
const deleteRegularOffersDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('promotion/regular-financing/' + id, {
    headers: headers,
  });
  return result;
};

// regular financing offers format
export const getRegularOffersFormatData = () => {
  return (dispatch) => {
    getRegularOffersFormatDataApi()
      .then((response) => {
        dispatch(getRegularOffersFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getRegularOffersFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=PromotionRegularFinancingsFormat',
    { headers: headers },
  );
  return result;
};

export const getRegularOffersFormatDataSuccess = (data) => {
  return {
    type: PROMO_REGULAR_OFFERS_FORMAT_SUCCESS,
    data,
  };
};

// delete Regular Financing format
export const deleteRegularOffersFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteRegularOffersFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          dispatch(getRegularOffersFormatData());
        } else {
          //toast.error(response.data.error.message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const deleteRegularOffersFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};

//upload
export const uploadRegularOffersDataFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadRegularOffersDataFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadRegularOffersSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadRegularOffersDataFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('upload-file?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
export const uploadRegularOffersSuccess = (data) => {
  return {
    type: PROMO_REGULAR_OFFERS_UPLOAD_SUCCESS,
    data,
  };
};

//get regular financing offers format fields
export const getRegularOffersFormatFields = () => {
  return (dispatch) => {
    getRegularOffersFormatFieldsApi()
      .then((response) => {
        dispatch(getRegularOffersFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getRegularOffersFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=PromotionRegularFinancingsFormat',
    { headers: headers },
  );
  return result;
};

export const getRegularOffersFormatFieldsSuccess = (data) => {
  return {
    type: PROMO_REGULAR_OFFERS_FIELDS_SUCCESS,
    data,
  };
};

//add regular finance format
export const addRegularOffersFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addRegularOffersFormatDataApi(data)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push('/regular-offers/regular-offers-excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addRegularOffersFormatDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'upload-format?app-functionality=PromotionRegularFinancingsFormat',
    data,
    { headers: headers },
  );
  return result;
};

// update regular financing offers format
export const updateRegularOffersFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateRegularOffersFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push('/regular-offers/regular-offers-excel-upload');
        } else {
          //toast.error(response.data.error.message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const updateRegularOffersFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'upload-format/' +
    id +
    '?app-functionality=PromotionRegularFinancingsFormat',
    data,
    { headers: headers },
  );
  return result;
};

export const downloadVistex = (data, name) => {
  return (dispatch) => {
    toast.info(
      'Downloading has been started in background. File will be downloaded automatically once ready.',
    );
    dispatch(downloading(true));
    downloadVistexApi(data)
      .then((response) => {
        dispatch(downloading(false));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name + '.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        dispatch(downloading(false));
      });
  };
};

const downloadVistexApi = (data) => {
  const headers = {
    'Content-Type': 'application/xlsx',
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('promotion/vistex-download', data, {
    headers: headers,
    responseType: 'blob',
  });
  return result;
};
export const downloading = (data) => {
  return {
    type: DOWNLOADING,
    data,
  };
};
//for duplicate validation
export const getValidMarkets = (id) => {
  return (dispatch) => {
    getValidMarketsApi(id).then((response) => {
      dispatch(getValidMarketsSuccess(response.data.response));
    });
  };
};

const getValidMarketsApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('promotion/scenario/grids/' + id, { headers: headers });
  return result;
};

export const getValidMarketsSuccess = (data) => {
  return {
    type: VALID_MARKETS,
    data,
  };
};
