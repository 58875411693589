import { QUERY_LIST_SUCCESS, QUERY_DETAIL_SUCCESS } from './ActionTypes';
import axios from '../../../../axios.conf';
import { history } from '../../../../components/Helpers';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';

export const getQueryasListSuccess = (data) => {
  return {
    type: QUERY_LIST_SUCCESS,
    data,
  };
};

export const getQueryasList = (pagination, limit) => {
  return (dispatch) => {
    getQueryasListAPI(pagination, limit)
      .then((response) => {
        dispatch(getQueryasListSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};

const getQueryasListAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'sql-work-bench?page=' +
      pagination +
      '&limit=' +
      limit +
      '&application_type=' +
      sessionStorage.getItem('application'),
    { headers: headers },
  );
  return result;
};

export const submitQuery = (data, id, type) => {
  return (dispatch) => {
    submitQueryAPI(data, id, type)
      .then((response) => {
        history.push('/query-field');
        dispatch(getQueryasList());
        toast.success(response.data.response);
      })
      .catch((error) => {
        //toast.error(error.response.data);
      });
  };
};

const submitQueryAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'put') {
    result = axios.put('sql-work-bench/' + id, data, { headers: headers });
  } else if (type === 'delete') {
    result = axios.delete('sql-work-bench/' + id, data, { headers: headers });
  } else {
    result = axios.post('sql-work-bench', data, { headers: headers });
  }
  return result;
};

//edit formula
export const getQueryDetails = (id) => {
  return (dispatch) => {
    getQueryDetailsAPI(id)
      .then((response) => {
        dispatch(getQueryDetailsSuccess(response.data.response));
        //history.push('/query-field/edit-query-field/' + id);
      })
      .catch((error) => {});
  };
};

const getQueryDetailsAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('sql-work-bench/' + id, { headers: headers });
  return result;
};

export const getQueryDetailsSuccess = (data) => {
  return {
    type: QUERY_DETAIL_SUCCESS,
    data,
  };
};
