import {
  HOLIDAY_LIST_SUCCESS,
  HOLIDAY_LIST_FAIL,
  FETCH_START,
  FETCH_END,
  EDIT_HOLIDAY_SUCCESS,
  EDIT_HOLIDAY_FAIL,
} from '../../actions/HolidayCalendar/ActionTypes';

const initialState = {
  loading: false,
  holidayData: null,
};

const HolidayCalendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOLIDAY_LIST_SUCCESS:
      return {
        ...state,
        holidayData: action.data,
        loading: false,
      };
    case HOLIDAY_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    case EDIT_HOLIDAY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EDIT_HOLIDAY_FAIL:
      return {
        ...state,
        loading: false,
      };
    case FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_END:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default HolidayCalendarReducer;
