export const ADD_MULTIPLE_CONFIGURATION = 'ADD_MULTIPLE_CONFIGURATION';
export const ADD_MULTIPLE_CONFIGURATION_KF = 'ADD_MULTIPLE_CONFIGURATION_KF';
export const ADD_MULTIPLE_CONFIGURATION_CR = 'ADD_MULTIPLE_CONFIGURATION_CR';
export const GET_SALES_BASIS_SUCCESS = 'GET_SALES_BASIS_SUCCESS';
export const ACCESS_DATA_SUCCESS = 'ACCESS_DATA_SUCCESS';
export const SECOND_API_CONFIG_SUCCESS = 'SECOND_API_CONFIG_SUCCESS';
export const HISTORY_SUCCESS = 'HISTORY_SUCCESS';
export const ORGANIZATION_ACCESS_SUCCESS = 'ORGANIZATION_ACCESS_SUCCESS';
export const USER_ROLE_ACCESS_SUCCESS = 'USER_ROLE_ACCESS_SUCCESS';
export const DEFAULT_VALUES_CALC_SIM = 'DEFAULT_VALUES_CALC_SIM';
export const DEFAULT_VALUES_TARG_DAT = 'DEFAULT_VALUES_TARG_DAT';
export const DEFAULT_VALUES_CONT_CHAN = 'DEFAULT_VALUES_CONT_CHAN';
export const DEFAULT_VALUES_FINAN_SUM = 'DEFAULT_VALUES_FINAN_SUM';
export const DEFAULT_VALUES_FINANCIAL = 'DEFAULT_VALUES_FINANCIAL';
export const APPROVAL_GROUP_SUCCESS = 'APPROVAL_GROUP_SUCCESS';
export const FAQ_SUCCESS = 'FAQ_SUCCESS';
export const COLLABORATION_PORTAL_SUCCESS = 'COLLABORATION_PORTAL_SUCCESS';
export const FINACIAL_POSTING_APPROVAL_DATA_COMPLETE_SUCCESS =
  'FINACIAL_POSTING_APPROVAL_DATA_COMPLETE_SUCCESS';
export const GET_FINANCIAL_APPROVAL_SUCCESS = 'GET_FINANCIAL_APPROVAL_SUCCESS';
export const MCF_INTEGRATION_SUCCESS = 'MCF_INTEGRATION_SUCCESS';
export const GET_CUSTOMER_PAYMENT_SUCCESS = 'GET_CUSTOMER_PAYMENT_SUCCESS';
export const GET_INTEGRATION_RECONCILATION_SUCCESS =
  'GET_INTEGRATION_RECONCILATION_SUCCESS';
export const GET_CALCULATION_SUMMARY_SUCCESS =
  'GET_CALCULATION_SUMMARY_SUCCESS';
export const DELETE_CALCULATION_SIMULATION_DATA_SUCCESS =
  'DELETE_CALCULATION_SIMULATION_DATA_SUCCESS';
export const GET_ACC_VS_PAY_SUCCESS = 'GET_ACC_VS_PAY_SUCCESS';
export const GET_EXTERNAL_ACCURAL_DATA_SUCCESS =
  'GET_EXTERNAL_ACCURAL_DATA_SUCCESS';
export const GET_EXTERNAL_PAYMENT_DATA_SUCCESS =
  'GET_EXTERNAL_PAYMENT_DATA_SUCCESS';
export const GET_COMPARE_REBATE_CALCULATION_DATA_SUCCESS =
  'GET_COMPARE_REBATE_CALCULATION_DATA_SUCCESS';
export const FETCH_START = 'FETCH_START';
export const FETCH_END = 'FETCH_END';
export const FORM_FIELDS_ALL_DATA = 'FORM_FIELDS_ALL_DATA';
export const FORM_FIELDS_ALL_DATA_ER = 'FORM_FIELDS_ALL_DATA_ER';
export const TARGET_SOURCE_ATTRIBUTE_ALL_DATA =
  'TARGET_SOURCE_ATTRIBUTE_ALL_DATA';
export const TARGET_SOURCE_ATTRIBUTE_ALL_DATA_SA =
  'TARGET_SOURCE_ATTRIBUTE_ALL_DATA_SA';
export const FORM_FIELDS_ALL_DATA_KF = 'FORM_FIELDS_ALL_DATA_KF';
export const FORM_FIELDS_ALL_DATA_NEW = 'FORM_FIELDS_ALL_DATA_NEW';
export const ADD_MULTIPLE_CONFIGURATION_AV = 'ADD_MULTIPLE_CONFIGURATION_AV';
export const LOAD_FIELDVALUES_ON_APP_TYPE = 'LOAD_FIELDVALUES_ON_APP_TYPE';
export const USER_ROLE_SINGLE = 'USER_ROLE_SINGLE';
export const ATTRIBUTE_NAME = 'ATTRIBUTE_NAME';
export const FETCH_START_ORG = 'FETCH_START_ORG';
export const FETCH_END_ORG = 'FETCH_END_ORG';
export const GET_USER_EMAIL_LINK = 'GET_USER_EMAIL_LINK';
export const WORKFLOW_REQUEST_ACCRUAL = 'WORKFLOW_REQUEST_ACCRUAL';
export const WORKFLOW_REQUEST_PAYMENT = 'WORKFLOW_REQUEST_PAYMENT';
export const ACCESS_DATA_NEW_SUCCESS = 'ACCESS_DATA_NEW_SUCCESS';
export const ORG_APP_TITLE_SUCCESS = 'ORG_APP_TITLE_SUCCESS';
export const KEY_FIGURE_NAME = 'KEY_FIGURE_NAME';
export const CONTRACT_TRENDS = 'CONTRACT_TRENDS';
export const CONTRACT_TRENDS_MULTIPLE = 'CONTRACT_TRENDS_MULTIPLE';
export const CALCULATION_SIMULATION_NUMBERS = 'CALULATION_SIMULATION_NUMBERS';
export const QUOTA_LEVEL = 'QUOTA_LEVEL';
export const ORG_DEFAULTS = 'ORG_DEFAULTS';
export const WORKFLOW_REQUEST_PROMOTION = 'WORKFLOW_REQUEST_PROMOTION';
export const CLAIM_ERROR_COUNT = 'CLAIM_ERROR_COUNT';
export const APP_RIGHTS = 'APP_RIGHTS';
export const UPLOAD_MASS_FILE = 'UPLOAD_MASS_FILE';
export const TIER_EVALUATION_LEVEL = 'TIER_EVALUATION_LEVEL';
export const TABLES_NAMES_SUCCESS = 'TABLES_NAMES_SUCCESS';
export const DEAFULT_VALUES_LBL_DISP = 'DEAFULT_VALUES_LBL_DISP';
export const CONTRACT_TRENDS_V2 = 'CONTRACT_TRENDS_V2';
export const CONTRACT_DISPLAY = 'CONTRACT_DISPLAY';
export const COLLABORATED_DATA = 'COLLABORATED_DATA';
export const DELETE_MASTER_DATA_SUCCESS = 'DELETE_MASTER_DATA_SUCCESS';
export const DELETE_MASTER_DATA_FIELD_SUCCESS =
  'DELETE_MASTER_DATA_FIELD_SUCCESS';
export const CONFIGURATION_DESIGNER_SUCCESS = 'CONFIGURATION_DESIGNER_SUCCESS';
export const REPORT_TYPE_FILTER_SUCCESS = 'REPORT_TYPE_FILTER_SUCCESS';
export const REPORT_TYPE_DATA_ALL = 'REPORT_TYPE_DATA_ALL';
export const REPORT_TYPE_DATA_SINGLE = 'REPORT_TYPE_DATA_SINGLE';
export const REPORT_ON_ROLL_ALL = 'REPORT_ON_ROLL_ALL';
export const GET_CONTRACT_ATTRIBUTES_SUCCESS =
  'GET_CONTRACT_ATTRIBUTES_SUCCESS';
export const CONTRACT_ALL_ATTRIBUTES = 'CONTRACT_ALL_ATTRIBUTES';
export const GET_DAILY_SIMULATIONS_REPORT_SUCCESS =
  'GET_DAILY_SIMULATIONS_REPORT_SUCCESS';
export const POSTINGS_DATA = 'POSTINGS_DATA';
export const WORKFLOW_MAINTENANCE_DATA = 'WORKFLOW_MAINTENANCE_DATA';
export const MANDATORY_ELIGIBILITY_RULES_SUCCESS =
  'MANDATORY_ELIGIBILITY_RULES_SUCCESS';
export const GET_STANDARD_COMPLIANCE_SUCCESS =
  'GET_STANDARD_COMPLIANCE_SUCCESS';
export const MESSAGE_MAINTENANCE_ALL_DATA = 'MESSAGE_MAINTENANCE_ALL_DATA';
export const MESSAGE_MAINTENANCE_DATA_SINGLE =
  'MESSAGE_MAINTENANCE_DATA_SINGLE';
export const MESSAGE_MAINTENANCE_ID_DATA = 'MESSAGE_MAINTENANCE_ID_DATA';
