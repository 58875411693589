import {
  PERFORMANCE_METRIC_SUCCESS,
  PERFORMANCE_METRIC_SINGLE_SUCCESS,
  PERFORMANCE_METRIC_FORMAT_SUCCESS,
  PERFORMANCE_METRIC_FORMAT_BY_ID_SUCCESS,
  PERFORMANCE_METRIC_FORMAT_FIELDS_SUCCESS,
  PERFORMANCE_WEIGHT_SUCCESS,
  PERFORMANCE_WEIGHT_SINGLE_SUCCESS,
  PERFORMANCE_WEIGHT_FORMAT_SUCCESS,
  PERFORMANCE_WEIGHT_FORMAT_BY_ID_SUCCESS,
  PERFORMANCE_WEIGHT_FORMAT_FIELDS_SUCCESS,
  FETCH_START,
  FETCH_END,
} from './ActionTypes';
import axios from '../../../axios.conf';
import { toast } from 'react-toastify';
import { ServerError } from '../../../library/constants';
import { history } from '../../../components/Helpers';
import secureLocalStorage from 'react-secure-storage';

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};
export const getPerformanceMetricData = (pagination, limit, keyword) => {
  return (dispatch) => {
    getPerformanceMetricDataApi(pagination, limit, keyword).then((response) => {
      dispatch(getPerformanceMetricDataSuccess(response.data.response));
    });
  };
};

const getPerformanceMetricDataApi = (pagination, limit, keyword) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('performance-metric', { headers: headers });
  return result;
};

export const getPerformanceMetricDataSuccess = (data) => {
  return {
    type: PERFORMANCE_METRIC_SUCCESS,
    data,
  };
};

export const createPerformanceMetric = (data, type, id) => {
  return (dispatch) => {
    createPerformanceMetricAPI(data, type, id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getPerformanceMetricData(1, 10));

        history.push('/performance-metric');
      })
      .catch((error) => {});
  };
};

const createPerformanceMetricAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'delete') {
    result = axios.delete('performance-metric/' + id, { headers: headers });
  } else if (type === 'edit') {
    result = axios.put('performance-metric/' + id, data, { headers: headers });
  } else {
    result = axios.post('performance-metric', data, { headers: headers });
  }
  return result;
};

export const getPerformanceMetricSingleItem = (id) => {
  return (dispatch) => {
    getPerformanceMetricSingleItemAPI(id).then((response) => {
      dispatch(getPerformanceMetricSingleItemSuccess(response.data.response));
    });
  };
};
const getPerformanceMetricSingleItemAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('performance-metric/' + id, { headers: headers });
  return result;
};

export const getPerformanceMetricSingleItemSuccess = (data) => {
  return {
    type: PERFORMANCE_METRIC_SINGLE_SUCCESS,
    data,
  };
};

//format

export const getPerformanceMetricFormatData = () => {
  return (dispatch) => {
    getPerformanceMetricFormatDataApi()
      .then((response) => {
        dispatch(getPerformanceMetricFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getPerformanceMetricFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=PerformanceMetricFormat',
    { headers: headers },
  );
  return result;
};
export const getPerformanceMetricFormatDataSuccess = (data) => {
  return {
    type: PERFORMANCE_METRIC_FORMAT_SUCCESS,
    data,
  };
};
export const getPerformanceMetricFormatDataById = (id) => {
  return (dispatch) => {
    getPerformanceMetricFormatDataByIdApi(id)
      .then((response) => {
        dispatch(
          getPerformanceMetricFormatDataByIdSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getPerformanceMetricFormatDataByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('performance-metric-format/' + id, { headers: headers });
  return result;
};
export const getPerformanceMetricFormatDataByIdSuccess = (data) => {
  return {
    type: PERFORMANCE_METRIC_FORMAT_BY_ID_SUCCESS,
    data,
  };
};
export const getPerformanceMetricFormatFields = () => {
  return (dispatch) => {
    getPerformanceMetricFormatFieldsApi()
      .then((response) => {
        dispatch(
          getPerformanceMetricFormatFieldsSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getPerformanceMetricFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=PerformanceMetricFormat',
    { headers: headers },
  );
  return result;
};
export const getPerformanceMetricFormatFieldsSuccess = (data) => {
  return {
    type: PERFORMANCE_METRIC_FORMAT_FIELDS_SUCCESS,
    data,
  };
};
export const updatePerformanceMetricFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updatePerformanceMetricFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push('/performance-metric/performance-metric-excel-upload');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};
const updatePerformanceMetricFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'upload-format/' + id + '?app-functionality=PerformanceMetricFormat',
    data,
    { headers: headers },
  );
  return result;
};
export const addPerformanceMetricFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addPerformanceMetricFormatDataApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        //dispatch(getPerformanceMetricFormatData())
        toast.success(response.data.response);
        history.push('/performance-metric/performance-metric-excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};
const addPerformanceMetricFormatDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'upload-format?app-functionality=PerformanceMetricFormat',
    data,
    { headers: headers },
  );
  return result;
};
export const deletePerformanceMetricFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deletePerformanceMetricFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success('Successfully deleted');
          dispatch(getPerformanceMetricFormatData());
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};
const deletePerformanceMetricFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};
export const uploadPerformanceMetricFile = (id, file) => {
  return (dispatch) => {
    uploadPerformanceMetricFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          //window.location.reload();
          toast.success('File has been successfully uploaded.');
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadPerformanceMetricFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('performance-metric-upload?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
//  performance Metric Weightage
export const getPerformanceMetricWeightageData = (
  pagination,
  limit,
  keyword,
) => {
  return (dispatch) => {
    getPerformanceMetricWeightageDataApi(pagination, limit, keyword).then(
      (response) => {
        dispatch(
          getPerformanceMetricWeightageDataSuccess(response.data.response),
        );
      },
    );
  };
};
const getPerformanceMetricWeightageDataApi = (pagination, limit, keyword) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('performance-weight', { headers: headers });
  return result;
};
export const getPerformanceMetricWeightageDataSuccess = (data) => {
  return {
    type: PERFORMANCE_WEIGHT_SUCCESS,
    data,
  };
};
export const createPerformanceMetricWeightage = (data, type, id) => {
  return (dispatch) => {
    createPerformanceMetricWeightageAPI(data, type, id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getPerformanceMetricWeightageData(1, 10));

        history.push('/performance-metric-weightage');
      })
      .catch((error) => {});
  };
};
const createPerformanceMetricWeightageAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'delete') {
    result = axios.delete('performance-weight/' + id, { headers: headers });
  } else if (type === 'edit') {
    result = axios.put('performance-weight/' + id, data, { headers: headers });
  } else {
    result = axios.post('performance-weight', data, { headers: headers });
  }
  return result;
};
export const getPerformanceMetricWeightageSingleItem = (id) => {
  return (dispatch) => {
    getPerformanceMetricWeightageSingleItemAPI(id).then((response) => {
      dispatch(
        getPerformanceMetricWeightageSingleItemSuccess(response.data.response),
      );
    });
  };
};
const getPerformanceMetricWeightageSingleItemAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('performance-weight/' + id, { headers: headers });
  return result;
};
export const getPerformanceMetricWeightageSingleItemSuccess = (data) => {
  return {
    type: PERFORMANCE_WEIGHT_SINGLE_SUCCESS,
    data,
  };
};
//format
export const getPerformanceMetricWeightageFormatData = () => {
  return (dispatch) => {
    getPerformanceMetricWeightageFormatDataApi()
      .then((response) => {
        dispatch(
          getPerformanceMetricWeightageFormatDataSuccess(
            response.data.response,
          ),
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getPerformanceMetricWeightageFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=PerformanceMetricWeightageFormat',
    { headers: headers },
  );
  return result;
};
export const getPerformanceMetricWeightageFormatDataSuccess = (data) => {
  return {
    type: PERFORMANCE_WEIGHT_FORMAT_SUCCESS,
    data,
  };
};
export const getPerformanceMetricWeightageFormatDataById = (id) => {
  return (dispatch) => {
    getPerformanceMetricWeightageFormatDataByIdApi(id)
      .then((response) => {
        dispatch(
          getPerformanceMetricWeightageFormatDataByIdSuccess(
            response.data.response,
          ),
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getPerformanceMetricWeightageFormatDataByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('performance-weight-format/' + id, { headers: headers });
  return result;
};
export const getPerformanceMetricWeightageFormatDataByIdSuccess = (data) => {
  return {
    type: PERFORMANCE_WEIGHT_FORMAT_BY_ID_SUCCESS,
    data,
  };
};
export const getPerformanceMetricWeightageFormatFields = () => {
  return (dispatch) => {
    getPerformanceMetricWeightageFormatFieldsApi()
      .then((response) => {
        dispatch(
          getPerformanceMetricWeightageFormatFieldsSuccess(
            response.data.response,
          ),
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getPerformanceMetricWeightageFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=PerformanceMetricWeightageFormat',
    { headers: headers },
  );
  return result;
};
export const getPerformanceMetricWeightageFormatFieldsSuccess = (data) => {
  return {
    type: PERFORMANCE_WEIGHT_FORMAT_FIELDS_SUCCESS,
    data,
  };
};
export const updatePerformanceMetricWeightageFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updatePerformanceMetricWeightageFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push(
            '/performance-metric-weightage/performance-metric-weightage-excel-upload',
          );
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};
const updatePerformanceMetricWeightageFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'upload-format/' +
      id +
      '?app-functionality=PerformanceMetricWeightageFormat',
    data,
    { headers: headers },
  );
  return result;
};
export const addPerformanceMetricWeightageFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addPerformanceMetricWeightageFormatDataApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        //dispatch(getPerformanceMetricWeightageFormatData())
        toast.success(response.data.response);
        history.push(
          '/performance-metric-weightage/performance-metric-weightage-excel-upload',
        );
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};
const addPerformanceMetricWeightageFormatDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'upload-format?app-functionality=PerformanceMetricWeightageFormat',
    data,
    { headers: headers },
  );
  return result;
};
export const deletePerformanceMetricWeightageFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deletePerformanceMetricWeightageFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success('Successfully deleted');
          dispatch(getPerformanceMetricWeightageFormatData());
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};
const deletePerformanceMetricWeightageFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};
//upload File
export const uploadPerformanceMetricWeightageFile = (id, file) => {
  return (dispatch) => {
    uploadPerformanceMetricWeightageFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          //window.location.reload();
          toast.success('File has been successfully uploaded.');
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadPerformanceMetricWeightageFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('performance-weight-upload?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
