export const EXTERNAL_FORMULA_SUCCESS = 'EXTERNAL_FORMULA_SUCCESS';
export const EXTERNAL_FORMULA_VIEW_DETAIL_SUCCESS =
  'EXTERNAL_FORMULA_VIEW_DETAIL_SUCCESS';
export const EXTERNAL_FORMULA_SINGLE_SUCCESS =
  ' EXTERNAL_FORMULA_SINGLE_SUCCESS';
export const EXTERNAL_FORMULA_FORMAT_SUCCESS =
  'EXTERNAL_FORMULA_FORMAT_SUCCESS';
export const EXTERNAL_FORMULA_FORMAT_BY_ID_SUCCESS =
  'EXTERNAL_FORMULA_FORMAT_BY_ID_SUCCESS';
export const EXTERNAL_FORMULA_FORMAT_FIELDS_SUCCESS =
  'EXTERNAL_FORMULA_FORMAT_FIELDS_SUCCESS';
export const FETCH_START = 'FETCH_START';
export const FETCH_END = 'FETCH_END';
