import {
  DOCUMENT_HISTORY,
  DOCUMENT_DATA_BY_ID,
  DOCUMENT_DATA,
  DOCUMENT_LEVEL_DATA,
  DOCUMENT_LEVEL_BY_ID,
  DISTINCT_DOCUMENT_LEVEL_DATA,
  DOCUMENT_STATUS,
  FETCH_START,
  FETCH_END,
  SEARCH_DOCUMENT_SUCESS,
} from '../../actions/DocumentManagement/ActionTypes';

const initialState = {
  loading: false,
  documentData: [],
  documentSingleData: [],
  documentSearchData: [],
  documentLevels: {},
  documentLevelById: {},
  distinctDocumentLevel: [],
  documentStatus: {},
  documentHistory: [],
};

const DocumentManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_END:
      return {
        ...state,
        loading: false,
      };
    case DOCUMENT_DATA:
      return {
        ...state,
        documentData: action.data,
      };
    case DOCUMENT_DATA_BY_ID:
      return {
        ...state,
        documentSingleData: action.data,
      };
    case SEARCH_DOCUMENT_SUCESS:
      return {
        ...state,
        documentSearchData: action.data,
      };
    case DOCUMENT_LEVEL_DATA:
      return {
        ...state,
        documentLevels: action.data,
      };
    case DOCUMENT_LEVEL_BY_ID:
      return {
        ...state,
        documentLevelById: action.data,
      };
    case DISTINCT_DOCUMENT_LEVEL_DATA:
      return {
        ...state,
        distinctDocumentLevel: action.data,
      };
    case DOCUMENT_STATUS:
      return {
        ...state,
        documentStatus: action.data,
      };
    case DOCUMENT_HISTORY:
      return {
        ...state,
        documentHistory: action.data,
      };
    default:
      return state;
  }
};

export default DocumentManagementReducer;
