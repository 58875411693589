import {
  PRODUCT_FEATURE_SUCCESS,
  PRODUCT_FEATURE_SINGLE_SUCCESS,
  FETCH_START,
  FETCH_END,
} from '../../actions/ProductFeature/ActionTypes';

const initialState = {
  loading: false,
  productFeatureData: [],
  productFeatureSingleData: [],
};

const ProductFeatureReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_END:
      return {
        ...state,
        loading: false,
      };
    case PRODUCT_FEATURE_SUCCESS:
      return {
        ...state,
        productFeatureData: action.data,
      };
    case PRODUCT_FEATURE_SINGLE_SUCCESS:
      return {
        ...state,
        productFeatureSingleData: action.data,
      };
    default:
      return state;
  }
};

export default ProductFeatureReducer;
