import {
  FETCH_START,
  FETCH_END,
  PROMOTION_DATA_SUCCESS,
  PROMO_FINANCE_COST_DATA_SUCCESS,
  PROMO_TERRITORY_DATA_SUCCESS,
  PROMO_TERRITORY_FORMAT_SUCCESS,
  PROMO_TERRITORY_FORMAT_BY_ID_SUCCESS,
  PROMO_TERRITORY_FORMAT_FIELDS_SUCCESS,
  PROMO_TERRITORY_UPLOAD_SUCCESS,
  PROMOTION_SETUP_BY_ID,
  PROMOTION_SETUP_SUCCESS,
  PROMOTION_MATERIAL_SUCCESS,
  PROMO_FINANCE_COST_DETAIL_SUCCESS,
  FINANCE_COST_FORMAT_SUCCESS,
  FINANCE_COST_FORMAT_BY_ID_SUCCESS,
  FINANCE_COST_FORMAT_FIELDS_SUCCESS,
  PROMO_FINANCE_COST_UPLOAD_SUCCESS,
  COUNTRY_SUCCESS,
  TERRITORY_ID_SUCCESS,
  POSTING_FAILED,
  GLOBAL_SETUP_BY_ID,
  GLOBAL_SETUP_SUCCESS,
  PROMO_MAP_SUCCESS,
  PROMO_MAP_SINGLE_SUCCESS,
  PROMO_MAPPING_FORMAT_SUCCESS,
  PROMO_MAP_FORMAT_BY_ID,
  PROMO_MAP_FORMAT_FIELDS,
  PROMO_SIM_FAILURE,
  PROMO_TYPES,
  PROMO_STICKERS,
  PROMO_STICKER_BY_ID,
  MARKET_FILTERS,
  PROMO_FILTERS,
  MODEL_GROUP,
  LOCATION_GROUP,
  MODEL_LIST_GROUP,
  PROMO_CONFIG_UPLOAD_SUCCESS,
  PROMO_CONFIG_FORMAT_FIELDS_SUCCESS,
  PROMO_CONFIG_FORMAT_SUCCESS,
  PROMO_CONFIG_DATA_SUCCESS,
  MATERIAL_PRICING_UPLOAD_SUCCESS,
  MATERIAL_PRICING_FORMAT_FIELDS_SUCCESS,
  MATERIAL_PRICING_FORMAT_SUCCESS,
  MATERIAL_PRICING_DATA_SUCCESS,
  MATERIAL_PRICING_SINGLE_DATA_SUCCESS,
  PROMO_CONFIG_SINGLE_DATA_SUCCESS,
  SCENARIO_LIST_DATA_SUCCESS,
  HISTORY_DATA_SUCCESS,
  SAND_BOX_DATA_SUCCESS,
  PROMOTIONS_SEARCH_SUCCESS,
  COSTING_SIMULATION_SUCCESS,
  COSTING_START,
  COSTING_END,
  PREFILL_SCENARIO_SUCCESS,
  SCENARIO_BY_ID,
  COVERAGE_MAPPING_UPLOAD_SUCCESS,
  COVERAGE_MAPPING_FORMAT_FIELDS_SUCCESS,
  COVERAGE_MAPPING_FORMAT_SUCCESS,
  COVERAGE_MAPPING_DATA_SUCCESS,
  COVERAGE_MAPPING_SINGLE_SUCCESS,
  STANDARD_FINANCE_UPLOAD_SUCCESS,
  STANDARD_FINANCE_FORMAT_FIELDS_SUCCESS,
  STANDARD_FINANCE_FORMAT_SUCCESS,
  STANDARD_FINANCE_DATA_SUCCESS,
  STANDARD_FINANCE_SINGLE_DATA_SUCCESS,
  PROMOTION_CURRENCY_UPLOAD_SUCCESS,
  PROMOTION_CURRENCY_FORMAT_FIELDS_SUCCESS,
  PROMOTION_CURRENCY_FORMAT_SUCCESS,
  PROMOTION_CURRENCY_DATA_SUCCESS,
  PROMOTION_CURRENCY_SINGLE_SUCCESS,
  SAVED_SCENARIO,
  SALES_FORECAST_SINGLE_DATA_SUCCESS,
  SCENARIO_MARKETS,
  PREVIOUS_SCENARIO,
  REVIEW_SCENARIO,
  CURRENT_SCENARIO,
  MARKET_END,
  MARKET_START,
  CUSTOM_GROUP_ID,
  CUSTOM_GROUPS,
  CUSTOM_GROUP_LIST,
  TR_APPROVAL_DATA_SUCCESS,
  TR_APPROVAL_REVIEW_DATA_SUCCESS,
  TR_APPROVAL_REVIEW_COMPLETED_DATA_SUCCESS,
  GROUPED_CUSTOM_GROUP,
  PROMOTION_COSTING_COMPARISON_UPLOAD_SUCCESS,
  PROMO_COSTING_DROPDOWN_DATA_SUCCESS,
  ARCHIVE,
  STICKER_ARCHIVE,
  PROMO_COSTING_TABLE_DATA_SUCCESS,
  LOCATION_FILTER,
  SCENARIO_DATA,
  GRID_DATA,
  USER_ACCESS_DATA,
  CURRENT_MODEL_YEAR,
  CLEAR_DATA,
  PROMO_FINANCE_COSTING_DIALOG_DATA_SUCCESS,
  PROMO_PROMOTIONAL_OFFERS_DATA_SUCCESS,
  PROMO_REGULAR_OFFERS_DATA_SUCCESS,
  PROMO_REGULAR_OFFERS_UPLOAD_SUCCESS,
  PROMO_REGULAR_OFFERS_FORMAT_SUCCESS,
  PROMO_REGULAR_OFFERS_FIELDS_SUCCESS,
  PROMO_REGULAR_FINANCING_DATA_SUCCESS,
  DOWNLOADING,
  VALID_MARKETS,
} from '../../actions/Promotion/ActionTypes';
import { downloading } from '../../actions/Promotion/AuthAction';

const initialState = {
  loading: false,
  promotionData: [],
  promoFinanceCostData: [],
  promoTerritoryData: [],
  promoTerritorySingleFormatData: [],
  promoTerritoryFormatFieldData: [],
  promoTerritoryFormatData: [],
  promoTerritory: [],
  promoTerritoryErrorList: [],
  promotionSetupData: [],
  promotionSetupSingleData: [],
  promotionMaterialData: [],
  promoFinanceCostDetailsData: [],
  promoFinanceCostSingleFormatData: [],
  promoFinanceCostFormatFieldData: [],
  promoFinanceCostFormatData: [],
  promoFinanceCost: [],
  promoFinanceCostErrorList: [],
  countryData: [],
  territoryIdData: [],
  postingFailedList: [],
  globalSetupData: [],
  globalSetupList: [],
  promoMapData: [],
  promoMapSingleData: [],
  promoMapFormatData: [],
  promoMapFormatFields: [],
  promoMapFormatSingleData: [],
  promoSimulationFailure: [],
  promoTypes: {},
  promoStickers: [],
  stickerData: {},
  marketFilters: [],
  promoFilters: [],
  modelFilters: [],
  locationFilters: [],
  modelList: [],
  promoConfigErrorList: [],
  promoConfigField: [],
  promoConfigFormatData: [],
  promoConfigData: [],
  promoConfigDataSingle: [],
  materialPricingErrorList: [],
  materialPricingField: [],
  materialPricingFormatData: [],
  materialPricingData: [],
  materialPricingDataSingle: [],
  promotionsSearchData: [],
  costingSimulationResult: [],
  oncostingLoad: false,
  prefillScenarioList: [],
  //scenarioPayload: [],
  scenarioData: [],
  scenarioListData: [],
  sandBoxListData: [],
  historyListData: [],
  coverageMappingFormatData: [],
  coverageMappingErrorList: [],
  coverageMappingField: [],
  coverageMappingListData: [],
  coverageMappingDataSingle: [],
  standardFinanceFormatData: [],
  standardFinanceErrorList: [],
  standardFinanceField: [],
  standardFinanceListData: [],
  standardFinanceDataSingle: [],
  promotionCurrencyFormatData: [],
  promotionCurrencyErrorList: [],
  promotionCurrencyField: [],
  promotionCurrencyListData: [],
  promotionCurrencyDataSingle: [],
  savedScenario: {},
  salesForecastDataSingle: [],
  scenarioMarkets: [],
  previousScenario: [],
  reviewScenario: [],
  currentScenarioList: [],
  trApprovalReviewData: [],
  trApprovalData: [],
  trApprovalReviewCompletedData: [],
  marketloading: false,
  marketcompare: false,
  customGroups: [],
  customGroupById: {},
  customGroupList: [],
  groupedCustom: {},
  costingComparisonDownload: [],
  costingDropdownData: [],
  archiveList: [],
  stickerArchiveList: [],
  costingTableData: [],
  filteredLocation: [],
  gridData: {},
  userAccessData: [],
  gridLoader: false,
  Scenarioloader: false,
  currentModelYear: [],
  financeCostingDialog: [],
  promotionalOffers: [],
  regularOffers: [],
  regularOffersFormatData: [],
  regularOffersErrorList: [],
  regularOffersFormatFieldData: [],
  regularFinancingData: [],
  downloading: false,
  validMarkets: {},
};

const PromotionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_END:
      return {
        ...state,
        loading: false,
      };
    case PROMOTION_DATA_SUCCESS:
      return {
        ...state,
        promotionData: action.data,
      };
    case PROMO_FINANCE_COST_DATA_SUCCESS:
      return {
        ...state,
        promoFinanceCostData: action.data,
      };
    case PROMO_TERRITORY_DATA_SUCCESS:
      return {
        ...state,
        promoTerritoryData: action.data,
      };
    case PROMO_TERRITORY_FORMAT_SUCCESS:
      return {
        ...state,
        promoTerritoryFormatData: action.data,
      };
    case PROMO_TERRITORY_FORMAT_FIELDS_SUCCESS:
      return {
        ...state,
        promoTerritoryFormatFieldData: action.data,
      };
    case PROMO_TERRITORY_UPLOAD_SUCCESS:
      return {
        ...state,
        promoTerritoryErrorList: action.data,
      };
    case PROMO_TERRITORY_FORMAT_BY_ID_SUCCESS:
      return {
        ...state,
        promoTerritorySingleFormatData: action.data,
      };
    case PROMOTION_SETUP_BY_ID:
      return {
        ...state,
        promotionSetupSingleData: action.data,
      };

    case PROMOTION_SETUP_SUCCESS:
      return {
        ...state,
        promotionSetupData: action.data,
      };
    case PROMOTION_MATERIAL_SUCCESS:
      return {
        ...state,
        promotionMaterialData: action.data,
      };
    case PROMO_FINANCE_COST_DETAIL_SUCCESS:
      return {
        ...state,
        promoFinanceCostDetailsData: action.data,
      };
    case FINANCE_COST_FORMAT_SUCCESS:
      return {
        ...state,
        promoFinanceCostFormatData: action.data,
      };
    case FINANCE_COST_FORMAT_FIELDS_SUCCESS:
      return {
        ...state,
        promoFinanceCostFormatFieldData: action.data,
      };
    case FINANCE_COST_FORMAT_BY_ID_SUCCESS:
      return {
        ...state,
        promoFinanceCostSingleFormatData: action.data,
      };
    case PROMO_FINANCE_COST_UPLOAD_SUCCESS:
      return {
        ...state,
        promoFinanceCostErrorList: action.data,
      };
    case COUNTRY_SUCCESS:
      return {
        ...state,
        countryData: action.data,
      };
    case TERRITORY_ID_SUCCESS:
      return {
        ...state,
        territoryIdData: action.data,
      };
    case POSTING_FAILED:
      return {
        ...state,
        postingFailedList: action.data,
      };
    case GLOBAL_SETUP_SUCCESS:
      return {
        ...state,
        globalSetupData: action.data,
      };
    case GLOBAL_SETUP_BY_ID:
      return {
        ...state,
        globalSetupList: action.data,
      };
    case PROMO_MAP_SUCCESS:
      return {
        ...state,
        promoMapData: action.data,
      };
    case PROMO_MAP_SINGLE_SUCCESS:
      return {
        ...state,
        promoMapSingleData: action.data,
      };
    case PROMO_MAPPING_FORMAT_SUCCESS:
      return {
        ...state,
        promoMapFormatData: action.data,
      };
    case PROMO_MAP_FORMAT_BY_ID:
      return {
        ...state,
        promoMapFormatSingleData: action.data,
      };
    case PROMO_MAP_FORMAT_FIELDS:
      return {
        ...state,
        promoMapFormatFields: action.data,
      };
    case PROMO_SIM_FAILURE:
      return {
        ...state,
        promoSimulationFailure: action.data,
      };
    case PROMO_TYPES:
      return {
        ...state,
        promoTypes: action.data,
      };
    case PROMO_STICKERS:
      return {
        ...state,
        promoStickers: action.data,
      };
    case PROMO_STICKER_BY_ID:
      return {
        ...state,
        stickerData: action.data,
      };
    case STICKER_ARCHIVE:
      return {
        ...state,
        stickerArchiveList: action.data,
      };
    case PROMO_FILTERS:
      return {
        ...state,
        promoFilters: action.data,
      };
    case MARKET_FILTERS:
      return {
        ...state,
        marketFilters: action.data,
      };
    case MODEL_GROUP:
      return {
        ...state,
        modelFilters: action.data,
      };
    case LOCATION_GROUP:
      return {
        ...state,
        locationFilters: action.data,
      };
    case MODEL_LIST_GROUP:
      return {
        ...state,
        modelList: action.data,
      };
    case PROMO_CONFIG_UPLOAD_SUCCESS:
      return {
        ...state,
        promoConfigErrorList: action.data,
      };
    case PROMO_CONFIG_FORMAT_FIELDS_SUCCESS:
      return {
        ...state,
        promoConfigField: action.data,
      };
    case PROMO_CONFIG_FORMAT_SUCCESS:
      return {
        ...state,
        promoConfigFormatData: action.data,
      };
    case PROMO_CONFIG_DATA_SUCCESS:
      return {
        ...state,
        promoConfigData: action.data,
      };
    case PROMO_CONFIG_SINGLE_DATA_SUCCESS:
      return {
        ...state,
        promoConfigDataSingle: action.data,
      };
    case MATERIAL_PRICING_UPLOAD_SUCCESS:
      return {
        ...state,
        materialPricingErrorList: action.data,
      };
    case MATERIAL_PRICING_FORMAT_FIELDS_SUCCESS:
      return {
        ...state,
        materialPricingField: action.data,
      };
    case MATERIAL_PRICING_FORMAT_SUCCESS:
      return {
        ...state,
        materialPricingFormatData: action.data,
      };
    case MATERIAL_PRICING_DATA_SUCCESS:
      return {
        ...state,
        materialPricingData: action.data,
      };
    case MATERIAL_PRICING_SINGLE_DATA_SUCCESS:
      return {
        ...state,
        materialPricingDataSingle: action.data,
      };
    case PROMOTIONS_SEARCH_SUCCESS:
      return {
        ...state,
        promotionsSearchData: action.data,
      };
    case COSTING_SIMULATION_SUCCESS:
      return {
        ...state,
        costingSimulationResult: action.data,
      };
    case COSTING_START:
      return {
        ...state,
        oncostingLoad: true,
      };
    case COSTING_END:
      return {
        ...state,
        oncostingLoad: false,
      };
    case PREFILL_SCENARIO_SUCCESS:
      return {
        ...state,
        prefillScenarioList: action.data,
        // scenarioPayload: action.payload
      };
    case SCENARIO_BY_ID:
      console.log(action.data);
      return {
        ...state,
        scenarioData: action.data,
      };
    case SCENARIO_LIST_DATA_SUCCESS:
      return {
        ...state,
        scenarioListData: action.data,
      };
    case SAND_BOX_DATA_SUCCESS:
      return {
        ...state,
        sandBoxListData: action.data,
      };
    case HISTORY_DATA_SUCCESS:
      return {
        ...state,
        historyListData: action.data,
      };

    case COVERAGE_MAPPING_UPLOAD_SUCCESS:
      return {
        ...state,
        coverageMappingErrorList: action.data,
      };
    case COVERAGE_MAPPING_FORMAT_FIELDS_SUCCESS:
      return {
        ...state,
        coverageMappingField: action.data,
      };
    case COVERAGE_MAPPING_FORMAT_SUCCESS:
      return {
        ...state,
        coverageMappingFormatData: action.data,
      };
    case COVERAGE_MAPPING_DATA_SUCCESS:
      return {
        ...state,
        coverageMappingListData: action.data,
      };
    case COVERAGE_MAPPING_SINGLE_SUCCESS:
      return {
        ...state,
        coverageMappingDataSingle: action.data,
      };
    case STANDARD_FINANCE_UPLOAD_SUCCESS:
      return {
        ...state,
        standardFinanceErrorList: action.data,
      };
    case STANDARD_FINANCE_FORMAT_FIELDS_SUCCESS:
      return {
        ...state,
        standardFinanceField: action.data,
      };
    case STANDARD_FINANCE_FORMAT_SUCCESS:
      return {
        ...state,
        standardFinanceFormatData: action.data,
      };
    case STANDARD_FINANCE_DATA_SUCCESS:
      return {
        ...state,
        standardFinanceListData: action.data,
      };
    case STANDARD_FINANCE_SINGLE_DATA_SUCCESS:
      return {
        ...state,
        standardFinanceDataSingle: action.data,
      };
    case PROMOTION_CURRENCY_UPLOAD_SUCCESS:
      return {
        ...state,
        promotionCurrencyErrorList: action.data,
      };
    case PROMOTION_CURRENCY_FORMAT_FIELDS_SUCCESS:
      return {
        ...state,
        promotionCurrencyField: action.data,
      };
    case PROMOTION_CURRENCY_FORMAT_SUCCESS:
      return {
        ...state,
        promotionCurrencyFormatData: action.data,
      };
    case PROMOTION_CURRENCY_DATA_SUCCESS:
      return {
        ...state,
        promotionCurrencyListData: action.data,
      };
    case PROMOTION_CURRENCY_SINGLE_SUCCESS:
      return {
        ...state,
        promotionCurrencyDataSingle: action.data,
      };
    case SAVED_SCENARIO:
      return {
        ...state,
        savedScenario: action.data,
      };
    case CLEAR_DATA:
      return {
        ...state,
        savedScenario: null,
        gridData: null,
      };
    case SCENARIO_DATA:
      return {
        ...state,
        savedScenario: action.data,
        Scenarioloader: action.loader,
      };
    case SALES_FORECAST_SINGLE_DATA_SUCCESS:
      return {
        ...state,
        salesForecastDataSingle: action.data,
      };
    case SCENARIO_MARKETS:
      return {
        ...state,
        scenarioMarkets: action.data,
      };
    case PREVIOUS_SCENARIO:
      return {
        ...state,
        previousScenario: action.data,
      };
    case REVIEW_SCENARIO:
      return {
        ...state,
        reviewScenario: action.data,
      };
    case CURRENT_SCENARIO:
      return {
        ...state,
        currentScenario: action.data,
      };
    case TR_APPROVAL_DATA_SUCCESS:
      return {
        ...state,
        trApprovalData: action.data,
      };
    case TR_APPROVAL_REVIEW_DATA_SUCCESS:
      return {
        ...state,
        trApprovalReviewData: action.data,
      };
    case TR_APPROVAL_REVIEW_COMPLETED_DATA_SUCCESS:
      return {
        ...state,
        trApprovalReviewCompletedData: action.data,
      };
    case MARKET_START:
      return {
        ...state,
        marketloading: true,
      };
    case MARKET_END:
      return {
        ...state,
        marketloading: false,
      };
    case CUSTOM_GROUP_ID:
      return {
        ...state,
        customGroupById: action.data,
      };
    case CUSTOM_GROUPS:
      return {
        ...state,
        customGroups: action.data,
      };
    case GROUPED_CUSTOM_GROUP:
      return {
        ...state,
        groupedCustom: action.data,
      };
    case CUSTOM_GROUP_LIST:
      return {
        ...state,
        customGroupList: action.data,
      };
    case ARCHIVE:
      return {
        ...state,
        archiveList: action.data,
      };
    case PROMOTION_COSTING_COMPARISON_UPLOAD_SUCCESS:
      return {
        ...state,
        costingComparisonDownload: action.data,
      };
    case PROMO_COSTING_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        costingDropdownData: action.data,
      };
    case PROMO_COSTING_TABLE_DATA_SUCCESS:
      return {
        ...state,
        costingTableData: action.data,
      };
    case LOCATION_FILTER:
      return {
        ...state,
        filteredLocation: action.data,
      };
    case GRID_DATA:
      return {
        ...state,
        gridData: action.data,
        gridLoader: action.loader,
      };
    case USER_ACCESS_DATA:
      return {
        ...state,
        userAccessData: action.data,
      };
    case CURRENT_MODEL_YEAR:
      return {
        ...state,
        currentModelYear: action.data,
      };
    case PROMO_FINANCE_COSTING_DIALOG_DATA_SUCCESS:
      return {
        ...state,
        financeCostingDialog: action.data,
      };
    case PROMO_PROMOTIONAL_OFFERS_DATA_SUCCESS:
      return {
        ...state,
        promotionalOffers: action.data,
      };
    case PROMO_REGULAR_OFFERS_DATA_SUCCESS:
      return {
        ...state,
        regularOffers: action.data,
      };
    case PROMO_REGULAR_OFFERS_UPLOAD_SUCCESS:
      return {
        ...state,
        regularOffersErrorList: action.data,
      };
    case PROMO_REGULAR_OFFERS_FORMAT_SUCCESS:
      return {
        ...state,
        regularOffersFormatData: action.data,
      };
    case PROMO_REGULAR_OFFERS_FIELDS_SUCCESS:
      return {
        ...state,
        regularOffersFormatFieldData: action.data,
      };
    case PROMO_REGULAR_FINANCING_DATA_SUCCESS:
      return {
        ...state,
        regularFinancingData: action.data,
      };
    case DOWNLOADING:
      return {
        ...state,
        downloading: action.data,
      };
    case VALID_MARKETS:
      return {
        ...state,
        validMarkets: action.data,
      };
    default:
      return state;
  }
};

export default PromotionReducer;
