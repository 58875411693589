import {
  QUALIFIER_CONFIG_SUCCESS,
  PRICE_LIST_FIELDS,
  FETCH_START,
  FETCH_END,
  PRICE_TYPE_CONFIG_SUCCESS,
  PRICE_TYPE_CONFIG_BY_ID,
  EXTERNAl_CONFIG_SUCCESS,
  EXTERNAl_CONFIG_BY_ID,
  PRICE_LIST_MASTER_SUCCESS,
  PRICE_LIST_MASTER_BY_ID,
  PRICE_QUALIFIER_NAME_SUCCESS,
  PRICE_TYPE_NAME_SUCCESS,
  PRICE_CAL_FOR_LIST_SUCCESS,
  PRICE_CAL_FOR_LIST_BY_NAME,
  DRIVER_FORMAT_FIELDS,
  DRIVER_FORMAT_UPLOAD,
  PROFIT_FORMAT_BY_ID,
  DRIVER_DATA_UPLOAD_FAILURE,
  EXTERNAL_DATA_FORMAT_FIELDS_SUCCESS,
  ALL_EXTERNAL_DATA,
  EXTERNAL_DATA_UPLOAD_SUCCESS,
  FLEXIBLE_ANALYSIS_SIMULATION_SUCCESS,
  MARGIN_POL_EXC_SUCCESS,
  GROSS_TO_NET_SUCCESS,
  MARGIN_TREN_SUCCESS,
  MEDIAN_MARGIN_SUCCESS,
} from './ActionTypes';
import axios from '../../../axios.conf';
import { history } from '../../../components/Helpers';
import { toast } from 'react-toastify';
import { ServerError } from '../../../library/constants';
import secureLocalStorage from 'react-secure-storage';
import { runPriceCalcForSimulation } from '../Pricing/AuthAction';
export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};
export const getAttributesConfig = (pagination, limit) => {
  return (dispatch) => {
    getAttributesConfigAPI(pagination, limit)
      .then((response) => {
        dispatch(getAttributesConfigSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};
const getAttributesConfigAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'profit-attribute-config?page=' + pagination + '&limit=' + limit,
    { headers: headers },
  );
  return result;
};
export const getAttributesConfigSuccess = (data) => {
  return {
    type: QUALIFIER_CONFIG_SUCCESS,
    data,
  };
};
export const createAttributesConfig = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createAttributesConfigAPI(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
        dispatch(getAttributesConfig(1, 100));
        history.push('/attributes-config');
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const createAttributesConfigAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('profit-attribute-config/' + id, data, {
      headers: headers,
    });
  } else if (type === 'delete') {
    result = axios.delete('profit-attribute-config/' + id, {
      headers: headers,
    });
  } else {
    result = axios.post('profit-attribute-config', data, { headers: headers });
  }
  return result;
};
export const getAttributesConfigById = (id) => {
  return (dispatch) => {
    getAttributesConfigByIdAPI(id)
      .then((response) => {
        dispatch(getAttributesConfigSuccess(response.data.response));
        history.push('/attributes-config/edit-attributes-config/' + id);
      })
      .catch((error) => {
        //toast.error(ServerError)
      });
  };
};
const getAttributesConfigByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('profit-attribute-config/' + id, { headers: headers });
  return result;
};
export const getAttributesFields = () => {
  return (dispatch) => {
    getAttributesFieldsAPI()
      .then((response) => {
        dispatch(getAttributesFieldsSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};
const getAttributesFieldsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('price-list-data-fields', { headers: headers });
  return result;
};
export const getAttributesFieldsSuccess = (data) => {
  return {
    type: PRICE_LIST_FIELDS,
    data,
  };
};
export const getAnalysisLevelValues = (qualifierKey) => {
  return (dispatch) => {
    getAnalysisLevelValuesAPI(qualifierKey)
      .then((response) => {
        if (qualifierKey == 'qualifier_key')
          dispatch(getAnalysisLevelValuesSuccess(response.data.response));
        else
          dispatch(getAnalysisLevelFieldValuesSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError)
      });
  };
};
const getAnalysisLevelValuesAPI = (qualifierKey) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (qualifierKey == 'qualifier_key')
    result = axios.get('profit-attribute-config?page=1&limit=0', {
      headers: headers,
    });
  else
    result = axios.get('profit-driver-config?page=1&limit=0', {
      headers: headers,
    });
  return result;
};
export const getAnalysisLevelValuesSuccess = (data) => {
  return {
    type: PRICE_QUALIFIER_NAME_SUCCESS,
    data,
  };
};
export const getAnalysisLevelFieldValuesSuccess = (data) => {
  return {
    type: PRICE_TYPE_NAME_SUCCESS,
    data,
  };
};
export const getAnalysisLevel = (pagination, limit, id) => {
  return (dispatch) => {
    getAnalysisLevelAPI(pagination, limit, id)
      .then((response) => {
        dispatch(getAnalysisLevelSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError)
      });
  };
};
const getAnalysisLevelAPI = (pagination, limit, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id)
    result = axios.get('profit-analysis-level-config?price_list_id=' + id, {
      headers: headers,
    });
  else
    result = axios.get(
      'profit-analysis-level-config?page=' + pagination + '&limit=' + limit,
      { headers: headers },
    );
  return result;
};
export const getAnalysisLevelSuccess = (data) => {
  return {
    type: PRICE_LIST_MASTER_SUCCESS,
    data,
  };
};
export const createAnalysisLevel = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createAnalysisLevelAPI(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/analysis-level');
        dispatch(getAnalysisLevel(1, 10));
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const createAnalysisLevelAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('profit-analysis-level-config/' + id, data, {
      headers: headers,
    });
  } else if (type === 'delete') {
    result = axios.delete('profit-analysis-level-config/' + id, {
      headers: headers,
    });
  } else {
    result = axios.post('profit-analysis-level-config', data, {
      headers: headers,
    });
  }
  return result;
};
export const getAnalysisLevelById = (id) => {
  return (dispatch) => {
    getAnalysisLevelByIdAPI(id)
      .then((response) => {
        dispatch(getAnalysisLevelByIdSuccess(response.data.response));
        history.push('/analysis-level/edit-level/' + id);
      })
      .catch((error) => {
        //toast.error(ServerError)
      });
  };
};
const getAnalysisLevelByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('profit-analysis-level-config/' + id, {
    headers: headers,
  });
  return result;
};
export const getAnalysisLevelByIdSuccess = (data) => {
  return {
    type: PRICE_LIST_MASTER_BY_ID,
    data,
  };
};
export const getDrivers = (pagination, limit) => {
  return (dispatch) => {
    getDriversAPI(pagination, limit)
      .then((response) => {
        dispatch(getDriversSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};
const getDriversAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'profit-driver-config?page=' + pagination + '&limit=' + limit,
    { headers: headers },
  );
  return result;
};
export const getDriversSuccess = (data) => {
  return {
    type: PRICE_TYPE_CONFIG_SUCCESS,
    data,
  };
};
export const getDriversById = (id) => {
  return (dispatch) => {
    getDriversByIdAPI(id)
      .then((response) => {
        dispatch(getDriversByIdSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};
const getDriversByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('profit-driver-config/' + id, { headers: headers });
  return result;
};
export const getDriversByIdSuccess = (data) => {
  return {
    type: PRICE_TYPE_CONFIG_BY_ID,
    data,
  };
};
export const createDrivers = (data, id, type) => {
  return (dispatch) => {
    createDriversAPI(data, id, type)
      .then((response) => {
        history.push('/drivers');
        dispatch(getDrivers(1, 10));
        toast.success(response.data.response);
      })
      .catch((error) => {});
  };
};
const createDriversAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('profit-driver-config/' + id, data, {
      headers: headers,
    });
  } else if (type === 'delete') {
    result = axios.delete('profit-driver-config/' + id, { headers: headers });
  } else {
    result = axios.post('profit-driver-config', data, { headers: headers });
  }
  return result;
};
// External Config
export const getExternalConfig = (pagination, limit) => {
  return (dispatch) => {
    getExternalConfigAPI(pagination, limit)
      .then((response) => {
        dispatch(getExternalConfigSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};
const getExternalConfigAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'profit-external-config?page=' + pagination + '&limit=' + limit,
    { headers: headers },
  );
  return result;
};
export const getExternalConfigSuccess = (data) => {
  return {
    type: EXTERNAl_CONFIG_SUCCESS,
    data,
  };
};
export const getExternalConfigById = (id) => {
  return (dispatch) => {
    getExternalConfigByIdAPI(id)
      .then((response) => {
        dispatch(getExternalConfigByIdSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};
const getExternalConfigByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('profit-external-config/' + id, { headers: headers });
  return result;
};
export const getExternalConfigByIdSuccess = (data) => {
  return {
    type: EXTERNAl_CONFIG_BY_ID,
    data,
  };
};
export const createExternalConfig = (data, id, type) => {
  return (dispatch) => {
    createExternalConfigAPI(data, id, type)
      .then((response) => {
        history.push('/external-config');
        dispatch(getExternalConfig(1, 10));
        toast.success(response.data.response);
      })
      .catch((error) => {});
  };
};
const createExternalConfigAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('profit-external-config/' + id, data, {
      headers: headers,
    });
  } else if (type === 'delete') {
    result = axios.delete('profit-external-config/' + id, { headers: headers });
  } else {
    result = axios.post('profit-external-config', data, { headers: headers });
  }
  return result;
};
export const getAnaylsisFormula = (pagination, limit) => {
  return (dispatch) => {
    getAnaylsisFormulaAPI(pagination, limit)
      .then((response) => {
        dispatch(getAnaylsisFormulaSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};
const getAnaylsisFormulaAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'profit-optimization-formula?page=' + pagination + '&limit=' + limit,
    { headers: headers },
  );
  return result;
};
export const getAnaylsisFormulaSuccess = (data) => {
  return {
    type: PRICE_CAL_FOR_LIST_SUCCESS,
    data,
  };
};
export const createAnalysisFormula = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createAnaylsisFormulaAPI(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/analysis-formula');
        dispatch(getAnaylsisFormula(1, 10));
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const createAnaylsisFormulaAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('profit-optimization-formula', data, {
      headers: headers,
    });
  } else if (type === 'delete') {
    result = axios.delete('profit-optimization-formula?formula_name=' + id, {
      headers: headers,
    });
  } else {
    result = axios.post('profit-optimization-formula', data, {
      headers: headers,
    });
  }
  return result;
};
export const getAnalysisFormulaByName = (name) => {
  return (dispatch) => {
    getAnaylsisFormulaByNameAPI(name)
      .then((response) => {
        dispatch(getAnaylsisFormulaByNameSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError)
      });
  };
};
const getAnaylsisFormulaByNameAPI = (name) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('profit-optimization-formula?formula_name=' + name, {
    headers: headers,
  });
  return result;
};
export const getAnaylsisFormulaByNameSuccess = (data) => {
  return {
    type: PRICE_CAL_FOR_LIST_BY_NAME,
    data,
  };
};
// driver maintenance

export const getDriverFormatFields = (name) => {
  return (dispatch) => {
    getDriverFormatFieldsAPI(name)
      .then((response) => {
        dispatch(getDriverFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError)
      });
  };
};
const getDriverFormatFieldsAPI = (name) => {
  const headers = {
    Authorization: localStorage.getItem('accessToken'),
  };

  var result;
  if (name)
    result = axios.get('profit-list-fields?analysis_level_id=' + name, {
      headers: headers,
    });
  else
    result = axios.get(
      'upload-format-fields?app-functionality=ProfitOptimizationAnalysisLevelDataFormat',
      { headers: headers },
    );
  //result = axios.get('profit-list-fields', { headers: headers });//   profit-analysis-level-data-fields
  return result;
};
export const getDriverFormatFieldsSuccess = (data) => {
  return {
    type: DRIVER_FORMAT_FIELDS,
    data,
  };
};
export const getDriverFormatUpload = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getDriverFormatUploadAPI(id, file)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(DriverFormatUploadFailure(response.data.response));
        if (
          !response.data.response.error_list &&
          response.data.response.error_list == null
        ) {
          toast.success(response.data.response.success_msg);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError)
      });
  };
};
const getDriverFormatUploadAPI = (id, file) => {
  const headers = {
    Authorization: localStorage.getItem('accessToken'),
  };
  var result;
  result = axios.post(
    'profit-analysis-level-data-upload?format_id=' + id,
    file,
    { headers: headers },
  );
  return result;
};
export const DriverFormatUploadFailure = (data) => {
  return {
    type: DRIVER_DATA_UPLOAD_FAILURE,
    data,
  };
};
//format
export const getFormatData = () => {
  return (dispatch) => {
    getFormatDataApi()
      .then((response) => {
        dispatch(getDriverFormatSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getFormatDataApi = () => {
  const headers = {
    Authorization: localStorage.getItem('accessToken'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=ProfitOptimizationAnalysisLevelDataFormat',
    { headers: headers },
  );
  return result;
};
export const getDriverFormatSuccess = (data) => {
  return {
    type: DRIVER_FORMAT_UPLOAD,
    data,
  };
};

export const addFormatData = (data, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addFormatDataApi(data, id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push('/driver-maintenance/driver-maintenance-excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addFormatDataApi = (data, id) => {
  const headers = {
    Authorization: localStorage.getItem('accessToken'),
  };
  var result;
  if (id)
    result = axios.put(
      'upload-format/' +
        id +
        '?app-functionality=ProfitOptimizationAnalysisLevelDataFormat',
      data,
      { headers: headers },
    );
  else
    result = axios.post(
      'upload-format?app-functionality=ProfitOptimizationAnalysisLevelDataFormat',
      data,
      { headers: headers },
    );
  return result;
};

export const deleteFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success('Successfully deleted');
          dispatch(getFormatData());
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const deleteFormatDataApi = (id) => {
  const headers = {
    Authorization: localStorage.getItem('accessToken'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};
export const getFormatDataById = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getFormatDataByIdApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getFormatDataByIdSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getFormatDataByIdApi = (id) => {
  const headers = {
    Authorization: localStorage.getItem('accessToken'),
  };
  var result;
  result = axios.get('upload-format/' + id, { headers: headers });
  return result;
};
export const getFormatDataByIdSuccess = (data) => {
  return {
    type: PROFIT_FORMAT_BY_ID,
    data,
  };
};
// Margin Analysis
export const runMarginAnalysis = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getMarginAnalysisAPi(data)
      .then((response) => {
        dispatch(fetchEnd());
        data['calculation_simulation_number'] =
          response.data.calculation_simulation_number;
        console.log(data);
        // dispatch(runProfitCalcForSimulation(data));
        // toast.success(response.data.message);
        toast.success('Successfully Run Margin Simulation');
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getMarginAnalysisAPi = (data) => {
  const headers = {
    Authorization: localStorage.getItem('accessToken'),
  };
  var result;
  result = axios.post('profit-calc-sim', data, { headers: headers });
  return result;
};
export const runProfitCalcForSimulation = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getProfitCalcForSimulationAPi(data)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
        dispatch(flexibleAnalysisSimulationSuccess(response.data.response));
        history.push('/margin-analysis-result');
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getProfitCalcForSimulationAPi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('profit-calc-sim/price-type-values', data, {
    headers: headers,
  });
  return result;
};
export const flexibleAnalysisSimulationSuccess = (data) => {
  return {
    type: FLEXIBLE_ANALYSIS_SIMULATION_SUCCESS,
    data,
  };
};
// Margin Policy Exceptions
export const runMarginPolicyException = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getMarginPolicyExceptionAPi(data)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
        dispatch(runMarginPolicyExceptionSuccess(response.data.response));
        history.push('/margin-policy-exceptions-result');
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getMarginPolicyExceptionAPi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'profit-margin-policy-exceptions?limit=0&page=0',
    { params: data },
    {
      headers: headers,
    },
  );
  return result;
};
export const runMarginPolicyExceptionSuccess = (data) => {
  return {
    type: MARGIN_POL_EXC_SUCCESS,
    data,
  };
};
// Margin Trending
export const runMarginTrending = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getMarginTrendingAPi(data)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
        dispatch(runMarginTrendingSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getMarginTrendingAPi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('margin-variance', data, {
    headers: headers,
  });
  return result;
};
export const runMarginTrendingSuccess = (data) => {
  return {
    type: MARGIN_TREN_SUCCESS,
    data,
  };
};
// Build Analysis Data
export const runBuildAnalysis = (data, analysisLevelId) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getBuildAnalysisAPi(data, analysisLevelId)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getBuildAnalysisAPi = (data, analysisLevelId) => {
  const headers = {
    Authorization: localStorage.getItem('accessToken'),
  };
  var result;
  result = axios.post(
    'aggregate-profit-opt-external?analysis_level_id=' + analysisLevelId,
    data,
    { headers: headers },
  );
  return result;
};
export const getAllExternalData = () => {
  return (dispatch) => {
    getAllExternalDataAPI()
      .then((response) => {
        dispatch(getAllExternalDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError)
      });
  };
};
const getAllExternalDataAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=ProfitOptimizationExternalDataFormat',
    { headers: headers },
  );
  return result;
};

export const getAllExternalDataSuccess = (data) => {
  return {
    type: ALL_EXTERNAL_DATA,
    data,
  };
};
export const getExternalDataFormatFields = () => {
  return (dispatch) => {
    getExternalDataFormatFieldsAPI()
      .then((response) => {
        dispatch(getExternalDataFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};
const getExternalDataFormatFieldsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=ProfitOptimizationExternalDataFormat',
    { headers: headers },
  );
  return result;
};
export const getExternalDataFormatFieldsSuccess = (data) => {
  return {
    type: EXTERNAL_DATA_FORMAT_FIELDS_SUCCESS,
    data,
  };
};
export const createExteranlDataFormat = (data, id, type) => {
  return (dispatch) => {
    createExteranlDataFormatAPI(data, id, type)
      .then((response) => {
        if (type == 'delete') {
          dispatch(getAllExternalData());
        }

        toast.success(response.data.response);
        history.push('/external-data-excel-upload');
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const createExteranlDataFormatAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'edit')
    result = axios.put(
      'upload-format/' +
        id +
        '?app-functionality=ProfitOptimizationExternalDataFormat',
      data,
      { headers: headers },
    );
  else if (type == 'delete')
    result = axios.delete('upload-format/' + id, { headers: headers });
  else
    result = axios.post(
      'upload-format?app-functionality=ProfitOptimizationExternalDataFormat',
      data,
      { headers: headers },
    );
  return result;
};
export const externalDataUploadNew = (file, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    externalDataUploadNewAPI(file, id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadExternalDataSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const externalDataUploadNewAPI = (file, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result = axios.post('profit-opt-external-upload?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
export const uploadExternalDataSuccess = (data) => {
  return {
    type: EXTERNAL_DATA_UPLOAD_SUCCESS,
    data,
  };
};
export const getGrossToNet = (data, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getGrossToNetAPI(data, type)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getGrossToNetSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error('Error');
      });
  };
};
const getGrossToNetAPI = (data, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('/profit-gross-to-net?analysis_type=' + type, data, {
    headers: headers,
  });
  //result = axios.post("/profit-gross-to-net?display_level="+type, data, { headers: headers });
  return result;
};
export const getGrossToNetSuccess = (data) => {
  return {
    type: GROSS_TO_NET_SUCCESS,
    data,
  };
};
export const getMedianMargin = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getMedianMarginAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getMedianMarginSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error('Error');
      });
  };
};
const getMedianMarginAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('profit/median-margin', data, { headers: headers });
  return result;
};
export const getMedianMarginSuccess = (data) => {
  return {
    type: MEDIAN_MARGIN_SUCCESS,
    data,
  };
};
