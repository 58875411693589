import {
  QUERY_LIST_SUCCESS,
  QUERY_DETAIL_SUCCESS,
} from '../../../actions/QueryField/QueryField/ActionTypes';

const initialState = {
  queryListData: {},
  queryDetailsData: {},
};

const QueryFieldReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUERY_LIST_SUCCESS:
      return {
        ...state,
        queryListData: action.data,
      };
    case QUERY_DETAIL_SUCCESS:
      return {
        ...state,
        queryDetailsData: action.data,
      };
    default:
      return state;
  }
};

export default QueryFieldReducer;
