import {
  COLLABORATION_DESI_ALL,
  COLLABORATION_FILTER_SUCCESS,
  COLLABORATION_DESI_SINGLE,
  FETCH_START,
  FETCH_END,
  COLLABORATION_DESI_LIST_SUCCESS,
} from '../../actions/CollaborationDesigner/ActionTypes';
const initialState = {
  CollaborationData: [],
  CollaborationFilters: [],
  CollaborationDataSingle: [],
  loading: false,
  CollaborationDropdownData: [],
};
const CollaborationDesignerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_START:
      return {
        loading: true,
      };
    case FETCH_END:
      return {
        loading: false,
      };
    case COLLABORATION_DESI_ALL:
      return {
        ...state,
        CollaborationData: action.data,
      };
    case COLLABORATION_FILTER_SUCCESS:
      return {
        ...state,
        CollaborationFilters: action.data,
      };
    case COLLABORATION_DESI_SINGLE:
      return {
        ...state,
        CollaborationDataSingle: action.data,
      };
    case COLLABORATION_DESI_LIST_SUCCESS:
      return {
        ...state,
        CollaborationDropdownData: action.data,
        loading: false,
      };
    default:
      return state;
  }
};
export default CollaborationDesignerReducer;
