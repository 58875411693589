import {
  DEFAULT_POSTING_DATE,
  CHECK_POSTING_DATE,
  FETCH_START,
  FETCH_END,
  FISCALYEAR_DATA_SUCCESS,
  DROP_DOWN_SUCCESS,
  DROP_DOWN_FIELD_SUCCESS,
} from './ActionTypes';
import axios from '../../../axios.conf';
import { toast } from 'react-toastify';
//import { ServerError } from '../../../../library/constants';
import { history } from '../../../components/Helpers';
import secureLocalStorage from 'react-secure-storage';

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};
export const addDefaultPostingDate = (data, id, type) => {
  return (dispatch) => {
    //dispatch(fetchStart());
    addDefaultPostingDateAPI(data, id)
      .then((response) => {
        history.push('/financial-close');
        dispatch(fetchDefaultPostingDate());
        if (type == 'clear') {
          toast.success('Successfully Deleted.');
        } else if (id) {
          toast.success('Successfully Updated.');
        } else {
          toast.success('Successfully Created.');
        }
      })
      .catch((error) => {});
  };
};

const addDefaultPostingDateAPI = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id)
    result = axios.put('default-posting-date/' + id, data, {
      headers: headers,
    });
  else result = axios.post('default-posting-date', data, { headers: headers });
  return result;
};

export const fetchDefaultPostingDate = (pagination, limit) => {
  return (dispatch) => {
    fetchDefaultPostingDateAPI(pagination, limit)
      .then((response) => {
        //dispatch(getKeyFigureableDataComplete(response.data.response.total_record));
        dispatch(fetchDefaultPostingDateSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};

const fetchDefaultPostingDateAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('default-posting-date', { headers: headers });
  return result;
};

export const fetchDefaultPostingDateSuccess = (data) => {
  return {
    type: DEFAULT_POSTING_DATE,
    data,
  };
};

export const checkingPostingDate = () => {
  return (dispatch) => {
    checkingPostingDateAPI()
      .then((response) => {
        //dispatch(getKeyFigureableDataComplete(response.data.response.total_record));
        dispatch(checkingPostingDateSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};

const checkingPostingDateAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('posting-date', { headers: headers });
  return result;
};

export const checkingPostingDateSuccess = (data) => {
  return {
    type: CHECK_POSTING_DATE,
    data,
  };
};

//Fiscal year definition
export const addFiscalYearData = (data, id, type) => {
  return (dispatch) => {
    //dispatch(fetchStart());
    addFiscalYearDataAPI(data, id)
      .then((response) => {
        history.push('/fiscal-year-definition');
        dispatch(fetchFiscalYearData());
        if (type == 'clear') {
          toast.success('Successfully Deleted.');
        } else if (id) {
          toast.success('Successfully Updated.');
        } else {
          toast.success('Successfully Created.');
        }
      })
      .catch((error) => {});
  };
};

const addFiscalYearDataAPI = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id) result = axios.put('fiscal-month/' + id, data, { headers: headers });
  else result = axios.post('fiscal-month', data, { headers: headers });
  return result;
};

export const fetchFiscalYearData = (pagination, limit) => {
  return (dispatch) => {
    fetchFiscalYearDataAPI(pagination, limit)
      .then((response) => {
        dispatch(fetchFiscalYearDataSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};

const fetchFiscalYearDataAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('fiscal-month', { headers: headers });
  return result;
};

export const fetchFiscalYearDataSuccess = (data) => {
  return {
    type: FISCALYEAR_DATA_SUCCESS,
    data,
  };
};

export const addDropdownConfig = (data, id, type) => {
  return (dispatch) => {
    //dispatch(fetchStart());
    addDropdownConfigAPI(data, id, type)
      .then((response) => {
        if (type == 'DropDown') {
          history.push('/dropdown-enabling');
        } else {
          history.push('/fiscal-year-definition');
        }
        dispatch(fetchDropdownConfig());
        if (type == 'clear') {
          toast.success('Successfully Deleted.');
        } else if (id) {
          toast.success('Successfully Updated.');
        } else {
          toast.success('Successfully Created.');
        }
      })
      .catch((error) => {});
  };
};

const addDropdownConfigAPI = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id)
    result = axios.put('default-configuration/' + id, data, {
      headers: headers,
    });
  else result = axios.post('default-configuration', data, { headers: headers });
  return result;
};

export const fetchDropdownConfig = (field) => {
  return (dispatch) => {
    fetchDropdownConfigAPI(field)
      .then((response) => {
        if (field)
          dispatch(fetchDropdownFieldConfigSuccess(response.data.response));
        else dispatch(fetchDropdownConfigSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};

const fetchDropdownConfigAPI = (field) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (field)
    result = axios.get(
      'valid-configuration?key_name=dropdown_configuration&field=' + field,
      { headers: headers },
    );
  else
    result = axios.get(
      'default-configuration?key_name=dropdown_configuration',
      { headers: headers },
    );
  return result;
};

export const fetchDropdownConfigSuccess = (data) => {
  return {
    type: DROP_DOWN_SUCCESS,
    data,
  };
};
export const fetchDropdownFieldConfigSuccess = (data) => {
  return {
    type: DROP_DOWN_FIELD_SUCCESS,
    data,
  };
};
