import {
  FETCH_START,
  FETCH_END,
  FORECAST_LEVEL_DATA_SUCCESS,
  FORECAST_LEVEL_BY_ID,
  FORECAST_LEVEL_DROPDWON_SUCCESS,
  SALES_FORECAST_QUERY_FIELDS_SUCCESS,
  SALES_FORECAST_FORMAT_LIST_SUCCESS,
  SALES_FORECAST_QUERY_SINGLE_SUCCESS,
  SALES_FORECAST_UPLOAD_SUCCESS,
  DISPLAY_FORECAST_SUCCESS,
  SALES_FORECAST_DATA_SUCCESS,
} from '../../actions/Forecasting/ActionTypes';

const initialState = {
  loading: false,
  foreCastLevelData: [],
  foreCastLevelSingleData: [],
  dropdwonForeCast: [],
  forecastformats: [],
  forecastFormatList: [],
  forecastFormatSingleData: [],
  forecastErrorList: [],
  displayForecastResult: [],
  salesForecastData: [],
};

const ForecastingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_END:
      return {
        ...state,
        loading: false,
      };
    case FORECAST_LEVEL_DATA_SUCCESS:
      return {
        ...state,
        foreCastLevelData: action.data,
        loading: false,
      };
    case FORECAST_LEVEL_BY_ID:
      return {
        ...state,
        foreCastLevelSingleData: action.data,
        loading: false,
      };
    case FORECAST_LEVEL_DROPDWON_SUCCESS:
      return {
        ...state,
        dropdwonForeCast: action.data,
        loading: false,
      };
    case SALES_FORECAST_QUERY_FIELDS_SUCCESS:
      return {
        ...state,
        forecastformats: action.data,
        loading: false,
      };
    case SALES_FORECAST_FORMAT_LIST_SUCCESS:
      return {
        ...state,
        forecastFormatList: action.data,
        loading: false,
      };
    case SALES_FORECAST_QUERY_SINGLE_SUCCESS:
      return {
        ...state,
        forecastFormatSingleData: action.data,
        loading: false,
      };
    case SALES_FORECAST_UPLOAD_SUCCESS:
      return {
        ...state,
        forecastErrorList: action.data,
        loading: false,
      };
    case DISPLAY_FORECAST_SUCCESS:
      return {
        ...state,
        displayForecastResult: action.data,
        loading: false,
      };
    case SALES_FORECAST_DATA_SUCCESS:
      return {
        ...state,
        salesForecastData: action.data,
        loading: false,
      };

    default:
      return state;
  }
};

export default ForecastingReducer;
