import secureLocalStorage from 'react-secure-storage';

export const ServerError = 'Something has gone wrong. Please try again later!';
export const ApplicationTypes =
  secureLocalStorage.getItem('dYtz') &&
  secureLocalStorage.getItem('dYtz').role &&
  secureLocalStorage.getItem('dYtz').role.authorized_apps
    ? secureLocalStorage.getItem('dYtz').role.authorized_apps
    : [];
export const NoData = 'There is no data to show now.';
