import {
  ATTRIBUTE_MAPPING_ALL,
  ATTRIBUTE_MAPPING_SINGLE,
  FETCH_START,
  FETCH_END,
} from '../../../actions/CalculationDesigner/AttributeMap/ActionTypes';
const initialState = {
  AttributeMapData: [],
  AttributeMapDataSingle: [],
  loading: false,
};
const AddKeyFigureReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_START:
      return {
        loading: true,
      };
    case FETCH_END:
      return {
        loading: false,
      };
    case ATTRIBUTE_MAPPING_ALL:
      return {
        ...state,
        AttributeMapData: action.data,
      };
    case ATTRIBUTE_MAPPING_SINGLE:
      return {
        ...state,
        AttributeMapDataSingle: action.data,
      };
    default:
      return state;
  }
};
export default AddKeyFigureReducer;
