import {
  GET_AGGREGATION_LEVEL,
  FETCH_START,
  FETCH_END,
  CLAIM_CONFIGURATION_SUCCESS,
  BUDGET_CONFIGURATION_SUCCESS,
  CLAIM_ERROR_SUCCESS,
  CLAIM_ERROR_SINGLE_SUCCESS,
  GET_AGGREGATION_LEVEL_BY_ID,
} from './ActionTypes';
import axios from '../../../axios.conf';
import { toast } from 'react-toastify';
import { history } from '../../../components/Helpers';
import secureLocalStorage from 'react-secure-storage';
import { ServerError } from '../../../library/constants';

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};
export const createAggregationLevel = (data, type, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createAggregationLevelAPI(data, type, id)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/calculation-analysis');
        dispatch(getAggregationLevel());
        if (type == 'edit') {
          toast.success('Successfully Updated.');
        } else if (type == 'clear') {
          toast.success('Successfully Deleted.');
        } else {
          toast.success('Successfully Created.');
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createAggregationLevelAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'create')
    result = axios.post('/contract-accuracy-agg-level', data, {
      headers: headers,
    });
  else
    result = axios.put('/contract-accuracy-agg-level/' + id, data, {
      headers: headers,
    });
  return result;
};
export const getAggregationLevelById = (id) => {
  return (dispatch) => {
    getAggregationLevelByIdAPI(id)
      .then((response) => {
        dispatch(getAggregationLevelByIdSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError)
      });
  };
};
const getAggregationLevelByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('contract-accuracy-agg-level/' + id, { headers: headers });
  return result;
};
export const getAggregationLevelByIdSuccess = (data) => {
  return {
    type: GET_AGGREGATION_LEVEL_BY_ID,
    data,
  };
};
export const getAggregationLevel = (pagination, limit) => {
  return (dispatch) => {
    getAggregationLevelAPI(pagination, limit)
      .then((response) => {
        //dispatch(getKeyFigureableDataComplete(response.data.response.total_record));
        dispatch(getAggregationLevelSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};

const getAggregationLevelAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    '/contract-accuracy-agg-level?page=' + pagination + '&&limit=' + limit,
    { headers: headers },
  );
  return result;
};

export const getAggregationLevelSuccess = (data) => {
  return {
    type: GET_AGGREGATION_LEVEL,
    data,
  };
};
export const deleteAggregationLevel = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteAggregationLevelAPI(id)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/calculation-analysis');
        dispatch(getAggregationLevel());

        toast.success('Successfully deleted.');
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const deleteAggregationLevelAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('/contract-accuracy-agg-level/' + id, {
    headers: headers,
  });
  return result;
};

// claim confgruation
export const createClaimConfiguration = (data, type, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createClaimConfigurationAPI(data, type, id)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/claim-configuration');
        dispatch(getClaimConfiguration());
        if (type == 'edit') {
          toast.success('Successfully Updated.');
        } else if (type == 'clear') {
          toast.success('Successfully Deleted.');
        } else {
          toast.success('Successfully Created.');
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createClaimConfigurationAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'create')
    result = axios.post('claim-config', data, { headers: headers });
  else result = axios.put('claim-config/' + id, data, { headers: headers });
  return result;
};

export const getClaimConfiguration = () => {
  return (dispatch) => {
    getClaimConfigurationAPI()
      .then((response) => {
        //dispatch(getKeyFigureableDataComplete(response.data.response.total_record));
        dispatch(getClaimConfigurationSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};

const getClaimConfigurationAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('/claim-config', { headers: headers });
  return result;
};

export const getClaimConfigurationSuccess = (data) => {
  return {
    type: CLAIM_CONFIGURATION_SUCCESS,
    data,
  };
};

// Rejection Reasons
export const createClaimError = (data, type, id, rejectionReasonCode) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createClaimErrorAPI(data, type, id, rejectionReasonCode)
      .then((response) => {
        dispatch(fetchEnd());

        if (type == 'edit') {
          toast.success(response.data.response);
          //dispatch(getClaimError())
          dispatch(getSingleClaimErrorSuccess(response.data.response));
          history.push('/rejection-reasons');
        } else if (type == 'clear') {
          toast.success(response.data.response);
          dispatch(getClaimError());
          history.push('/rejection-reasons');
        } else if (type == 'editMode') {
          // dispatch(getClaimError())
          dispatch(getSingleClaimErrorSuccess(response.data.response));
        } else if (type === 'delete') {
          toast.success(response.data.response);
          dispatch(getClaimError());
          history.push('/rejection-reasons');
        } else {
          toast.success(response.data.response);
          dispatch(getClaimError());
          history.push('/rejection-reasons');
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createClaimErrorAPI = (data, type, id, rejectionReasonCode) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'create')
    result = axios.post('rejection-reason', data, { headers: headers });
  else if (type === 'editMode')
    result = axios.get(
      'rejection-reason/' +
        id +
        '?rejection_reason_code=' +
        rejectionReasonCode,
      { headers: headers },
    );
  // result = axios.get('rejection-reason/' + id, { headers: headers });
  else if (type === 'delete')
    result = axios.delete(
      'rejection-reason/' + id + '?rejection_reason_code=' + data,
      { headers: headers },
    );
  else result = axios.put('rejection-reason/' + id, data, { headers: headers });
  return result;
};

export const getClaimError = (pagination, limit) => {
  return (dispatch) => {
    getClaimErrorAPI(pagination, limit)
      .then((response) => {
        //dispatch(getKeyFigureableDataComplete(response.data.response.total_record));
        dispatch(getClaimErrorSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};

const getClaimErrorAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    '/rejection-reason?page=' + pagination + '&&limit=' + limit,
    { headers: headers },
  );
  return result;
};

export const getClaimErrorSuccess = (data) => {
  return {
    type: CLAIM_ERROR_SUCCESS,
    data,
  };
};
export const getSingleClaimErrorSuccess = (data) => {
  return {
    type: CLAIM_ERROR_SINGLE_SUCCESS,
    data,
  };
};

export const getBudgetingConfig = (page) => {
  return (dispatch) => {
    getBudgetingConfigAPI(page)
      .then((response) => {
        dispatch(getBudgetingConfigSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};

const getBudgetingConfigAPI = (page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (page == 'promotionType')
    result = axios.get('/promotion-type-sections', { headers: headers });
  else if (page == 'promotionConfig')
    result = axios.get('/promotion-type-group', { headers: headers });
  else result = axios.get('/budget-config', { headers: headers });
  return result;
};

export const getBudgetingConfigSuccess = (data) => {
  return {
    type: BUDGET_CONFIGURATION_SUCCESS,
    data,
  };
};

export const createBudgetClaimConfiguration = (data, type, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createBudgetClaimConfigurationAPI(data, type, id)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/promo-configuration');
        dispatch(getBudgetingConfig());
        if (type == 'edit') {
          toast.success('Successfully Updated.');
        } else if (type == 'clear') {
          toast.success('Successfully Deleted.');
        } else {
          toast.success('Successfully Created.');
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createBudgetClaimConfigurationAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'create')
    result = axios.post('budget-config', data, { headers: headers });
  else result = axios.put('budget-config/' + id, data, { headers: headers });
  return result;
};
