export const ADD_ELIGIBILITY_RULE = 'ADD_ELIGIBILITY_RULE';
export const ADD_ELIGIBILITY_RULE_SUCCESS = 'ADD_ELIGIBILITY_RULE_SUCCESS';
export const ADD_ELIGIBILITY_RULE_FAILED = 'ADD_ELIGIBILITY_RULE_FAILED';
export const ADD_CALCULATION_RULE_FAILED = 'ADD_CALCULATION_RULE_FAILED';
export const ADD_CALCULATION_RULE = 'ADD_CALCULATION_RULE';
export const ADD_EXCEPTION_GENERAL_DATA = 'ADD_EXCEPTION_GENERAL_DATA';
export const EXCEPTION_DATA = 'EXCEPTION_DATA';
export const EXCEPTION_DATA_COMPLETE = 'EXCEPTION_DATA_COMPLETE';
export const EXCEPTION_DETAIL_DATA = 'EXCEPTION_DETAIL_DATA';
export const CALCULATION_RULE_SUCCESS = 'CALCULATION_RULE_SUCCESS';
export const EXCEPTION_NUMBER = 'EXCEPTION_NUMBER';
export const FETCH_START = 'FETCH_START';
export const FETCH_END = 'FETCH_END';
export const FETCH_START_UPLOAD = 'FETCH_START_UPLOAD';
export const FETCH_END_UPLOAD = 'FETCH_END_UPLOAD';
export const UPLOAD_ATTACHMENT_SUCCESS = 'UPLOAD_ATTACHMENT_SUCCESS';
export const SHOW_EXCEPTION_POPUP = 'SHOW_EXCEPTION_POPUP';
export const CHANGED_EXCEPTION_DETAIL_SUCCESS =
  'CHANGED_EXCEPTION_DETAIL_SUCCESS';
export const SECOND_CHANGED_EXCEPTION_DETAIL_SUCCESS =
  'SECOND_CHANGED_EXCEPTION_DETAIL_SUCCESS';
export const EXCEPTION_DATA_COMPARE = 'EXCEPTION_DATA_COMPARE';
export const UPDATED_EXCEPTION_LIST = 'UPDATED_EXCEPTION_LIST';
export const EXCEPTION_CHANGE_LOG = 'EXCEPTION_CHANGE_LOG';
export const EXCEPTION_ALL_APPTYPE = 'EXCEPTION_ALL_APPTYPE';
export const EXCEPTION_DATA_LIST = 'EXCEPTION_DATA_LIST';
export const UPDATED_EXCEPTION_ALL_LIST = 'UPDATED_EXCEPTION_ALL_LIST';
export const FP_HISTORY_SUCCESS = 'FP_HISTORY_SUCCESS';
export const MASS_EXCEPTION_UPDATE = 'MASS_EXCEPTION_UPDATE';
export const MASS_EXCEPTION_UPDATE_ACTION = 'MASS_EXCEPTION_UPDATE_ACTION';
