import {
  PRODUCT_FEATURE_SUCCESS,
  PRODUCT_FEATURE_SINGLE_SUCCESS,
  FETCH_START,
  FETCH_END,
} from './ActionTypes';
import axios from '../../../axios.conf';
import { toast } from 'react-toastify';
//import { ServerError } from '../../../../library/constants';
import { history } from '../../../components/Helpers';
import secureLocalStorage from 'react-secure-storage';

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};
export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};
export const getProductFeatureData = (pagination, limit) => {
  return (dispatch) => {
    getProductFeatureDataAPI(pagination, limit).then((response) => {
      console.log(response.data.response);
      dispatch(getProductFeatureDataSuccess(response.data.response));
    });
  };
};
const getProductFeatureDataAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('product-feature?page=' + pagination + '&limit=' + limit, {
    headers: headers,
  });
  return result;
};
export const getProductFeatureDataSuccess = (data) => {
  return {
    type: PRODUCT_FEATURE_SUCCESS,
    data,
  };
};
export const createProductFeature = (data, type, id) => {
  return (dispatch) => {
    createProductFeatureAPI(data, type, id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getProductFeatureData(1, 10));
        history.push('/product-feature');
      })
      .catch((error) => {});
  };
};
const createProductFeatureAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'delete') {
    result = axios.delete('product-feature/' + id, { headers: headers });
  } else if (type === 'edit') {
    result = axios.put('product-feature/' + id, data, { headers: headers });
  } else {
    result = axios.post('product-feature', data, { headers: headers });
  }
  return result;
};
export const getProductFeatureSingleItem = (id) => {
  return (dispatch) => {
    getProductFeatureSingleItemAPI(id).then((response) => {
      console.log(response.data.response);
      dispatch(getProductFeatureSingleItemSuccess(response.data.response));
    });
  };
};
const getProductFeatureSingleItemAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('product-feature/' + id, { headers: headers });
  return result;
};
export const getProductFeatureSingleItemSuccess = (data) => {
  return {
    type: PRODUCT_FEATURE_SINGLE_SUCCESS,
    data,
  };
};
