import {
  FETCH_START,
  FETCH_END,
  ADD_ELIGIBILITY_RULE_SUCCESS,
  ADD_ELIGIBILITY_RULE,
  ADD_ELIGIBILITY_RULE_FAILED,
  ADD_CALCULATION_RULE,
  EXCEPTION_DATA,
  EXCEPTION_DETAIL_DATA,
  ADD_EXCEPTION_GENERAL_DATA,
  ADD_CALCULATION_RULE_FAILED,
  CALCULATION_RULE_SUCCESS,
  EXCEPTION_NUMBER,
  EXCEPTION_DATA_COMPLETE,
  UPLOAD_ATTACHMENT_SUCCESS,
  FETCH_START_UPLOAD,
  FETCH_END_UPLOAD,
  SHOW_EXCEPTION_POPUP,
  CHANGED_EXCEPTION_DETAIL_SUCCESS,
  SECOND_CHANGED_EXCEPTION_DETAIL_SUCCESS,
  EXCEPTION_DATA_COMPARE,
  UPDATED_EXCEPTION_LIST,
  EXCEPTION_CHANGE_LOG,
  EXCEPTION_ALL_APPTYPE,
  EXCEPTION_DATA_LIST,
  UPDATED_EXCEPTION_ALL_LIST,
  FP_HISTORY_SUCCESS,
  MASS_EXCEPTION_UPDATE,
  MASS_EXCEPTION_UPDATE_ACTION,
} from '../../../actions/Exception/AddException/ActionTypes';

const initialState = {
  eligibilityRulesData: [],
  calculationRulesData: [],
  exceptionListData: [],
  exceptionDetailData: [],
  exceptionGeneralData: [],
  changedExceptionData: [],
  changedExceptionOldData: [],
  exceptionCompareData: [],
  exceptionChangeLog: {},
  exceptionNumber: 0,
  eligibilityAdded: false,
  loading: false,
  uploadUrl: '',
  loadingFile: false,
  showExceptionPopup: false,
  exceptionAllAppType: [],
  exceptionAllListData: [],
  fpHistoryData: {},
  massUpdate: [],
  massUpdateAction: [],
};

const AddExceptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ELIGIBILITY_RULE:
      return {
        ...state,
        eligibilityRulesData: action.data,
      };
    case ADD_ELIGIBILITY_RULE_SUCCESS:
      return {
        eligibilityAdded: true,
      };
    case ADD_ELIGIBILITY_RULE_FAILED:
      return {
        ...state,
        errorEligibilityAdd: true,
      };
    case ADD_CALCULATION_RULE:
      return {
        ...state,
        calculationRulesData: action.data,
      };
    case EXCEPTION_DATA:
      return {
        ...state,
        exceptionListData: action.data,
      };
    case EXCEPTION_DATA_COMPLETE:
      return {
        ...state,
        exceptionListDataComplete: action.data,
      };
    case EXCEPTION_DETAIL_DATA:
      return {
        ...state,
        exceptionDetailData: action.data,
      };
    case ADD_EXCEPTION_GENERAL_DATA:
      return {
        ...state,
        exceptionGeneralData: action.data,
      };
    case EXCEPTION_DATA_COMPARE:
      return {
        ...state,
        exceptionCompareData: action.data,
      };
    case ADD_CALCULATION_RULE_FAILED:
      return {
        ...state,
        errorCalculationAdd: true,
      };
    case CALCULATION_RULE_SUCCESS:
      return {
        ...state,
        calculationSuccess: true,
      };
    case EXCEPTION_NUMBER:
      return {
        ...state,
        exceptionNumber: action.data,
      };
    case FETCH_START:
      return {
        loading: true,
      };
    case FETCH_END:
      return {
        loading: false,
      };
    case UPLOAD_ATTACHMENT_SUCCESS:
      return {
        ...state,
        uploadUrl: action.data,
      };
    case FETCH_START_UPLOAD:
      return {
        loadingFile: true,
      };
    case FETCH_END_UPLOAD:
      return {
        loadingFile: false,
      };
    case SHOW_EXCEPTION_POPUP:
      return {
        showExceptionPopup: action.data,
      };
    case CHANGED_EXCEPTION_DETAIL_SUCCESS:
      return {
        changedExceptionData: action.data,
      };
    case SECOND_CHANGED_EXCEPTION_DETAIL_SUCCESS:
      return {
        changedExceptionOldData: action.data,
      };
    case UPDATED_EXCEPTION_LIST:
      return {
        ...state,
        updatedExceptionList: action.data,
      };
    case EXCEPTION_CHANGE_LOG:
      return {
        ...state,
        exceptionChangeLog: action.data,
      };
    case EXCEPTION_ALL_APPTYPE:
      return {
        ...state,
        exceptionAllAppType: action.data,
      };
    case EXCEPTION_DATA_LIST:
      return {
        ...state,
        exceptionAllListData: action.data,
      };
    case UPDATED_EXCEPTION_ALL_LIST:
      return {
        ...state,
        updatedExceptionAllList: action.data,
      };
    case FP_HISTORY_SUCCESS:
      return {
        ...state,
        fpHistoryData: action.data,
      };
    case MASS_EXCEPTION_UPDATE:
      return {
        ...state,
        massUpdate: action.data,
      };
    case MASS_EXCEPTION_UPDATE_ACTION:
      return {
        ...state,
        massUpdateAction: action.data,
      };
    default:
      return state;
  }
};

export default AddExceptionReducer;
