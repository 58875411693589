export const ADD_ELIGIBILITY_RULE = 'ADD_ELIGIBILITY_RULE';
export const ADD_ELIGIBILITY_RULE_SUCCESS = 'ADD_ELIGIBILITY_RULE_SUCCESS';
export const ADD_ELIGIBILITY_RULE_FAILED = 'ADD_ELIGIBILITY_RULE_FAILED';
export const ADD_CALCULATION_RULE_FAILED = 'ADD_CALCULATION_RULE_FAILED';
export const ADD_CALCULATION_RULE = 'ADD_CALCULATION_RULE';
export const ADD_CONTRACT_GENERAL_DATA = 'ADD_CONTRACT_GENERAL_DATA';
export const CONTRACT_DATA = 'CONTRACT_DATA';
export const CONTRACT_DATA_COMPLETE = 'CONTRACT_DATA_COMPLETE';
export const CONTRACT_DETAIL_DATA = 'CONTRACT_DETAIL_DATA';
export const CALCULATION_RULE_SUCCESS = 'CALCULATION_RULE_SUCCESS';
export const CONTRACT_NUMBER = 'CONTRACT_NUMBER';
export const FETCH_START = 'FETCH_START';
export const FETCH_END = 'FETCH_END';
export const FETCH_START_UPLOAD = 'FETCH_START_UPLOAD';
export const FETCH_END_UPLOAD = 'FETCH_END_UPLOAD';
export const UPLOAD_ATTACHMENT_SUCCESS = 'UPLOAD_ATTACHMENT_SUCCESS';
export const SHOW_CONTRACT_POPUP = 'SHOW_CONTRACT_POPUP';
export const CHANGED_CONTRACT_DETAIL_SUCCESS =
  'CHANGED_CONTRACT_DETAIL_SUCCESS';
export const SECOND_CHANGED_CONTRACT_DETAIL_SUCCESS =
  'SECOND_CHANGED_CONTRACT_DETAIL_SUCCESS';
export const CONTRACT_DATA_COMPARE = 'CONTRACT_DATA_COMPARE';
export const UPDATED_CONTRACT_LIST = 'UPDATED_CONTRACT_LIST';
export const CONTRACT_CHANGE_LOG = 'CONTRACT_CHANGE_LOG';
export const CONTRACT_ALL_APPTYPE = 'CONTRACT_ALL_APPTYPE';
export const CONTRACT_DATA_LIST = 'CONTRACT_DATA_LIST';
export const CONTRACT_DATA_LIST_DASHBOARD = 'CONTRACT_DATA_LIST_DASHBOARD';
export const CONTRACT_RULES_DATA_API_SUCCESS =
  'CONTRACT_RULES_DATA_API_SUCCESS';
export const UPDATED_CONTRACT_ALL_LIST = 'UPDATED_CONTRACT_ALL_LIST';
export const FP_HISTORY_SUCCESS = 'FP_HISTORY_SUCCESS';
export const MASS_CONTRACT_UPDATE = 'MASS_CONTRACT_UPDATE';
export const MASS_CONTRACT_UPDATE_ACTION = 'MASS_CONTRACT_UPDATE_ACTION';
export const UPLOAD_CALCULATION_RULES_SUCCESS =
  'UPLOAD_CALCULATION_RULES_SUCCESS';
export const APPROVAL_LEVEL_DATA = 'APPROVAL_LEVEL_DATA';
export const EXPIRY_DATA = 'EXPIRY_DATA';
export const CALCU_RULE_VALID_MSG = 'CALCU_RULE_VALID_MSG';
