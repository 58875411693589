import {
  DEFAULT_POSTING_DATE,
  CHECK_POSTING_DATE,
  FETCH_START,
  FETCH_END,
  FISCALYEAR_DATA_SUCCESS,
  DROP_DOWN_SUCCESS,
  DROP_DOWN_FIELD_SUCCESS,
} from '../../actions/FinancialClose/ActionTypes';

const initialState = {
  loading: false,
  postingDateData: [],
  checkpostingDate: '',
  fiscalYearData: [],
  dropdownConfig: [],
  dropdownFieldConfig: '',
};

const FinancialCloseReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEFAULT_POSTING_DATE:
      return {
        ...state,
        postingDateData: action.data,
      };
    case FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_END:
      return {
        ...state,
        loading: false,
      };
    case CHECK_POSTING_DATE:
      return {
        ...state,
        checkpostingDate: action.data,
      };
    case FISCALYEAR_DATA_SUCCESS:
      return {
        ...state,
        fiscalYearData: action.data,
      };
    case DROP_DOWN_SUCCESS:
      return {
        ...state,
        dropdownConfig: action.data,
      };
    case DROP_DOWN_FIELD_SUCCESS:
      return {
        ...state,
        dropdownFieldConfig: action.data,
      };
    default:
      return state;
  }
};

export default FinancialCloseReducer;
