import {
  BASIS_MAPPING_ALL,
  BASIS_MAPPING_SINGLE,
  FETCH_START,
  FETCH_END,
} from './ActionTypes';
import axios from '../../../../axios.conf';
import { toast } from 'react-toastify';
import { history } from '../../../../components/Helpers';
import secureLocalStorage from 'react-secure-storage';

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};
export const addBasisMappingDefaultDesigner = (data, edit, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addBasisMappingAPI(data, edit, id)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/Basis-mapping');
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const addBasisMappingAPI = (data, edit, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (edit === 'edit')
    result = axios.put('calculation-transaction-types/' + id, data, {
      headers: headers,
    });
  else
    result = axios.post('calculation-transaction-types', data, {
      headers: headers,
    });
  return result;
};

export const getBasisMappingData = (pagination, limit) => {
  return (dispatch) => {
    getBasisMappingAPI(pagination, limit)
      .then((response) => {
        dispatch(getBasisMappingSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};

const getBasisMappingAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'calculation-transaction-types?page=' + pagination + '&&limit=' + limit,
    { headers: headers },
  );
  return result;
};

export const getBasisMappingSuccess = (data) => {
  return {
    type: BASIS_MAPPING_ALL,
    data,
  };
};

export const getSingleBasisMapping = (id) => {
  return (dispatch) => {
    getSingleBasisMappingAPI(id)
      .then((response) => {
        dispatch(getBasisMappingSuccessSingle(response.data.response));
      })
      .catch((error) => {
        //toast.error('Something happened!');
      });
  };
};

const getSingleBasisMappingAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('calculation-transaction-types/' + id, {
    headers: headers,
  });
  return result;
};

export const getBasisMappingSuccessSingle = (data) => {
  return {
    type: BASIS_MAPPING_SINGLE,
    data,
  };
};

export const deleteBasisMapping = (id) => {
  return (dispatch) => {
    deleteBasisMappingApi(id)
      .then((response) => {
        dispatch(getBasisMappingData());
        toast.success(response.data.response);
      })
      .catch((error) => {});
  };
};

const deleteBasisMappingApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('calculation-transaction-types/' + id, {
    headers: headers,
  });
  return result;
};
