export const QUOTA_SUCCESS = 'QUOTA_SUCCESS';
export const QUOTA_SINGLE_SUCCESS = ' QUOTA_SINGLE_SUCCESS';
export const QUOTA_FORMAT_SUCCESS = 'QUOTA_FORMAT_SUCCESS';
export const QUOTA_FORMAT_BY_ID_SUCCESS = 'QUOTA_FORMAT_BY_ID_SUCCESS';
export const QUOTA_FORMAT_FIELDS_SUCCESS = 'QUOTA_FORMAT_FIELDS_SUCCESS';
export const FETCH_START = 'FETCH_START';
export const FETCH_END = 'FETCH_END';
export const TERRITORY_FORMAT_SUCCESS = 'TERRITORY_FORMAT_SUCCESS';
export const TERRITORY_FORMAT_BY_ID_SUCCESS = 'TERRITORY_FORMAT_BY_ID_SUCCESS';
export const TERRITORY_FORMAT_FIELDS_SUCCESS =
  'TERRITORY_FORMAT_FIELDS_SUCCESS';
export const TERRITORY_UPLOAD_SUCCESS = 'TERRITORY_UPLOAD_SUCCESS';
