import {
  PROFILE_DETAILS_SUCCESS,
  PROFILE_DETAILS_FAIL,
  FETCH_START,
  FETCH_END,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_FAIL,
  CURRENT_APPLICATION,
} from './ActionTypes';
import axios from '../../../axios.conf';
import { toast } from 'react-toastify';
import { ServerError } from '../../../library/constants';
import { history } from '../../../components/Helpers';
import secureLocalStorage from 'react-secure-storage';
import { getOrgDefaults } from '../Configuration/MultipleConfiguration/AuthAction';

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const getProfileSuccess = (data) => {
  return {
    type: PROFILE_DETAILS_SUCCESS,
    data,
  };
};

export const getProfileFailed = (error) => {
  return {
    type: PROFILE_DETAILS_FAIL,
    error,
  };
};

export const getProfile = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getProfileApi()
      .then((response) => {
        dispatch(getProfileSuccess(response.data.response));
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(getProfileFailed(error));
        //toast.error(ServerError);
      });
  };
};

const getProfileApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('profile', { headers: headers });
  return result;
};

//edit profileData
export const editProfileSuccess = (data) => {
  return {
    type: EDIT_PROFILE_SUCCESS,
    data,
  };
};

export const editProfileFailed = (error) => {
  return {
    type: EDIT_PROFILE_FAIL,
    error,
  };
};

export const editProfile = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    editProfileApi(id, data)
      .then((response) => {
        dispatch(editProfileSuccess(response.data.response));
        dispatch(getProfile());
      })
      .catch((error) => {
        dispatch(editProfileFailed(error));
        //toast.error(ServerError);
      });
  };
};

const editProfileApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('users/' + id, data, { headers: headers });
  return result;
};

//upload profile image
export const uploadProfileImage = (id, file, type) => {
  return (dispatch) => {
    uploadProfileImageApi(id, file, type)
      .then((response) => {
        // window.location.reload();
        toast.success('Image has been successfully uploaded.');
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};

const uploadProfileImageApi = (id, file, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  if (type === 'delete') {
    var result = axios.delete('users/' + id + '/avatar', { headers: headers });
  } else {
    var result = axios.put('users/' + id + '/avatar', file, {
      headers: headers,
    });
  }
  return result;
};

// to fetch user selected application from anywhere on the app
export const currentApplication = (app) => {
  return (dispatch) => {
    dispatch(currentApplicationSuccess(app));
  };
};

export const currentApplicationSuccess = (data) => {
  return {
    type: CURRENT_APPLICATION,
    data,
  };
};

//upload profile image
export const uploadCompanyLogo = (file) => {
  return (dispatch) => {
    uploadCompanyLogoAPI(file)
      .then((response) => {
        // window.location.reload();
        dispatch(getOrgDefaults());
        toast.success('Image has been successfully uploaded.');
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};

const uploadCompanyLogoAPI = (file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result = axios.post('organizations-default-profile', file, {
    headers: headers,
  });
  return result;
};
