import {
  UOM_SUCCESS,
  UOM_SINGLE_SUCCESS,
  FETCH_START,
  FETCH_END,
} from './ActionTypes';
import axios from '../../../axios.conf';
import { toast } from 'react-toastify';
//import { ServerError } from '../../../../library/constants';
import { history } from '../../../components/Helpers';
import secureLocalStorage from 'react-secure-storage';

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};
export const getUoMData = (pagination, limit) => {
  return (dispatch) => {
    getUoMDataApi(pagination, limit).then((response) => {
      dispatch(getUoMDataSuccess(response.data.response));
    });
  };
};

const getUoMDataApi = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('general-uom?page=' + pagination + '&limit=' + limit, {
    headers: headers,
  });
  return result;
};

export const getUoMDataSuccess = (data) => {
  return {
    type: UOM_SUCCESS,
    data,
  };
};

export const createUoM = (data, type, id) => {
  return (dispatch) => {
    createUoMAPI(data, type, id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getUoMData(1, 10));

        history.push('/uom');
      })
      .catch((error) => {
        // if (error.response && error.response.data && error.response.data.error && error.response.data.error.message)
        //toast.error(error.response.data.error.message);
      });
  };
};

const createUoMAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'delete') {
    result = axios.delete('general-uom/' + id, { headers: headers });
  } else if (type === 'edit') {
    result = axios.put('general-uom/' + id, data, { headers: headers });
  } else {
    result = axios.post('general-uom', data, { headers: headers });
  }
  return result;
};

export const getUoMSingleItem = (id) => {
  return (dispatch) => {
    getUoMSingleItemAPI(id).then((response) => {
      dispatch(getUoMSingleItemSuccess(response.data.response));
    });
  };
};
const getUoMSingleItemAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('general-uom/' + id, { headers: headers });
  return result;
};

export const getUoMSingleItemSuccess = (data) => {
  return {
    type: UOM_SINGLE_SUCCESS,
    data,
  };
};
