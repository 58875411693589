import {
  QUOTA_SUCCESS,
  QUOTA_SINGLE_SUCCESS,
  QUOTA_FORMAT_SUCCESS,
  QUOTA_FORMAT_BY_ID_SUCCESS,
  QUOTA_FORMAT_FIELDS_SUCCESS,
  FETCH_START,
  FETCH_END,
  TERRITORY_FORMAT_SUCCESS,
  TERRITORY_FORMAT_BY_ID_SUCCESS,
  TERRITORY_FORMAT_FIELDS_SUCCESS,
  TERRITORY_UPLOAD_SUCCESS,
} from '../../actions/QuotaManagement/ActionTypes';

const initialState = {
  loading: false,
  quotaData: [],
  quotaSingleData: [],
  quotaFormatData: [],
  quotaFormatFieldData: [],
  quotaSingleFormatData: [],
  territoryFormatData: [],
  territoryFormatFieldData: [],
  territorySingleFormatData: [],
  territoryDataErrorList: [],
};

const QuotaManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUOTA_SUCCESS:
      return {
        ...state,
        quotaData: action.data,
      };

    case FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_END:
      return {
        ...state,
        loading: false,
      };
    case QUOTA_SINGLE_SUCCESS:
      return {
        ...state,
        quotaSingleData: action.data,
      };
    case QUOTA_FORMAT_SUCCESS:
      return {
        ...state,
        quotaFormatData: action.data,
      };
    case QUOTA_FORMAT_BY_ID_SUCCESS:
      return {
        ...state,
        quotaSingleFormatData: action.data,
      };
    case QUOTA_FORMAT_FIELDS_SUCCESS:
      return {
        ...state,
        quotaFormatFieldData: action.data,
      };
    case TERRITORY_FORMAT_SUCCESS:
      return {
        ...state,
        territoryFormatData: action.data,
      };
    case TERRITORY_FORMAT_BY_ID_SUCCESS:
      return {
        ...state,
        territorySingleFormatData: action.data,
      };
    case TERRITORY_FORMAT_FIELDS_SUCCESS:
      return {
        ...state,
        territoryFormatFieldData: action.data,
      };
    case TERRITORY_UPLOAD_SUCCESS:
      return {
        ...state,
        territoryDataErrorList: action.data,
      };
    default:
      return state;
  }
};

export default QuotaManagementReducer;
