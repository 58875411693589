import {
  FETCH_START,
  FETCH_END,
  ERROR_LOGS_SUCCESS,
  MESSAGE_LOGS_SUCCESS,
} from './ActionTypes';
import axios from '../../../axios.conf';
import { history } from '../../../components/Helpers';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};
export const getErrorLogs = (pagination, limit) => {
  return (dispatch) => {
    getErrorLogsAPI(pagination, limit)
      .then((response) => {
        dispatch(getErrorLogsSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};

const getErrorLogsAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    '/file-upload-errors?page=' + pagination + '&limit=' + limit,
    { headers: headers },
  );
  return result;
};

export const getErrorLogsSuccess = (data) => {
  return {
    type: ERROR_LOGS_SUCCESS,
    data,
  };
};
export const getMessageLogs = (pagination, limit, type, id) => {
  return (dispatch) => {
    getMessageLogsAPI(pagination, limit, type, id)
      .then((response) => {
        if (type) {
          history.push('/message-logs');
        }
        dispatch(getMessageLogsSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};

const getMessageLogsAPI = (pagination, limit, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type) {
    result = axios.get(
      '/message-log?page=' +
        pagination +
        '&limit=' +
        limit +
        '&batch_job_id=' +
        id,
      {
        headers: headers,
      },
    );
  } else {
    result = axios.get('/message-log?page=' + pagination + '&limit=' + limit, {
      headers: headers,
    });
  }
  return result;
};

export const getMessageLogsSuccess = (data) => {
  return {
    type: MESSAGE_LOGS_SUCCESS,
    data,
  };
};
